/* eslint-disable react-hooks/exhaustive-deps */
import { Header, Footer } from "@components";
import "./styles/ForgotPassword.css";
import './styles/WebsiteStyle.css';
import pass_eye from '../assets/img/pass_eye.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, encryptEmail } from "../services/commonService";
import { useCallback, useEffect, useState } from "react";
import { getSessionId } from "../services/storageData";
import reducersAndActions from "../_redux/slices";
import Preloader from "../plugins/Preloader";
import login_error_icon from '../assets/icons/login-error-icon.svg';
import { decryptText, renderPasswordHelperText } from "../helpers/DynamicFunctions";
import lm_logo from '../assets/img/lm_logo.svg';
import { Link } from 'react-router-dom';
import warning_icon from '../../src/assets/icons/warning_icon.svg';
import Constants from "../helpers/Constants";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
  }, [loading]);

  const errorbox = useSelector((state) => state.errorbox);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [showErrorMessages, setShowErrorMessages] = useState(false);

  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    numeric: false,
    special: false,
  });


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('data')) {
      const securedEmail = decryptText(queryParams.get('data'))
      setEmail(securedEmail);
    } else {
      const securedEmail = queryParams.get('email').split(" ").join("+");
      encryptEmail(securedEmail).then(resp => setEmail(resp)).catch(err => console.log(err));
    }
  }, []);

  const validatePassword = (password) => {
    const validations = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      numeric: /\d/.test(password),
      special: /[@$%^&*!#%]/.test(password),
    };
    setPasswordValidations(validations);
    setPasswordErrors(Object.keys(validations).filter((key) => !validations[key]));
  };

  const handlePasswordChange = useCallback((e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
    setShowErrorMessages(false);
  }, []);

  const handleConfirmPasswordChange = useCallback((e) => {
    setConfirmPassword(e.target.value);
    setShowErrorMessages(false);
  }, []);

  const showPassword = (inputId, eyeId) => {
    const input = document.getElementById(inputId);
    const eyeIcon = document.getElementById(eyeId);
    input.type = input.type === 'password' ? 'text' : 'password';
    eyeIcon.classList.toggle('active_pass');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (passwordErrors.length > 0) {
      setShowErrorMessages(true);
      return;
    }

    if (password !== confirmPassword) {
      setShowErrorMessages(true);
      return;
    }

    setLoading(true);

    const requestData = {
      EmailAddress: email,
      SessionId: getSessionId(),
      Password: password,
    };

    try {
      const { status, message } = await changePassword(requestData);
      if (status === 200) {
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message, type: status === 200 ? "success" : "error" }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        navigate('/');
      }
    } catch (err) {
      dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: err.response?.data?.message || "An error occurred", }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />

      {loading && <Preloader />}

      <div className="website-wrap">
        <section className="forgot-body">
        <div className='lm-logo-for-mobile-only pb-4 text-center'>
            <img src={lm_logo} alt="" />
          </div>
          <div className="container">
            {errorbox.visible && (
              <div className="login-validation-box">
                <img className="mr-2" src={login_error_icon} alt="" />
                {errorbox.message}
              </div>
            )}

            <div className="forgot-box verification-box">
              <h4>Reset Password</h4>
              <p>{email}</p>

              {(showErrorMessages && password && password !== confirmPassword) && (
                <div className="password-match-toast d-flex justify-content-center">
                  <img className="mr-2" src={warning_icon} alt="" />
                  Passwords must match
                </div>
              )}

              {showErrorMessages && passwordErrors.length > 0 && (
                <div className="password-match-toast d-flex justify-content-center">
                  <img className="mr-2" src={warning_icon} alt="" />
                  Password validations failed
                </div>
              )}

              <div className="form-group input-password">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Enter a password"
                />
                <img
                  onClick={() => showPassword("password", "pass_eye")}
                  id="pass_eye"
                  className="pass_eye"
                  src={pass_eye}
                  alt="Toggle Password Visibility"
                />
                {renderPasswordHelperText(passwordValidations)}
              </div>

              <div className="form-group input-password">
                <label htmlFor="confirm-password">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  placeholder="Confirm your password"
                />
                <img
                  onClick={() => showPassword("confirm-password", "confirm_pass_eye")}
                  id="confirm_pass_eye"
                  className="pass_eye"
                  src={pass_eye}
                  alt="Toggle Confirm Password Visibility"
                />
              </div>

              <button type="button" className="btn btn-forgot" onClick={handleSubmit}>
                Reset Password
              </button>
              <div className="text-center mt-4 mobile-only-link">
                <Link className="link-secondary" to="/">Back to Login</Link>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
}

export default ResetPassword;
/* eslint-disable react-hooks/exhaustive-deps */
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import router from '@lm-router';
import { RouterProvider } from 'react-router-dom';
import { autoSetSessionId } from './services/storageData';
import { generateUniqueId } from './services/commonService';
import { useEffect } from 'react';
import Toaster from './plugins/Toaster';
import axiosInstance from './helpers/axiosConfig';
import reducersAndActions from './_redux/slices';
import SessionManagement from './sessionManagment'; // The modal component

function App() {
  const dispatch = useDispatch();
  const toaster = useSelector(state => state.toaster);
  const user = useSelector((state) => state.auth);

  useEffect(() => {
    autoSetSessionId(generateUniqueId());
    if (user.isLoggedIn) {
      getMyProfile();
      dispatch(reducersAndActions.actions.sessionActions.checkSessionOnLoad(dispatch));
    }
  }, []);

  const getMyProfile = async () => {
    const storedSessionId = localStorage.getItem('sessionId');
    const data = await axiosInstance.get(`/api/User/MyProfile?userId=${user.user.id}&SessionId=${storedSessionId}`);
    if (data.status === 200) {
      let responseData = data.data.resultObject;
      dispatch(reducersAndActions.actions.profileAction.refreshData({ ...responseData, imagePath: 'data:image/png;base64,' + responseData.imagePath }));
      dispatch(reducersAndActions.actions.profileAction.isProfileUpdated({}));
    } else {
      console.log(data.message)
    }
  }

  return (
      <div className={!user.isLoggedIn ? "website-main" : ""}>
        {toaster.visible && <Toaster visible={toaster.visible} message={toaster.message} type={toaster.type} />}
        <RouterProvider router={router} />
        <SessionManagement />
      </div>
  );
}

export default App;
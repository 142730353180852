import React, { useState } from 'react';
import axiosInstance from '../../../helpers/axiosConfig'; // Adjust the path to where your Axios instance is defined
import { useDispatch } from 'react-redux';
import reducersAndActions from '../../../_redux/slices';
import Constants from '../../../helpers/Constants';
import Preloader from '../../../plugins/Preloader';
import { renderPasswordHelperText } from '../../../helpers/DynamicFunctions';

const ChangePassword = ({ activeTab, warning_icon, pass_eye }) => {
  const dispatch = useDispatch();

  const [preloader, setPreloader] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [showErrorMessages, setShowErrorMessages] = useState(false);

  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    numeric: false,
    special: false,
  });

  // Show password hide password
  const showPassword = (id) => {
    const input = document.getElementById(id);
    const btn = document.getElementById(`${id}_eye`);

    if (input.type === "password") {
      input.type = "text";
      btn.classList.add("active_pass");
    } else {
      input.type = "password";
      btn.classList.remove("active_pass");
    }
  };

  // Function to validate the password based on given rules
  const validatePassword = (password) => {
    const validations = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      numeric: /\d/.test(password),
      special: /[@$%^&*!#%]/.test(password),
    };

    setPasswordValidations(validations);
    setPasswordErrors(Object.entries(validations).filter(([_, valid]) => !valid).map(([rule]) => rule));
  };

  // Function to check if the password and confirm password fields match
  const checkPasswordsMatch = (password, confirmPassword) => {
    setPasswordsMatch(password === confirmPassword);
  };

  // Handler for password input change
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
    checkPasswordsMatch(newPassword, confirmPassword);
    setShowErrorMessages(false);
  };

  // Handler for confirm password input change
  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    checkPasswordsMatch(password, newConfirmPassword);
    setShowErrorMessages(false);
  };

  // Handler for the save button
  const handleSave = () => {
    if (!passwordsMatch || passwordErrors.length > 0) {
      setShowErrorMessages(true);
      return;
    }

    setPreloader(true);
    const storedUserId = localStorage?.getItem('user');
    const parsedUserId = JSON.parse(storedUserId);
    const storedSessionId = localStorage?.getItem('sessionId');

    if (storedUserId && parsedUserId && storedSessionId) {
      const queryParams = `?userId=${parsedUserId.id}&NewPassword=${encodeURIComponent(password)}&RoleName=${parsedUserId?.roleName}&SessionId=${storedSessionId}`;

      axiosInstance.post(`/api/User/ChangePassword${queryParams}`)
        .then(response => {
          setPreloader(false);
          if (response.data.status === 200) {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "success" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          } else {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          }
        })
        .catch(error => {
          setPreloader(false);
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.response.data.message, type: "error" }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        });
    } else {
      console.error('User ID, Session ID, or token is not available');
    }
  };



  return (
    <div className={`tab-pane fade ${activeTab === "Change_Password" ? "show active" : ""}`} id="Change_Password">
      {preloader ? (
        <Preloader />
      ) : (
        <div className="lm-card">
          {
            showErrorMessages && !passwordsMatch && (
              <div className="password-match-toast d-flex justify-content-center">
                <img className="mr-2" src={warning_icon} alt="" />
                Passwords must match
              </div>
            )
          }

          {
            showErrorMessages && passwordErrors.length > 0 && (
              <div className="password-match-toast d-flex justify-content-center">
                <img className="mr-2" src={warning_icon} alt="" />
                Password validations failed
              </div>
            )
          }

          <h3 className="lm-card-heading">Change Password</h3>
          <form>
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <div className={`form-group input-password ${passwordsMatch ? 'success' : ''}`}>
                  <label htmlFor="password">Password</label>
                  <div className="customtooltip">
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="Enter a password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <img
                      onClick={() => showPassword("password")}
                      id="password_eye"
                      className="pass_eye active_pass"
                      src={pass_eye}
                      alt=""
                    />
                    {renderPasswordHelperText(passwordValidations)}
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3">
                <div className={`form-group input-password ${passwordsMatch ? 'success' : ''}`}>
                  <label htmlFor="confirm_password">Confirm Password</label>
                  <div className="customtooltip">
                    <input
                      type="password"
                      className="form-control"
                      id="confirm_password"
                      placeholder="Enter a password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    <img
                      onClick={() => showPassword("confirm_password")}
                      id="confirm_password_eye"
                      className="pass_eye active_pass"
                      src={pass_eye}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <button type="button" className="lm-button1" onClick={handleSave}>
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;

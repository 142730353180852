import { FormBuilder } from "react-reactive-form";
import Constants from "./Constants";
import reducersAndActions from "../_redux/slices";

// lstCreditMemoGuarantorsCreditScore Starts ----------------------------------
export const createLstCreditMemoGuarantorsCreditScoreList = (creditMemoDetails) => {
    const defaultValues = { id: 0, creditMemoId: 0, userId: 0, isGuarantors: false, guarantorsCreditScore: 0, guarantorsCreditScoreDate: "", guarantorsCreditAgency: "", isDeleted: 0, userName: "" };
    const mergedValues = { ...defaultValues, ...creditMemoDetails };

    return FormBuilder.group({
        id: [mergedValues.id],
        creditMemoId: [mergedValues.creditMemoId],
        userId: [mergedValues.userId],
        isGuarantors: [mergedValues.isGuarantors],
        guarantorsCreditScore: [mergedValues.guarantorsCreditScore],
        guarantorsCreditScoreDate: [mergedValues.guarantorsCreditScoreDate],
        guarantorsCreditAgency: [mergedValues.guarantorsCreditAgency],
        isDeleted: [mergedValues.isDeleted],
        userName: [mergedValues.userName]
    });
}
// lstCreditMemoGuarantorsCreditScore Ends

// lstCreditMemoFee Starts ----------------------------------
export const createLstCreditMemoFeeList = (creditMemoDetails) => {
    const defaultValues = { id: 0, creditMemoId: 0, keyName: "", keyValue: "", isDeleted: 0 };
    const mergedValues = { ...defaultValues, ...creditMemoDetails };

    return FormBuilder.group({
        id: [mergedValues.id],
        creditMemoId: [mergedValues.creditMemoId],
        keyName: [mergedValues.keyName],
        keyValue: [mergedValues.keyValue],
        isDeleted: [mergedValues.isDeleted]
    });
}
// lstCreditMemoFee Ends

// lstCreditMemoFin_Exp Starts ----------------------------------
export const createLstCreditMemoFin_ExpList = (creditMemoDetails) => {
    const defaultValues = { id: 0, creditMemoId: 0, userId: 0, remarks: "", type: "", isDeleted: 0, userName: "" };
    const mergedValues = { ...defaultValues, ...creditMemoDetails };

    return FormBuilder.group({
        id: [mergedValues.id],
        creditMemoId: [mergedValues.creditMemoId],
        userId: [mergedValues.userId],
        remarks: [mergedValues.remarks],
        type: [mergedValues.type],
        isDeleted: [mergedValues.isDeleted],
        userName: [mergedValues.userName]
    });
}
// lstCreditMemoFin_Exp Ends

// lstCreditMemoBankExposure Starts ----------------------------------
export const createLstCreditMemoBankExposureList = (creditMemoDetails) => {
    const defaultValues = { id: 0, creditMemoId: 0, userId: 0, riskGrade: 0, customerHistory: 0, customerSince: "", approvalRequired: "", currentBalance: 0.00, availableCredit: 0.00, currentRequest: 0.00, totalExposure: 0.00, isDeleted: 0, userName: "" };
    const mergedValues = { ...defaultValues, ...creditMemoDetails };

    return FormBuilder.group({
        id: [mergedValues.id],
        creditMemoId: [mergedValues.creditMemoId],
        userId: [mergedValues.userId],
        riskGrade: [mergedValues.riskGrade],
        customerHistory: [mergedValues.customerHistory],
        customerSince: [mergedValues.customerSince],
        approvalRequired: [mergedValues.approvalRequired],
        currentBalance: [mergedValues.currentBalance],
        availableCredit: [mergedValues.availableCredit],
        currentRequest: [mergedValues.currentRequest],
        totalExposure: [mergedValues.totalExposure],
        isDeleted: [mergedValues.isDeleted],
        userName: [mergedValues.userName]
    });
}
// lstCreditMemoBankExposure Ends

// lstCreditMemoStrengthWeakness Starts ----------------------------------
export const createLstCreditMemoStrengthWeaknessList = (creditMemoDetails) => {
    const defaultValues = { id: 0, creditMemoId: 0, categoryId: "", details: "", type: "", isDeleted: 0 };
    const mergedValues = { ...defaultValues, ...creditMemoDetails };

    return FormBuilder.group({
        id: [mergedValues.id],
        creditMemoId: [mergedValues.creditMemoId],
        categoryId: [mergedValues.categoryId],
        details: [mergedValues.details],
        type: [mergedValues.type],
        isDeleted: [mergedValues.isDeleted]
    });
}
// lstCreditMemoStrengthWeakness Ends

// lstCreditMemoThirdPartyReport Starts ----------------------------------
export const createLstCreditMemoThirdPartyReportList = (creditMemoDetails) => {
    const defaultValues = { id: 0, creditMemoId: 0, reportType: "", reportDate: "", vendorName: "", comment: "", isDeleted: 0 };
    const mergedValues = { ...defaultValues, ...creditMemoDetails };

    return FormBuilder.group({
        id: [mergedValues.id],
        creditMemoId: [mergedValues.creditMemoId],
        reportType: [mergedValues.reportType],
        reportDate: [mergedValues.reportDate],
        vendorName: [mergedValues.vendorName],
        comment: [mergedValues.comment],
        isDeleted: [mergedValues.isDeleted]
    });
}
// lstCreditMemoThirdPartyReport Ends

// lstCreditMemoKeysMitigantConditionException Starts ----------------------------------
export const lstCreditMemoKeysMitigantConditionException = (creditMemoDetails) => {
    const defaultValues = { id: 0, creditMemoId: 0, details: "", type: "", isDeleted: 0 };
    const mergedValues = { ...defaultValues, ...creditMemoDetails };

    return FormBuilder.group({
        id: [mergedValues.id],
        creditMemoId: [mergedValues.creditMemoId],
        details: [mergedValues.details],
        type: [mergedValues.type],
        isDeleted: [mergedValues.isDeleted]
    });
}

const functionMap = {
    lstCreditMemoGuarantorsCreditScore: createLstCreditMemoGuarantorsCreditScoreList,
    lstCreditMemoFee: createLstCreditMemoFeeList,
    lstCreditMemoFin_Exp: createLstCreditMemoFin_ExpList,
    lstCreditMemoBankExposure: createLstCreditMemoBankExposureList,
    lstCreditMemoStrengthWeakness: createLstCreditMemoStrengthWeaknessList,
    lstCreditMemoThirdPartyReport: createLstCreditMemoThirdPartyReportList,

    lstCreditMemoStrength: createLstCreditMemoStrengthWeaknessList,
    lstCreditMemoWeakness: createLstCreditMemoStrengthWeaknessList,
    lstCreditMemoKeyRisk: lstCreditMemoKeysMitigantConditionException,
    lstCreditMemoMitigants: lstCreditMemoKeysMitigantConditionException,
    lstCreditMemoCondition: lstCreditMemoKeysMitigantConditionException,
    lstCreditMemoException: lstCreditMemoKeysMitigantConditionException,

    lstCreditMemoGuarantorsExperience: createLstCreditMemoFin_ExpList,
    lstCreditMemoFinanicalsAffiliates: createLstCreditMemoFin_ExpList,
    lstCreditMemoFinanicalsGuarantors: createLstCreditMemoFin_ExpList,
};


export const returnCreditMemoFormGroupInArray = (creditMemoData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    while (groupControl.length) {
        groupControl.removeAt(0);
    }
    creditMemoData.forEach((key) => {
        const createFunction = functionMap[keyName];
        if (!createFunction) throw new Error(`Unknown keyName: ${keyName}`);

        const creditMemo = createFunction(key);
        groupControl.push(creditMemo);
    });

}

// Add Checkings
export const addCheckings = ({ formGroup, keyName, fieldName, dispatch, type = "" }) => {
    const checkings = formGroup.get(keyName);
    const params = { creditMemoId: formGroup.get('id').value, keyName: fieldName, type: type };

    let elementValidated = true;
    if (checkings.value.length > 0) {
        if (type !== "") {
            const lastTypeElement = checkings.value.filter(element => element.type === type);
            const lastElement = lastTypeElement[lastTypeElement.length - 1];
            if (lastElement) {
                elementValidated = lastElement.isDeleted === 1 || Object.values(lastElement).every(value => value !== "" && value !== null && value !== undefined);
            }
        } else {
            const lastElement = checkings.value[checkings.value.length - 1];
            elementValidated = lastElement.isDeleted === 1 || Object.values(lastElement).every(value => value !== "" && value !== null && value !== undefined);
        }
    }

    if (elementValidated) {
        const createFunction = functionMap[keyName];
        if (!createFunction) throw new Error(`Unknown keyName: ${keyName}`);

        const creditMemo = createFunction(params);
        checkings.push(creditMemo);
    } else {
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Please fill all the element before adding a new one", type: "error" }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
    }
};

// Function to remove a user from the form array
export const removeCheckings = (index, formGroup, keyName) => {
    const checkings = formGroup.get(keyName);
    const newData = checkings.controls.map((ctrl, i) => {
        if (i === index) {
            if (ctrl.get('id')?.value !== 0) {
                ctrl.get('isDeleted').setValue(1);
            } else {
                ctrl = null;
            }
        }
        return ctrl;
    }).filter(ctrl => ctrl !== null);

    while (checkings.length) checkings.removeAt(0);
    setTimeout(() => {
        newData.forEach(ctrl => {
            checkings.push(ctrl);
        });
    }, 10);
};


// Function to remove a user from the form array
export const removeAllCheckings = (formGroup, keyName) => {
    const checkings = formGroup.get(keyName);
    while (checkings && checkings.length) {
        checkings.removeAt(0);
    }
}
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import FormRadio from "../../reusablecomponets/FormRadio";
import CreatableSelect from "../../reusablecomponets/CreatableSelect";
import { actions } from '../../_redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { decryptText, validStringValidator } from "../../helpers/DynamicFunctions";
import { FieldGroup, FormBuilder, Validators } from "react-reactive-form";
import reducersAndActions from "../../_redux/slices/index";
import FormTextArea from "../../reusablecomponets/FormTextArea";
import FormField from '../../components/FormField/FormField';
import { PeakSeasonOptions, LowSeasonOptions } from '../../screens/loan-app-tabs/personalProfile/commonFunction';
import Constants from '../../helpers/Constants';
import OpenAIComponent from '../../components/OpenAI/OpenAIComponent';
import WeeklyTimeComponent from '../../components/WeeklyTime/WeeklyTImeComponent';
import AmountContainer from '../../components/Amount/AmountContainer';

let tempDaySchedule = {};
let tempDayScheduleConv = {};
function IndustryGasStation() {
    const dispatch = useDispatch();

    const params = useParams();
    const parsedLoanId = decryptText(params.loanId);

    // Meta Title
    useEffect(() => {
        setForm(form);
        document.title = "Gas Station Information - Loan Application";
        setSelectOptions({ geographicMarket: [], customerVendors: [], peakSeason: [], lowSeason: [] });
        setShowErroeMessage(false);
    }, []);


    const [dayTimeSchedule, setDayTimeSchedule] = useState({ monday: {}, tuesday: {}, wednesday: {}, thursday: {}, friday: {}, saturday: {}, sunday: {} });
    const [dayTimeScheduleConv, setDayTimeScheduleConv] = useState({ monday: {}, tuesday: {}, wednesday: {}, thursday: {}, friday: {}, saturday: {}, sunday: {} });

    const [form, setForm] = React.useState(
        FormBuilder.group({
            id: [0],
            loanId: [parsedLoanId],
            geographicMarket: ["", [Validators.required, validStringValidator]],
            franchiseGas: ["", [Validators.required, validStringValidator]],
            franchiseConvenience: ["", [Validators.required, validStringValidator]],
            suppliersVendorTerms: ["", [Validators.required, validStringValidator]],
            competitiveAdvantages: ["", [Validators.required, validStringValidator]],
            seasonalBusiness: [""],
            buildingPercentageOffice: ["", [Validators.required, validStringValidator]],
            buildingPercentageService: ["", [Validators.required, validStringValidator]],
            buildingPercentageConvenience: ["", [Validators.required, validStringValidator]],
            hoursOperationGas: ["", [Validators.required, validStringValidator]],
            numberFuelingPositions: ["", [Validators.required, validStringValidator]],
            numberFullPumps: ["", [Validators.required, validStringValidator]],
            numberServicePumps: ["", [Validators.required, validStringValidator]],
            numberServeDiesel: ["", [Validators.required, validStringValidator]],
            numberServiceBays: ["", [Validators.required, validStringValidator]],
            fleetCustomers: [""],
            approxRegular: ["", [Validators.required, validStringValidator]],
            approxPlus: ["", [Validators.required, validStringValidator]],
            approxPremium: ["", [Validators.required, validStringValidator]],
            approxDiesel: ["", [Validators.required, validStringValidator]],
            additionalComments: [""],
            modifiedBy: [0],
            customerVendors: ["", [Validators.required, validStringValidator]],
            keyCompetitors: ["", [Validators.required, validStringValidator]],
            peakSeason: [""],
            lowSeason: [""],
            peakSeasonName: [""],
            fuelSold: [""],
            fuelSoldDetails: [""]
        })
    );

    useEffect(() => {
        dispatch(actions.gasStationForm.fetchGasStationData({ loanId: parsedLoanId, type: 'Gas Station' }));
    }, [dispatch, parsedLoanId]);

    const { data, loading } = useSelector((state) => state.gasStationForm);

    const [selectOptions, setSelectOptions] = useState({ geographicMarket: [], customerVendors: [], peakSeason: [], lowSeason: [] });
    const [showErroeMessage, setShowErroeMessage] = useState(false);

    useEffect(() => {
        try {
            if (data && data.resultObject && Object.keys(data.resultObject).length > 0) {
                const resultObject = data.resultObject
                console.log(resultObject)

                const geographicMarketOptions = resultObject.geographicMarket ? resultObject.geographicMarket.split(',').map(value => ({ label: value, value })) : [];
                const customerVendorsOptions = resultObject.customerVendors ? resultObject.customerVendors.split(',').map(value => ({ label: value, value })) : [];
                const peakSeasonOptions = resultObject.peakSeason ? resultObject.peakSeason.split(',').map(value => ({
                    value,
                    label: PeakSeasonOptions.find(option => option.value === value)?.label || value
                })) : [];
                const lowSeasonOptions = resultObject.lowSeason ? resultObject.lowSeason.split(',').map(value => ({
                    value,
                    label: LowSeasonOptions.find(option => option.value === value)?.label || value
                })) : [];


                setSelectOptions({
                    geographicMarket: geographicMarketOptions,
                    customerVendors: customerVendorsOptions,
                    peakSeason: peakSeasonOptions,
                    lowSeason: lowSeasonOptions
                });

                form.patchValue({
                    ...resultObject,
                    loanId: resultObject.loanId || parsedLoanId,
                    seasonalBusiness: resultObject.seasonalBusiness ? resultObject.seasonalBusiness.toString() : 'false',
                    fleetCustomers: resultObject.fleetCustomers ? resultObject.fleetCustomers.toString() : 'false',
                });

                resultObject.lstHoursOperation.forEach((item) => {
                    if (item.serviceType === 'services') {
                        tempDaySchedule[item.daysName] = item;
                    } else {
                        tempDayScheduleConv[item.daysName] = item;
                    }
                });

                setDayTimeSchedule((prev) => ({ ...prev, ...tempDaySchedule }));
                setDayTimeScheduleConv((prev) => ({ ...prev, ...tempDayScheduleConv }));
            } else {
                form.reset({ id: 0, loanId: parsedLoanId, modifiedBy: 0, seasonalBusiness: 'false', fleetCustomers: 'false' });
            }
        } catch (error) {
            console.error("Error parsing resultObject", error);
            form.reset({ id: 0, loanId: parsedLoanId, modifiedBy: 0, seasonalBusiness: 'false', fleetCustomers: 'false' });
        }
    }, [data, setSelectOptions, form]);

    useEffect(() => {
        tempDaySchedule = { ...dayTimeSchedule };
        tempDayScheduleConv = { ...dayTimeScheduleConv };
    }, [dayTimeSchedule, dayTimeScheduleConv]);

    const handleSubmit = (() => {
        setShowErroeMessage(true);

        if (form.status !== "INVALID") {
            const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
            weekDays.forEach(day => {
                if (!tempDaySchedule.hasOwnProperty(day)) {
                    tempDaySchedule[day] = { startDate: '09:00 AM', endDate: '06:00 PM', timeHour: '12:00', status: true, daysName: day, id: 0, industryId: 0 };
                }
                if (!tempDayScheduleConv.hasOwnProperty(day)) {
                    tempDayScheduleConv[day] = { startDate: '09:00 AM', endDate: '06:00 PM', timeHour: '12:00', status: true, daysName: day, id: 0, industryId: 0 };
                }
            })

            const allHoursOfOperation = Object.values(tempDaySchedule).concat(Object.values(tempDayScheduleConv));
            let finalData = { ...form.value, lstHoursOperation: allHoursOfOperation };

            finalData['fuelSoldDetails'] = finalData['fuelSold'] === 'true' ? finalData['fuelSoldDetails'] : '';
            finalData['peakSeason'] = finalData['seasonalBusiness'] === 'true' ? finalData['peakSeason'] : '';
            finalData['lowSeason'] = finalData['seasonalBusiness'] === 'true' ? finalData['lowSeason'] : '';

            dispatch(actions.gasStationForm.submitGasStationForm(finalData))
                .then((response) => {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.payload.message, type: "success" }));
                    dispatch(actions.gasStationForm.fetchGasStationData({ loanId: parsedLoanId, type: 'Gas Station' }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    setShowErroeMessage(false);
                })
                .catch(() => {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Error submitting form", type: "error" }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                });
        } else {
            markAllAsTouched(form);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Please filled required fields", type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    });

    const handleSelectChange = (name, selectedOption) => {
        const currentSelectedValues = selectedOption || [];
        const selectedValues = currentSelectedValues.map(option => option.value);

        // Allow removal if the new selection is less than or equal to the current one
        if (currentSelectedValues.length <= 10) {
            setSelectOptions(prevOptions => ({ ...prevOptions, [name]: currentSelectedValues.filter(opt => opt.value.trim() !== "" && opt.value.length <= 50) }));
            form.controls[name].setValue(selectedValues.join(','));
        } else {
            if (selectOptions[name]?.length < currentSelectedValues.length) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "You can't select more than 10 options", type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);

                setSelectOptions(prevOptions => ({ ...prevOptions, [name]: prevOptions[name] }));
            }
        }
    };


    const markAllAsTouched = (formGroup) => {
        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control && control.status === "INVALID") {
                control.markAsTouched();
                control.updateValueAndValidity();
                if (document.getElementById(controlName)) {
                    document.getElementById(controlName).focus();
                }
            }
        });
    };


    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>


                <div className="lm-form-box pb-5">
                    <FieldGroup
                        control={form}
                        render={({ get }) => (
                            <form>
                                {/* Gas Station Information section */}
                                <div className="lm-card">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">Gas Station Information</h3>
                                        </div>
                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="geographicMarket"
                                                label="Geographic markets"
                                                value={selectOptions.geographicMarket}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                                required={true}
                                            />
                                            {showErroeMessage && !form.value.geographicMarket && <div className='error-message mt-2 mb-3'>Required field</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="customerVendors"
                                                label="Major customers"
                                                value={selectOptions.customerVendors}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                                required={true}
                                            />
                                            {showErroeMessage && !form.value.customerVendors && <div className='error-message mt-2 mb-3'>Required field</div>}
                                        </div>

                                        <div className="col-md-6 mt-3">
                                            <div className="form-group AI_btn_field">
                                                <FormTextArea
                                                    id="suppliersVendorTerms"
                                                    label="Major suppliers & vendor terms"
                                                    name="suppliersVendorTerms"
                                                    placeholder="Enter Text"
                                                    required={true}
                                                    errorMessage="Required field"
                                                />
                                                <OpenAIComponent
                                                    fieldName="suppliersVendorTerms"
                                                    formGroup={form}
                                                    type="textarea"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <div className="form-group AI_btn_field">
                                                <FormTextArea
                                                    id="competitiveAdvantages"
                                                    label="Competitive advantage"
                                                    name="competitiveAdvantages"
                                                    placeholder="Enter Text"
                                                    required={true}
                                                    errorMessage="Required field"
                                                />
                                                <OpenAIComponent
                                                    fieldName="competitiveAdvantages"
                                                    formGroup={form}
                                                    type="textarea"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 mt-3">
                                            <div className="form-group AI_btn_field">
                                                <FormField
                                                    id="keyCompetitors"
                                                    label="Key competitors"
                                                    name="keyCompetitors"
                                                    placeholder="Enter Text"
                                                    required={true}
                                                    errorMessage="Required field"
                                                />
                                                <OpenAIComponent
                                                    fieldName="keyCompetitors"
                                                    formGroup={form}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 mt-3">
                                            <FormField
                                                id="numberFuelingPositions"
                                                label="Numbers of years left in fuel contract?"
                                                name="numberFuelingPositions"
                                                placeholder="Enter Text"
                                                required={true}
                                                errorMessage="Required field"
                                                maxlength={6}
                                                onlyNumbers={true}
                                            />
                                        </div>

                                        <div className="col-md-3 mt-3">
                                            <FormRadio
                                                name="fleetCustomers"
                                                label="Are their “fleet” customers?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                        </div>

                                        <div className="col-md-4 col-lg-3">
                                            <FormField
                                                id="numberFullPumps"
                                                label="How many pumps are full service?"
                                                name="numberFullPumps"
                                                placeholder="Enter Text"
                                                required={true}
                                                errorMessage="Required field"
                                                maxlength={6}
                                                onlyNumbers={true}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormField
                                                id="numberServicePumps"
                                                label="How many pumps are self-service?"
                                                name="numberServicePumps"
                                                placeholder="Enter Text"
                                                required={true}
                                                errorMessage="Required field"
                                                maxlength={6}
                                                onlyNumbers={true}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormField
                                                id="numberServeDiesel"
                                                label="How many despense diesel?"
                                                name="numberServeDiesel"
                                                placeholder="Enter Text"
                                                required={true}
                                                errorMessage="Required field"
                                                maxlength={6}
                                                onlyNumbers={true}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormField
                                                id="numberServiceBays"
                                                label="How many service days?"
                                                name="numberServiceBays"
                                                placeholder="Enter Text"
                                                required={true}
                                                errorMessage="Required field"
                                                maxlength={6}
                                                onlyNumbers={true}
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <FormRadio
                                                name="fuelSold"
                                                label="Any other fuel sold?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                            />
                                        </div>

                                        {form.value.fuelSold === "true" &&
                                            <>
                                                <div className="col-md-12">
                                                    <FormField
                                                        id="fuelSoldDetails"
                                                        label="Provide detail"
                                                        name="fuelSoldDetails"
                                                        placeholder="Enter Text"
                                                    />
                                                </div>
                                            </>
                                        }


                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">
                                            <FormRadio
                                                name="seasonalBusiness"
                                                label="Is this a seasonal business?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                                errorMessage="This field is required."
                                            />
                                        </div>

                                        {form.value.seasonalBusiness === "true" &&
                                            <>
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <CreatableSelect
                                                                name="peakSeason"
                                                                id="peakSeason"
                                                                label="Peak Season"
                                                                value={selectOptions.peakSeason}
                                                                onChange={handleSelectChange}
                                                                placeholder="Select..."
                                                                options={PeakSeasonOptions}
                                                                isMulti
                                                                isClearable
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <CreatableSelect
                                                                name="lowSeason"
                                                                label="Off Season"
                                                                value={selectOptions.lowSeason}
                                                                onChange={handleSelectChange}
                                                                placeholder="Select..."
                                                                options={LowSeasonOptions}
                                                                isMulti
                                                                isClearable
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                    </div>
                                </div>
                                {/* Gas Station Information section */}


                                {/* Franchise / Flag Section */}
                                <div className="lm-card">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">Brand</h3>
                                        </div>
                                        <div className="col-md-6">
                                            <FormField
                                                id="franchiseGas"
                                                label="Fuel"
                                                name="franchiseGas"
                                                placeholder="Enter Text"
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <FormField
                                                id="franchiseConvenience"
                                                label="Convenience store"
                                                name="franchiseConvenience"
                                                placeholder="Enter Text"
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Franchise / Flag Section */}


                                {/* Building Percentage of Sq. Ft Section */}
                                <div className="lm-card">
                                    <h3 className="lm-card-heading">Occupency</h3>
                                    <div className="row">
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group input-percentage">
                                                <AmountContainer
                                                    id="buildingPercentageConvenience"
                                                    label="Convenience store"
                                                    name="buildingPercentageConvenience"
                                                    placeholder="0.00"
                                                    isRequired={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group input-percentage">
                                                <AmountContainer
                                                    id="buildingPercentageService"
                                                    label="Canopy"
                                                    name="buildingPercentageService"
                                                    placeholder="0.00"
                                                    isRequired={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group input-percentage">
                                                <AmountContainer
                                                    id="buildingPercentageOffice"
                                                    label="Office"
                                                    name="buildingPercentageOffice"
                                                    placeholder="0.00"
                                                    isRequired={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Building Percentage of Sq. Ft Section */}


                                {/* What is your approximate general mark up on the following Section */}
                                <div className="lm-card">
                                    <h3 className="lm-card-heading">Average mark-up</h3>
                                    <div className="row">
                                        <div className="col-md-4 col-lg-3">
                                            <AmountContainer
                                                id="approxRegular"
                                                label="Regular"
                                                name="approxRegular"
                                                placeholder="0.00"
                                                isRequired={true}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <AmountContainer
                                                id="approxPlus"
                                                label="Plus"
                                                name="approxPlus"
                                                placeholder="0.00"
                                                isRequired={true}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <AmountContainer
                                                id="approxPremium"
                                                label="Premium"
                                                name="approxPremium"
                                                placeholder="0.00"
                                                isRequired={true}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <AmountContainer
                                                id="approxDiesel"
                                                label="Diesel"
                                                name="approxDiesel"
                                                placeholder="0.00"
                                                isRequired={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* What is your approximate general mark up on the following Section */}


                                {/* Hours of operation Section */}
                                <div className="lm-card">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">Hours of operation</h3>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FormField
                                                id="hoursOperationGas"
                                                label="Fuel"
                                                name="hoursOperationGas"
                                                placeholder="0.00"
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                        </div>

                                        <div className='col-12'>
                                            <h3 className="lm-card-heading mt-2">Services</h3>
                                            <div className='row'>
                                                <WeeklyTimeComponent
                                                    weekday="monday"
                                                    serviceType="services"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="GasStation"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="tuesday"
                                                    serviceType="services"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="GasStation"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="wednesday"
                                                    serviceType="services"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="GasStation"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="thursday"
                                                    serviceType="services"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="GasStation"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="friday"
                                                    serviceType="services"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="GasStation"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="saturday"
                                                    serviceType="services"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="GasStation"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="sunday"
                                                    serviceType="services"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="GasStation"
                                                />
                                            </div>
                                        </div>

                                        <div className='col-12'>
                                            <h3 className="lm-card-heading mt-2">Conveniences</h3>
                                            <div className='row'>
                                                <WeeklyTimeComponent
                                                    weekday="monday"
                                                    serviceType="conveniences"
                                                    setDayTimeSchedule={setDayTimeScheduleConv}
                                                    value={dayTimeScheduleConv}
                                                    industryType="GasStation"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="tuesday"
                                                    serviceType="conveniences"
                                                    setDayTimeSchedule={setDayTimeScheduleConv}
                                                    value={dayTimeScheduleConv}
                                                    industryType="GasStation"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="wednesday"
                                                    serviceType="conveniences"
                                                    setDayTimeSchedule={setDayTimeScheduleConv}
                                                    value={dayTimeScheduleConv}
                                                    industryType="GasStation"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="thursday"
                                                    serviceType="conveniences"
                                                    setDayTimeSchedule={setDayTimeScheduleConv}
                                                    value={dayTimeScheduleConv}
                                                    industryType="GasStation"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="friday"
                                                    serviceType="conveniences"
                                                    setDayTimeSchedule={setDayTimeScheduleConv}
                                                    value={dayTimeScheduleConv}
                                                    industryType="GasStation"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="saturday"
                                                    serviceType="conveniences"
                                                    setDayTimeSchedule={setDayTimeScheduleConv}
                                                    value={dayTimeScheduleConv}
                                                    industryType="GasStation"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="sunday"
                                                    serviceType="conveniences"
                                                    setDayTimeSchedule={setDayTimeScheduleConv}
                                                    value={dayTimeScheduleConv}
                                                    industryType="GasStation"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Hours of operation Section */}


                                {/* <!--  lm common divider to break the section  --> */}
                                <div className="lm-divider my-4 mx-3"></div>
                                {/* <!--  lm common divider to break the section  --> */}

                                <div className="lm-card">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">Other</h3>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group AI_btn_field">
                                                <FormTextArea
                                                    id="additionalComments"
                                                    label="Additional comments"
                                                    name="additionalComments"
                                                    placeholder="Enter Text"
                                                />
                                                <OpenAIComponent
                                                    fieldName="additionalComments"
                                                    formGroup={form}
                                                    type="textarea"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="sticky-btn-footer d-flex justify-content-end">
                                    <button type="button" className="lm-button1 ml-3" onClick={handleSubmit}>Save</button>
                                </div>
                            </form>
                        )}
                    />
                </div>





            </div>)}

        </>
    );
}

export default IndustryGasStation;
import send_email_icon from '../assets/icons/email_icon_color.svg';
import Info_tooltip from '../assets/icons/Info-tooltip.svg';
import user_avtar_grey from '../assets/icons/user_avtar_grey.svg';
import moment from 'moment';
import OpenAIComponent from '../components/OpenAI/OpenAIComponent'

const ratingArray = [1, 2, 3, 4, 5];
const ShowRatingQuestions = ({ data, indexData, isDisabled, setEmailFields, setSendEmailPopup, setAppIndexData, setHardDataOfOpenAI, accordianClass, handleAccordion, handleRatingApprovals, info, accordionIndex, user }) => {
    return (
        data.length > 0 && data.map((app, index) => (
            <div className='user-applicaion-dtls mt-3'>
                <div className='d-flex justify-content-between'>
                    <div>
                        <div className='d-flex gap-10'>
                            <h1 className="appliaction-no mr-2">{app.applicationNumber}
                            </h1>
                            <div className='lender-user-name'>
                                <img src={user_avtar_grey} alt=""/>
                                {app.advisorName}
                            </div>
                        </div>
                        <small>Created on {moment(app.createdDate).format("MMM DD, YYYY")}</small>
                    </div>
                    {
                        isDisabled || indexData[app.loanId + "_" + app.advisorId]['id'] ? null : (
                            <button type='button' className='lm-button2'
                                onClick={() => {
                                    setEmailFields({
                                        to: app.advisorEmail,
                                        body: '',
                                        loanId: app.loanId,
                                        applicationNumber: app.applicationNumber,
                                        createdDate: app.createdDate,
                                        borrowAmount: app.borrowAmount,
                                        businessCategory: app.businessCategory,
                                        typeOfBusiness: app.typeOfBusiness,
                                        applicationStatus: app.applicationStatus,
                                        lenderId: info.id,
                                        advisorId: app.advisorId,
                                        advisorName: app.advisorName,
                                    });
                                    setSendEmailPopup(true)
                                }}
                            >
                                <img width={14} src={send_email_icon} alt=""/>
                                Send Email
                            </button>
                        )
                    }
                </div>
                <div className="user-applicaion-dtls-accordian mt-2">
                    <div
                        className={`cursor-pointer user-applicaion-dtls-accordian-button ${isDisabled
                            ? 'open-user-applicaion-dtls-accordian'
                            : accordianClass(app.loanId + "_" + app.advisorId)
                            }`}
                        onClick={() => {
                            if (isDisabled) return null
                            handleAccordion(app.loanId + "_" + app.advisorId)
                        }}
                    >
                        Details
                    </div>
                    {
                        (accordionIndex[app.loanId + "_" + app.advisorId] || isDisabled) && (
                            <>
                                <div className="lm-divider mt-2 mb-0"></div>
                                <div className='row pt-3'>
                                    <div className='col-md-3 col-6'>
                                        <div className='label'>Loan Amount</div>
                                        <div className='value'>${app.borrowAmount}</div>
                                    </div>
                                    <div className='col-md-3 col-6'>
                                        <div className='label'>Business Category</div>
                                        <div className='value'>{(app.businessCategory && app.businessCategory !== 'undefined') ? app.businessCategory : "N/A"}</div>
                                    </div>
                                    <div className='col-md-3 col-6'>
                                        <div className='label'>Type of Business</div>
                                        <div className='value'>{app.typeOfBusiness}</div>
                                    </div>
                                    <div className='col-md-3 col-6'>
                                        <div className='label'>Deal Closed</div>
                                        <div className='value'>{app.applicationStatus === "Closed" ? "Yes" : "No"}</div>
                                    </div>
                                </div>


                                {
                                    (isDisabled || indexData[app.loanId + "_" + app.advisorId]['id'] !== 0) && (
                                        <>
                                            <div className="lm-divider mt-2"></div>
                                            <div className='row mt-3'>
                                                <div className='col-12'>
                                                    <h3 className="lm-card-heading">Rating
                                                        <span className="lm-tooltip ml-1" data-tooltip="Based on your experience with the transaction listed above, please rate the lender on a scale from 1 to 5" data-tooltip-pos="right" data-tooltip-length="cutsom"> <img src={Info_tooltip} alt="" /> </span>
                                                    </h3>
                                                </div>
                                                <div className='col-md-6 mb-2'>
                                                    <div className="form-group">
                                                        <div className="label pb-1">
                                                            How would you rate the effectiveness of your lender's communication with you and others throughout the loan process? (1 is least effective and 5 is highly effective).<span className="asterisk">*</span>
                                                        </div>
                                                        {
                                                            ratingArray.map((item, index) => (
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name={'communication_' + app.loanId + "_" + app.advisorId}
                                                                        id={'communication_' + item + '_' + app.loanId + "_" + app.advisorId}
                                                                        checked={indexData[app.loanId + "_" + app.advisorId]['communication'] === item}
                                                                        onChange={() => setAppIndexData({ ...indexData, [app.loanId + "_" + app.advisorId]: { ...indexData[app.loanId + "_" + app.advisorId], communication: item } })}
                                                                        disabled={indexData[app.loanId + "_" + app.advisorId]['approvedBy'] !== 0}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={'communication_' + item + '_' + app.loanId + "_" + app.advisorId}>{item}</label>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <div className='label text-dark mb-2'>What area of communication needs improvement?<span className="asterisk">*</span> </div>
                                                        <div className='select_feedback d-flex gap-10 flex-wrap'>
                                                            {
                                                                ['Frequency', 'Tone', 'Reliability', 'Other'].map((item, index) => (
                                                                    <div className={indexData[app.loanId + "_" + app.advisorId]['communicationText'] === item ? 'option if_selected' : 'option'}
                                                                        onClick={() => {
                                                                            if (indexData[app.loanId + "_" + app.advisorId]['approvedBy'] !== 0) return null
                                                                            setAppIndexData({ ...indexData, [app.loanId + "_" + app.advisorId]: { ...indexData[app.loanId + "_" + app.advisorId], communicationText: item } })
                                                                        }}
                                                                    >{item}</div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-md-6 mb-2'>
                                                    <div className="form-group">
                                                        <div className="label pb-1">
                                                            To what extent did the lender provide trustworthy expertise regarding the financial products available to you? (1 is no expertise and 5 is deep expertise).<span className="asterisk">*</span>
                                                        </div>
                                                        {
                                                            ratingArray.map((item, index) => (
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name={'expertise_' + app.loanId + "_" + app.advisorId}
                                                                        id={'expertise_' + item + '_' + app.loanId + "_" + app.advisorId}
                                                                        checked={indexData[app.loanId + "_" + app.advisorId]['expertise'] === item}
                                                                        onChange={() => setAppIndexData({ ...indexData, [app.loanId + "_" + app.advisorId]: { ...indexData[app.loanId + "_" + app.advisorId], expertise: item } })}
                                                                        disabled={indexData[app.loanId + "_" + app.advisorId]['approvedBy'] !== 0}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={'expertise_' + item + '_' + app.loanId + "_" + app.advisorId}>{item}</label>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <div className='label text-dark mb-2'>What area of expertise needs improvement?<span className="asterisk">*</span></div>
                                                        <div className='select_feedback d-flex gap-10 flex-wrap'>
                                                            {
                                                                ['SBA', 'CRE', 'Line of Credit', 'Equipment', 'Other'].map((item, index) => (
                                                                    <div className={indexData[app.loanId + "_" + app.advisorId]['expertiseText'] === item ? 'option if_selected' : 'option'}
                                                                        onClick={() => {
                                                                            if (indexData[app.loanId + "_" + app.advisorId]['approvedBy'] !== 0) return null
                                                                            setAppIndexData({ ...indexData, [app.loanId + "_" + app.advisorId]: { ...indexData[app.loanId + "_" + app.advisorId], expertiseText: item } })
                                                                        }}
                                                                    >{item}</div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-md-6 mb-2'>
                                                    <div className="form-group">
                                                        <div className="label pb-1">
                                                            How confident were you in your lender's guidance throughout the loan process, from application to closing? (1 is not confident and 5 is very confident).<span className="asterisk">*</span>
                                                        </div>
                                                        {
                                                            ratingArray.map((item, index) => (
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name={'guidance_' + app.loanId + "_" + app.advisorId}
                                                                        id={'guidance_' + item + '_' + app.loanId + "_" + app.advisorId}
                                                                        checked={indexData[app.loanId + "_" + app.advisorId]['guidance'] === item}
                                                                        onChange={() => setAppIndexData({ ...indexData, [app.loanId + "_" + app.advisorId]: { ...indexData[app.loanId + "_" + app.advisorId], guidance: item } })}
                                                                        disabled={indexData[app.loanId + "_" + app.advisorId]['approvedBy'] !== 0}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={'guidance_' + item + '_' + app.loanId + "_" + app.advisorId}>{item}</label>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <div className='label text-dark mb-2'>What area of guidance needs improvement?<span className="asterisk">*</span> </div>
                                                        <div className='select_feedback d-flex gap-10 flex-wrap'>
                                                            {
                                                                ['Loan Structuring', 'Underwriting ', 'Closing', 'Other'].map((item, index) => (
                                                                    <div className={indexData[app.loanId + "_" + app.advisorId]['guidanceText'] === item ? 'option if_selected' : 'option'}
                                                                        onClick={() => {
                                                                            if (indexData[app.loanId + "_" + app.advisorId]['approvedBy'] !== 0) return null
                                                                            setAppIndexData({ ...indexData, [app.loanId + "_" + app.advisorId]: { ...indexData[app.loanId + "_" + app.advisorId], guidanceText: item } })
                                                                        }}
                                                                    >{item}</div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-md-6 mb-2'>
                                                    <div className="form-group">
                                                        <div className="label pb-1">
                                                            How likely are you to use this lender again in the future? (1 is not at all likely and 5 is very likely).<span className="asterisk">*</span>
                                                        </div>
                                                        {
                                                            ratingArray.map((item, index) => (
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name={'reuseLender' + app.loanId + "_" + app.advisorId}
                                                                        id={'reuseLender' + item + '_' + app.loanId + "_" + app.advisorId}
                                                                        checked={indexData[app.loanId + "_" + app.advisorId]['reuseLender'] === item}
                                                                        onChange={() => setAppIndexData({ ...indexData, [app.loanId + "_" + app.advisorId]: { ...indexData[app.loanId + "_" + app.advisorId], reuseLender: item } })}
                                                                        disabled={indexData[app.loanId + "_" + app.advisorId]['approvedBy'] !== 0}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={'reuseLender' + item + '_' + app.loanId + "_" + app.advisorId}>{item}</label>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <div className='label text-dark mb-2'>What areas need improvement?<span className="asterisk">*</span></div>
                                                        <div className='select_feedback d-flex gap-10 flex-wrap'>
                                                            {
                                                                ['Communication', 'Expertise', 'Friendliness', 'Reliability', 'Other'].map((item, index) => (
                                                                    <div className={indexData[app.loanId + "_" + app.advisorId]['reuseLenderText'] === item ? 'option if_selected' : 'option'}
                                                                        onClick={() => {
                                                                            if (indexData[app.loanId + "_" + app.advisorId]['approvedBy'] !== 0) return null
                                                                            setAppIndexData({ ...indexData, [app.loanId + "_" + app.advisorId]: { ...indexData[app.loanId + "_" + app.advisorId], reuseLenderText: item } })
                                                                        }}
                                                                    >{item}</div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-md-6 mb-2'>
                                                    <div className="form-group">
                                                        <div className="label pb-1">
                                                            How would you rate the level of efficiency, clarity and transparency shown by the lender throughout the loan process? (1 is inefficient, unclear and confusing and 5 is highly efficient, clear and transparent).
                                                        </div>
                                                        {
                                                            ratingArray.map((item, index) => (
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name={'efficiency_' + app.loanId + "_" + app.advisorId}
                                                                        id={'efficiency_' + item + '_' + app.loanId + "_" + app.advisorId}
                                                                        checked={indexData[app.loanId + "_" + app.advisorId]['efficiency'] === item}
                                                                        onChange={() => setAppIndexData({ ...indexData, [app.loanId + "_" + app.advisorId]: { ...indexData[app.loanId + "_" + app.advisorId], efficiency: item } })}
                                                                        disabled={indexData[app.loanId + "_" + app.advisorId]['approvedBy'] !== 0}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={'efficiency_' + item + '_' + app.loanId + "_" + app.advisorId}>{item}</label>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <div className='label text-dark mb-2'>What areas need improvement?<span className="asterisk">*</span></div>
                                                        <div className='select_feedback d-flex gap-10 flex-wrap'>
                                                            {
                                                                ['Confusing', 'Complicated', 'Took longer than expected', 'Other'].map((item, index) => (
                                                                    <div className={indexData[app.loanId + "_" + app.advisorId]['efficiencyText'] === item ? 'option if_selected' : 'option'}
                                                                        onClick={() => {
                                                                            if (indexData[app.loanId + "_" + app.advisorId]['approvedBy'] !== 0) return null
                                                                            setAppIndexData({ ...indexData, [app.loanId + "_" + app.advisorId]: { ...indexData[app.loanId + "_" + app.advisorId], efficiencyText: item } })
                                                                        }}
                                                                    >{item}</div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <div className="form-group AI_btn_field">
                                                        <label>
                                                            What factors influenced your rating of the lender?<span className="asterisk">*</span>
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            id={'factorsInfluence_' + app.loanId + "_" + app.advisorId}
                                                            name={'factorsInfluence_' + app.loanId + "_" + app.advisorId}
                                                            value={indexData[app.loanId + "_" + app.advisorId]['factorsInfluence']}
                                                            onChange={(e) => setAppIndexData({ ...indexData, [app.loanId + "_" + app.advisorId]: { ...indexData[app.loanId + "_" + app.advisorId], factorsInfluence: e.target.value } })}
                                                            placeholder="Enter text"
                                                            disabled={indexData[app.loanId + "_" + app.advisorId]['approvedBy'] !== 0}
                                                            maxLength={250}
                                                        >
                                                        </textarea>
                                                        <OpenAIComponent
                                                            fieldName={'factorsInfluence_' + app.loanId + "_" + app.advisorId}
                                                            value={indexData[app.loanId + "_" + app.advisorId]['factorsInfluence']}
                                                            isFormGroup={false}
                                                            setNestedHardData={true}
                                                            setHardData={setHardDataOfOpenAI}
                                                            type='textarea'
                                                            disabled={indexData[app.loanId + "_" + app.advisorId]['approvedBy'] !== 0}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mb-2">
                                                    <div className="form-group AI_btn_field">
                                                        <label>
                                                            Comment
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            id={'comment_' + app.loanId + "_" + app.advisorId}
                                                            name={'comment_' + app.loanId + "_" + app.advisorId}
                                                            value={indexData[app.loanId + "_" + app.advisorId]['comment']}
                                                            onChange={(e) => setAppIndexData({ ...indexData, [app.loanId + "_" + app.advisorId]: { ...indexData[app.loanId + "_" + app.advisorId], comment: e.target.value } })}
                                                            placeholder="Enter text"
                                                            disabled={indexData[app.loanId + "_" + app.advisorId]['approvedBy'] !== 0}
                                                            maxLength={250}
                                                        >
                                                        </textarea>
                                                        <OpenAIComponent
                                                            fieldName={'comment_' + app.loanId + "_" + app.advisorId}
                                                            value={indexData[app.loanId + "_" + app.advisorId]['comment']}
                                                            isFormGroup={false}
                                                            setNestedHardData={true}
                                                            setHardData={setHardDataOfOpenAI}
                                                            type='textarea'
                                                            disabled={indexData[app.loanId + "_" + app.advisorId]['approvedBy'] !== 0}
                                                        />
                                                    </div>
                                                </div>

                                                {isDisabled ? null : (
                                                    indexData[app.loanId + "_" + app.advisorId]['approvedBy'] !== user.user.id && (
                                                        <div className='col-12 mb-3'>
                                                            <button type="button" className='lm-button1' onClick={() => handleRatingApprovals(app.loanId, app.advisorId)}>
                                                                Approve
                                                            </button>
                                                        </div>
                                                    )
                                                )}

                                            </div>
                                        </>
                                    )
                                }
                            </>

                        )
                    }
                    {
                        !isDisabled && (< div className="lm-divider mt-2"></div>)
                    }
                </div>
            </div >
        ))
    )
}

export default ShowRatingQuestions;
/* eslint-disable react-hooks/exhaustive-deps */
import { SidePanel, ProfileHeader } from "@components";
import Select from 'react-select';
import Preloader from '../../../plugins/Preloader';
import React, { useState, useEffect } from 'react';
import '../../styles/Users.css';
import back_arrow from '../../../assets/icons/back_arrow.svg';
import brdcrm_nav from '../../../assets/icons/brdcrm_nav.svg';
import axiosInstance from '../../../helpers/axiosConfig';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ChangePasswordModal from "../../user/adminAndAdvisor/ChangePasswordModal";
import SelectAdminModal from "../../user/adminAndAdvisor/SelectAdminModal";
import { useDispatch } from "react-redux";
import reducersAndActions from "../../../_redux/slices";
import Constants from "../../../helpers/Constants";
import convertToIST from "../commonFunctions/User";
import { contactNumberValidate, emailValidator, validStringValidator } from "../../../helpers/DynamicFunctions";
import { FieldControl, FieldGroup, FormBuilder, Validators } from "react-reactive-form";
import FormField from "../../../components/FormField/FormField";
import ContactNumberField from "../../../components/ContactField/ContactField";

function UserPartnerform() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userdetails = location.state?.data

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
  }, [loading]);

  const [changePassPopupShow, setChangePassPopup] = useState(false);
  const [adminPopup, setAdminPopup] = useState(false);


  // Go back to previous page
  const goBack = () => {
    window.history.back();
  };

  // Dropdown options for role and status
  const DropdownOptionsForRole = [
    { value: 'Advisor', label: 'Advisor' },
    { value: 'Admin', label: 'Admin' }
  ];
  const DropdownOptionsForStatus = [
    { value: 0, label: 'Inactive' },
    { value: 1, label: 'Active' }
  ];

  const [formData, setFromData] = useState(
    FormBuilder.group({
      id: [0],
      firstName: ["", [Validators.required, validStringValidator]],
      lastName: ["", [Validators.required, validStringValidator]],
      company: [""],
      institution: ["", [Validators.required, validStringValidator]],
      email: ["", [Validators.required, emailValidator]],
      officeNumber: ["", [Validators.required, contactNumberValidate]],
      mobileNumber: ["", [Validators.required, contactNumberValidate]],
      program: [""],
      role: [""],
      status: [""],
      code: [""],
      allowESignature: [false],
      CreditReport: [false],
      thirdPartyReports: [false],
      lockAccount: [false],
      allowPasswordReset: [true],
      RoleId: [1]
    })
  )

  // Set document title
  useEffect(() => {
    document.title = "User Detail";
    setFromData(formData);
    formData.reset(); // Reset form on mount
    getUseDetailsById();
  }, []);

  // Get user details by id
  const getUseDetailsById = async () => {
    if (userdetails?.id) {
      const apiUrl = '/api/User/GetUserDetailsById';
      const queryParams = `?userId=${userdetails.id}&SessionId=${localStorage.getItem('sessionId')}`;

      axiosInstance.get(`${apiUrl}${queryParams}`).then((res) => {
        const resultObject = JSON.parse(res.data.resultObject)[0];
        formData.patchValue({
          id: resultObject.ID,
          firstName: resultObject.FirstName,
          lastName: resultObject.LastName,
          company: resultObject.CompanyEntity,
          institution: resultObject.CompanyEntity,
          email: resultObject.Email,
          officeNumber: resultObject.OfficeNumber,
          mobileNumber: resultObject.MobileNumber,
          role: resultObject.ROLENAME === "Admin" ? DropdownOptionsForRole[1] : DropdownOptionsForRole[0],
          status: userdetails?.statusDesc === "Active" ? DropdownOptionsForStatus[1] : DropdownOptionsForStatus[0],
          allowESignature: resultObject.AdvisorDocuSign,
          CreditReport: resultObject.CreditReport,
          thirdPartyReports: resultObject.ThirdPartyReports,
          lockAccount: resultObject.IsUserLocked,
          allowPasswordReset: resultObject.IsAllowChange,
          RoleId: parseInt(resultObject.RoleId, 10),
        });
        setLoading(false)
      }).catch((error) => {
        setLoading(false)
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error?.response.data.message, type: 'error' }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        console.error('Error submitting form:', error.response ? error.response.data : error.message);
      })
    } else {
      setLoading(false)
      formData.patchValue({ allowPasswordReset: "True", status: DropdownOptionsForStatus[1], CreditReport: "False", thirdPartyReports: "False", allowESignature: "False", lockAccount: "False", RoleId: 1 });
    }
  }

  // Handle select field change
  const handleSelectChange = (selectedOption, actionMeta) => {
    formData.patchValue({ [actionMeta.name]: selectedOption });
  };


  // Handle form submission
  const handleSubmit = async () => {
    try {
      if (formData.status === "VALID") {
        setLoading(true)
        const formDataToSend = new FormData();

        // Append form data 
        formDataToSend.append("Id", formData.value.id ? formData.value.id : 0);
        formDataToSend.append("FirstName", formData.value.firstName);
        formDataToSend.append("LastName", formData.value.lastName);
        formDataToSend.append("EmailAddress", formData.value.email);
        formDataToSend.append("CompanyOrEntity", formData.value.company);
        formDataToSend.append("Telephone", formData.value.officeNumber);
        formDataToSend.append("Mobile", formData.value.mobileNumber);
        formDataToSend.append("RoleId", formData.value.RoleId);
        formDataToSend.append("IsPasswordResetAllowed", formData.value.allowPasswordReset);
        formDataToSend.append("CreditReports", formData.value.CreditReport);
        formDataToSend.append("ThirdPartyReports", formData.value.thirdPartyReports);
        formDataToSend.append("AdvisorDocuSign", formData.value.allowESignature);
        formDataToSend.append("IsUserLocked", formData.value.lockAccount);
        formDataToSend.append("IsPasswordResetAllowed", formData.value.allowPasswordReset);
        formDataToSend.append("Status", formData.value.status.value);
        formDataToSend.append("PartnerInstitution", formData.value.institution);
        formDataToSend.append("program", formData.value.program);
        formDataToSend.append("code", formData.value.code);

        const response = await axiosInstance.post("/api/User/InsertUpdatePartnerInfo", formDataToSend, {
          params: {
            WebURL: "http://localhost:3000/",
            SessionId: localStorage.getItem('sessionId')
          }
        });
        if (response.data.status === 200) {
          setLoading(false)
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "success" }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          setAdminPopup(false);
          navigate('/users/partners')
        } else {
          setLoading(false)
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: 'error' }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }

      } else {
        markAllAsTouched(formData);
      }
    } catch (error) {
      setLoading(false)
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error?.response.data.message, type: 'error' }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
    }
  };


  // Marking all input fields as touched if the form is not valid
  const markAllAsTouched = (formGroup) => {
    Object.keys(formGroup.controls).forEach((controlName) => {
      const control = formGroup.get(controlName);
      if (control && control.status === "INVALID") {
        control.active = true;
        control.touched = true;
        if (document.getElementById(controlName)) {
          document.getElementById(controlName).focus();
        }
      }
    });
  };

  return (
    <>
      {/* SidePanel */}
      <SidePanel />

      {/* ProfileHeader */}
      <ProfileHeader />

      {
        loading ? <Preloader /> : (
          <>
            <div className="dashboard-wrap users-screen">
              {/* User breadcrumb */}
              <div className="lm-breadcrumb d-flex">
                <Link onClick={goBack} className="back-btn mr-2" >
                  <img src={back_arrow} alt="" />
                </Link>
                <span onClick={goBack} className="text-light mr-1 cursor-pointer">
                  Users
                </span>
                <img src={brdcrm_nav} alt="" />
                <span className="text-dark ml-1">
                  {userdetails?.firstName} {userdetails?.lastName}
                </span>
              </div>
              {/* End User breadcrumb */}


              {
                userdetails && (
                  <div className="last-login-box">
                    <span>Last Login: {convertToIST(userdetails?.lastLoginDateTime)}</span>
                  </div>
                )
              }


              <FieldGroup
                control={formData}
                render={({ get, invalid }) => (
                  <>
                    <div className="row mt-4 mb-5 pb-2">
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          <FormField
                            name="firstName"
                            label="First Name"
                            id="firstName"
                            placeholder="Enter First Name"
                            onlyCharacters={true}
                            required={true}
                            errorMessage="First Name is required"
                            maxlength={50}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          <FormField
                            name="lastName"
                            label="Last Name"
                            id="lastName"
                            placeholder="Enter Last Name"
                            onlyCharacters={true}
                            required={true}
                            errorMessage="Last Name is required"
                            maxlength={50}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          <FormField
                            name="institution"
                            label="Institution"
                            id="institution"
                            placeholder="Enter Institution"
                            required={true}
                            errorMessage="Institution is required"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          <FormField
                            name="email"
                            label="Email"
                            id="email"
                            placeholder="Enter Email"
                            required={true}
                            disabled={userdetails ? true : false}
                            errorMessage="Email is required"
                            patternRequired={true}
                            patternMessage="Please enter a valid email"
                            maxlength={50}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          <ContactNumberField
                            id="officeNumber"
                            name="officeNumber"
                            required={true}
                            label="Office Number"
                            placeholder="Enter Office Number"
                            errorMessage="Office Number is required"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          <ContactNumberField
                            id="mobileNumber"
                            name="mobileNumber"
                            required={true}
                            label="Mobile Number"
                            placeholder="Enter Mobile Number"
                            errorMessage="Mobile Number is required"
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-lg-3">
                        <div className="form-group">
                          <label htmlFor="status">Status</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            options={DropdownOptionsForStatus}
                            value={formData.value.status}
                            onChange={handleSelectChange}
                            name="status"
                          />
                        </div>
                      </div>

                      {/* Checkboxes for various options */}
                      <div className="col-12">
                        <div className="form-group mb-2 form-check">
                          <FieldControl
                            name="allowESignature"
                            render={({ handler }) => (
                              <>
                                <input type="checkbox" className="form-check-input" id="allowESignature" checked={handler().value === "True"}
                                  onChange={(e) => {
                                    handler().onChange(e.target.checked ? "True" : "False")
                                    formData.patchValue({ allowESignature: e.target.checked ? "True" : "False" })
                                  }}
                                />
                                <label className="form-check-label" htmlFor="allowESignature">Allow E-Signature</label>
                              </>
                            )}
                          />
                        </div>
                        <div className="form-group mb-2 form-check">
                          <FieldControl
                            name="CreditReport"
                            render={({ handler }) => (
                              <>
                                <input type="checkbox" className="form-check-input" id="CreditReport" checked={handler().value === "True"}
                                  onChange={(e) => {
                                    handler().onChange(e.target.checked ? "True" : "False")
                                    formData.patchValue({ CreditReport: e.target.checked ? "True" : "False" })
                                  }}
                                />
                                <label className="form-check-label" htmlFor="CreditReport">Credit Reports</label>
                              </>
                            )}
                          />
                        </div>
                        <div className="form-group mb-2 form-check">
                          <FieldControl
                            name="thirdPartyReports"
                            render={({ handler }) => (
                              <>
                                <input type="checkbox" className="form-check-input" id="thirdPartyReports" checked={handler().value === "True"}
                                  onChange={(e) => {
                                    handler().onChange(e.target.checked ? "True" : "False")
                                    formData.patchValue({ thirdPartyReports: e.target.checked ? "True" : "False" })
                                  }}
                                />
                                <label className="form-check-label" htmlFor="thirdPartyReports">Third Party Reports</label>
                              </>
                            )}
                          />
                        </div>
                        <div className="form-group mb-2 form-check">
                          <FieldControl
                            name="lockAccount"
                            render={({ handler }) => (
                              <>
                                <input type="checkbox" className="form-check-input" id="lockAccount" checked={handler().value === "True"}
                                  onChange={(e) => {
                                    handler().onChange(e.target.checked ? "True" : "False")
                                    formData.patchValue({ lockAccount: e.target.checked ? "True" : "False" })
                                  }}
                                />
                                <label className="form-check-label" htmlFor="lockAccount">Lock Account</label>
                              </>
                            )}
                          />
                        </div>
                        <div className="form-group mb-2 form-check">
                          <FieldControl
                            name="allowPasswordReset"
                            render={({ handler }) => (
                              <>
                                <input type="checkbox" className="form-check-input" id="allowPasswordReset" checked={handler().value === "True"}
                                  onChange={(e) => {
                                    handler().onChange(e.target.checked ? "True" : "False")
                                    formData.patchValue({ allowPasswordReset: e.target.checked ? "True" : "False" })
                                  }}
                                />
                                <label className="form-check-label" htmlFor="allowPasswordReset">Allow Password Reset</label>
                              </>
                            )}
                          />
                        </div>
                      </div>
                      {/* End Checkboxes */}

                      {
                        userdetails && (
                          <div className="col-12 mt-4">
                            <button onClick={() => setChangePassPopup(true)} type="button" className="lm-button2">
                              Change Password
                            </button>
                          </div>
                        )
                      }
                    </div>

                    {/* Sticky buttons at the bottom */}
                    <div className="sticky-btn-footer d-flex justify-content-end">
                      <button onClick={goBack} type="button" className="lm-button2">Back</button>
                      <button type="button" className="lm-button1 ml-3" onClick={handleSubmit}>Save</button>
                    </div>
                    {/* End Sticky buttons */}
                  </>
                )}
              />


              {/* Change Password Popup / Modal */}
              <ChangePasswordModal
                userdetails={userdetails}
                changePassPopupShow={changePassPopupShow}
                setChangePassPopup={setChangePassPopup}
              />

              {/* End Change Password Popup / Modal */}
              <SelectAdminModal
                adminPopup={adminPopup}
                setAdminPopup={setAdminPopup}
              />
            </div>
          </>
        )
      }
    </>


  );
}

export default UserPartnerform;

import React from 'react';
import LM_loader_2 from '../../src/assets/LM_loader_2.gif';

function Preloader() {
  return (
    <div className="preloader">
      {/* Loader Image */}
      <div className='loader-box'>
        <img className='loader-obj' src={LM_loader_2} alt=""/>
        <h4>Loading... Just a moment!</h4>
      </div>
    </div>
  );
};

export default Preloader;

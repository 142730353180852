/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { SidePanel, ProfileHeader } from "@components";
import axiosInstance from '../helpers/axiosConfig';
import {
    Affiliates,
    LoanAmount,
    BusinessProfile,
    BusinessHistory,
    PersonalProfile,
    PFS,
    CashFlow,
    IndustryHotel,
    IndustryContractor,
    IndustryGasStation,
    IndustryMedical,
    IndustryRestaurant,
    Documents,
    Notes,
    Verification,
    CreditMemo,
    // SBA
} from "@loan-app-tabs";
import "./styles/ApplicationDetail.css";
import Preloader from "../plugins/Preloader";
import back_arrow from "../assets/icons/back_arrow.svg";
import brdcrm_nav from "../assets/icons/brdcrm_nav.svg";
import verify_icon_blue from "../assets/icons/verify-icon-blue.svg";
import application_icon_blue from "../assets/icons/application-icon-blue.svg";
import close_popup from "../assets/icons/close_popup.svg";
import scroll_left_icon from "../assets/icons/scroll-left-icon.svg";
import scroll_right_icon from "../assets/icons/scroll-right-icon.svg";
import { FieldGroup, FormBuilder, Validators, FieldControl } from "react-reactive-form";
import FormField from "../components/FormField/FormField";
import { getSessionId } from "../services/storageData";
import Constants from "../helpers/Constants";
import reducersAndActions from "../_redux/slices/index";
import { Modal } from "react-bootstrap";
import moment from 'moment';
import ContactNumberField from "../components/ContactField/ContactField";
import { InsertOrUpdateNewLoanExistingUser } from "../services/loanApplicationServices";
import { GetBasicInfoLoanApplicationById, InsertUpdateBasicInfo, UpdateBorrowerEmailAddress } from "../services/loanApplicationServices";
import { staticBusinessCategoriesList, staticLoanReasonList, staticStatesList, staticTypeOfBusinessList } from "../helpers/staticContentData";
import { decryptText, businessNameValidator, borrowAmountValidator, emailValidator, formatNumberWithCommas, contactNumberValidate, validStringValidator } from "../helpers/DynamicFunctions";

let NewApplicationFormGroup = FormBuilder.group({
    Borrowamount: ["", [Validators.required, borrowAmountValidator, Validators.pattern("^[0-9,.]*$")]],
    Loandate: ["", Validators.required],
    purpose: ["", Validators.required],
    TypeOfbusiness: ["", Validators.required],
    Businessname: ["", [Validators.required, businessNameValidator]],
    BState: ["", Validators.required],
    BCity: ["", Validators.required],
    UserId: [""],
    Category: [""]
});

let BasicInfoForm = FormBuilder.group({
    BorrowAmount: ["", [Validators.required, borrowAmountValidator]],
    Purpose: ["", [Validators.required]],
    BusinessName: ["", [Validators.required, validStringValidator]],
    BorrowerFirstName: ["", [Validators.required, validStringValidator]],
    BorrowerLastName: ["", [Validators.required, validStringValidator]],
    BorrowerContactNumber: ["", [Validators.required, contactNumberValidate]],
    BorrowerName: [""],
    DBA: [""],
    TypeOfBusiness: ["", [Validators.required]],
    BusinessCategory: [""],
    TaxID: ["", [Validators.required]],
    BusinessContactNo: ["", [Validators.required, contactNumberValidate]],
    NAICSCode: [0],
    BusinessEmail: ["", emailValidator],
    ApplicationNumber: [""],
    ApplicationStatus: [""],
    VerfiedStatus: [""]
});


let loanId;
let stateLists = [];

let TypeOfBusnessOptionsTemp = [];
let LoanReasonOptionsTemp = [];
let BusinessCategoriesOptionsTemp = [];

function ApplicationDetail() {
    const params = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const user = useSelector((state) => state.auth);
    const userdata = user.user;
    const userRole = userdata?.roleName || '';

    const [TypeOfBusnessOptions, setTypeOfBusnessOptions] = useState([]);
    const [BusinessCategoriesOptions, setBusinessCategoriesOptions] = useState([]);
    const [newApplicationData, setNewApplicationData] = useState(null);
    const [isHiddenBusinessCategary, setIsHiddenBusinessCategary] = useState({});
    const [isHiddenBusinessCategaryCaparision, setIsHiddenBusinessCategaryCaparision] = useState([]);
    const [formData, setFormData] = useState({ BusinessCategory: null })

    const handleSelectChange = (name, selectedOption) => {
        if (formData.BusinessCategory === null) {
            setFormData({ ...formData, [name]: selectedOption });
        }
        if (formData.BusinessCategory !== null && isHiddenBusinessCategary.value !== selectedOption.value) {
            setNewTabPopupShow(true)
        }
        setIsHiddenBusinessCategary({ value: selectedOption.value })
        if (isHiddenBusinessCategary.value === formData.BusinessCategory?.value) {
            setFormData({ ...formData, [name]: selectedOption });
        }

    };

    const objectiveRef = createRef();
    const businessRef = createRef();
    const categoryRef = createRef();

    // Preloader Start
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);

    const [LoanReasonOptions, setLoanReasonOptions] = useState([]);


    // sticky header in application page js
    const [isHidden, setIsHidden] = useState(false);
    const [newTabPopupShow, setNewTabPopupShow] = useState(false);


    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > 0) {
            setIsHidden(true); // Hide the header when not at the top
        } else {
            setIsHidden(false); // Show the header when at the top
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Set initial active tab
    const [activeTab, setActiveTab] = useState("affiliates");

    // Parse the query parameters to get the tab
    useEffect(() => {
        const { tabName } = location.state || {};
        if (tabName && tabComponents[tabName]) {
            setActiveTab(tabName);
            navigate(location.pathname, { state: null, replace: true }); // Clear the state
        }
    }, [location.search, navigate]);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const showIndustryHotel = isHiddenBusinessCategary.value === "Hotel" ? true : false;
    const showIndustryContractor = isHiddenBusinessCategary.value === "Contractor" ? true : false;;
    const showIndustryGasStation = isHiddenBusinessCategary.value === "Gas Station" ? true : false;;
    const showIndustryMedical = isHiddenBusinessCategary.value === "Medical" ? true : false;
    const showIndustryRestaurant = isHiddenBusinessCategary.value === "Restaurant" ? true : false;;

    let tabComponents = {
        affiliates: <Affiliates />,
        loan_amount: <LoanAmount backPreviousTab={() => handleTabClick("affiliates")} />,
        business_profile: <BusinessProfile backPreviousTab={() => handleTabClick("loan_amount")} />,
        business_history: <BusinessHistory backPreviousTab={() => handleTabClick("business_profile")} />,
        personal_profile: <PersonalProfile />,
        PFS: <PFS loanid={decryptText(params.loanId)} applicationNumber={BasicInfoForm.value.ApplicationNumber} />,
        cash_flow: (
            <CashFlow
                backPreviousTab={() => handleTabClick("PFS")}
                loanid={decryptText(params.loanId)}
                applicationNumber={BasicInfoForm.value.ApplicationNumber}
                applicationName={BasicInfoForm.value.BusinessName}
            />
        ),
        ...(showIndustryHotel && {
            industry_hotel: <IndustryHotel backPreviousTab={() => handleTabClick("industry_hotel")} />
        }),
        ...(showIndustryContractor && {
            industry_Contractor: <IndustryContractor backPreviousTab={() => handleTabClick("industry_Contractor")} />
        }),
        ...(showIndustryGasStation && {
            Industry_Gas_Station: <IndustryGasStation backPreviousTab={() => handleTabClick("Industry_Gas_Station")} />

        }),
        ...(showIndustryMedical && {
            Industry_Medical: <IndustryMedical backPreviousTab={() => handleTabClick("Industry_Medical")} />

        }),
        ...(showIndustryRestaurant && {
            Industry_Restaurant: <IndustryRestaurant backPreviousTab={() => handleTabClick("Industry_Restaurant")} />
        }),
        creditMemo: (
            <CreditMemo
                backPreviousTab={() => handleTabClick("creditMemo")}
                loanid={decryptText(params.loanId)}
            />
        ),
        // SBA: <SBA />,
        documents: (
            <Documents
                loanid={decryptText(params.loanId)}
                applicationNumber={BasicInfoForm.value.ApplicationNumber}
            />
        ),
        notes: <Notes />,
    };

    if (userRole !== 'Borrower') {
        tabComponents = { ...tabComponents, verification: <Verification /> };
    }


    const tabTitles = {
        affiliates: "Affiliates",
        loan_amount: "Loan Amount",
        business_profile: "Business Profile",
        business_history: "Business History",
        personal_profile: "Personal Profile",
        PFS: "PFS",
        cash_flow: "Cash Flow",
        industry_hotel: "Hotel",
        industry_Contractor: "Contractor",
        Industry_Gas_Station: "Gas Station",
        Industry_Medical: "Medical",
        Industry_Restaurant: "Restaurant",
        creditMemo: "Credit Memo",
        SBA: "SBA",
        documents: "Documents",
        notes: "Notes",
        verification: "Verification",
    };

    // Vertical tabs scroll button
    const laTabRef = useRef(null);
    const [naics, setNaics] = useState("")
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(true);
    const [contactEmail, setContactEmail] = useState("")
    const [contactTaxId, setContactTaxId] = useState("")

    const scrollLeft = () => {
        if (laTabRef.current) {
            laTabRef.current.scrollBy({ left: -120, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (laTabRef.current) {
            laTabRef.current.scrollBy({ left: 120, behavior: "smooth" });
        }
    };

    const updateButtonsVisibility = () => {
        if (laTabRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = laTabRef.current;
            setShowLeftButton(scrollLeft > 0);
            setShowRightButton(scrollLeft + clientWidth < scrollWidth);
        }
    };

    useEffect(() => {
        const currentRef = laTabRef.current;

        const handleResize = () => {
            updateButtonsVisibility();
        };

        const handleScroll = () => {
            updateButtonsVisibility();
        };

        if (currentRef) {
            updateButtonsVisibility(); // Update button visibility on mount
            currentRef.addEventListener("scroll", handleScroll);
            window.addEventListener("resize", handleResize);

            return () => {
                currentRef.removeEventListener("scroll", handleScroll);
                window.removeEventListener("resize", handleResize);
            };
        }
    }, [tabComponents, tabTitles]); // Include all relevant dependencies

    // Accordion state
    const [accordionOpen, setAccordionOpen] = useState(false);

    const toggleAccordion = () => {
        setAccordionOpen(!accordionOpen);
    };

    const customHandleScroll = () => {
        if (accordionOpen) {
            setAccordionOpen(false);
        }
        handleScroll();
    };

    useEffect(() => {
        let oid = decryptText(params.loanId);
        loanId = oid;
        getTypesOfBusiness()
        getLoanReasonList()
        getBusinessCategory()
        getStates()
        GetBasicInfoLoanApplication();
    }, []);


    const getStates = async () => {
        const states = staticStatesList;
        stateLists = states;
    }

    const getTypesOfBusiness = async () => {
        TypeOfBusnessOptionsTemp = staticTypeOfBusinessList
        setTypeOfBusnessOptions(staticTypeOfBusinessList);
    }

    const getBusinessCategory = async () => {
        BusinessCategoriesOptionsTemp = staticBusinessCategoriesList
        setBusinessCategoriesOptions(staticBusinessCategoriesList);
    }

    const getLoanReasonList = async () => {
        LoanReasonOptionsTemp = staticLoanReasonList
        setLoanReasonOptions(staticLoanReasonList);
    }

    const [detailLoan, setDetailLoan] = useState()
    const [firstEmail, setFirstEmail] = useState()

    const GetBasicInfoLoanApplication = async () => {
        try {
            BasicInfoForm.reset();
            if (loanId !== "" && parseInt(loanId) > 0) {

                let queryObj = { Id: loanId, SessionId: getSessionId() };
                const modelBasicInfoDetails = await GetBasicInfoLoanApplicationById(queryObj);

                if (modelBasicInfoDetails.status === 200) {
                    let finalData = modelBasicInfoDetails.resultObject ? JSON.parse(modelBasicInfoDetails.resultObject) : "";

                    setDetailLoan(finalData)
                    if (finalData && Array.isArray(finalData) && finalData.length > 0) {

                        const bussCategory = finalData[0]['BusinessCategory'] === undefined ? "" : finalData[0]['BusinessCategory']

                        const getBusinessCategoryOption = BusinessCategoriesOptionsTemp.find(elem => elem.value === bussCategory);
                        const TypeOfBusinessOption = TypeOfBusnessOptionsTemp.find(elem => elem.value === finalData[0].TypeOfBusiness);
                        const getPurposeOption = LoanReasonOptionsTemp.find(elem => elem.value === finalData[0].Purpose);

                        setFormData({ BusinessCategory: getBusinessCategoryOption ? getBusinessCategoryOption : null });
                        setIsHiddenBusinessCategaryCaparision({ value: finalData[0].BusinessCategory })
                        setIsHiddenBusinessCategary({ value: finalData[0].BusinessCategory })

                        BasicInfoForm.patchValue({
                            BusinessName: finalData[0].BusinessName,
                            DBA: finalData[0].DBA,
                            BusinessCategory: finalData[0].BusinessCategory,
                            TaxID: finalData[0].TaxID.replace(/^(\d{2})(\d+)$/, '$1-$2'),
                            BusinessContactNo: finalData[0].BusinessContactNo,
                            NAICSCode: finalData[0].NAICSCode,
                            BorrowAmount: finalData[0].BorrowAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                            BusinessEmail: finalData[0].BusinessEmail,
                            BorrowerFirstName: finalData[0].BorrowerFirstName,
                            BorrowerLastName: finalData[0].BorrowerLastName,
                            ApplicationNumber: finalData[0].ApplicationNumber,
                            ApplicationStatus: finalData[0].ApplicationStatus,
                            BorrowerContactNumber: finalData[0].BorrowerContactNumber,
                            BorrowerName: finalData[0].BorrowerFirstName,
                            VerfiedStatus: finalData[0].VerfiedStatus,
                            UserId: finalData[0].UserId,
                            Purpose: getPurposeOption,
                            TypeOfBusiness: TypeOfBusinessOption
                        });

                        setLoading(false);
                    }
                }
            }
        } catch (err) {
            console.log(err);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    };

    const [sentEmailPopupShow, setSentEmailPopup] = useState(false);

    const ChangePreviusTab = () => {

        setFormData({ BusinessCategory: null })
        const previousData = BusinessCategoriesOptions.find(data => data.value === isHiddenBusinessCategaryCaparision.value);
        setNewTabPopupShow(false);

        setFormData(prevState => ({
            ...prevState,
            BusinessCategory: previousData // Store the entire previousData object
        }));

        setIsHiddenBusinessCategary({ value: isHiddenBusinessCategaryCaparision.value });
    };

    const ChangeNewTab = () => {
        setNewTabPopupShow(false)
    }
    const sentEmailPopupConfirm = () => {
        Emailsender()
        setSentEmailPopup(false)
    }
    const sentEmailPopupClose = () => {
        handleSubmit("AddNewmailPreviusMail")
        setSentEmailPopup(false);
    }
    const Emailsender = async () => {
        handleSubmit("AddNewmail")
        let queryObj = {
            LoanId: loanId,
            BorrowerEmail: firstEmail,
            SessionId: getSessionId()
        };

        UpdateBorrowerEmailAddress(queryObj)
            .then((data) => {
                setLoading(false);
                if (data.status === 200) {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "success", }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                }
                else {
                    setLoading(false);
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "error", }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            });

    }

    const handleSubmit = (e) => {
        if (e !== "AddNewmail" && e !== "AddNewmailPreviusMail") {
            e?.preventDefault();
        }
        let validate = BasicInfoForm.value

        if (BasicInfoForm.status !== "INVALID" && validate.BorrowAmount !== "" && validate.BorrowerLastName !== ""
            && validate.BorrowerFirstName !== ""
            && validate.BorrowerContactNumber !== ""
            && contactTaxId === "" && contactEmail === ""

        ) {
            if (e !== "AddNewmail" && e !== "AddNewmailPreviusMail") {
                if (validate.BusinessEmail !== detailLoan[0].BusinessEmail) {
                    return setSentEmailPopup(true)
                }
            }
            setLoading(true);

            let finalObj = {
                ...BasicInfoForm.value,
                IsFlagSetForBusinessCategoryChange_BasicInfo: false,
                UserId: 1,
                Id: loanId,
                SessionId: getSessionId(),
                modifiedBy: JSON.parse(localStorage.getItem("user"))?.id || 0
            };
            if (e === "AddNewmailPreviusMail") {
                finalObj = {
                    ...finalObj,
                    BusinessEmail: detailLoan[0].BusinessEmail,
                    TypeOfBusiness: BasicInfoForm.value.TypeOfBusiness.value,
                    BusinessCategory: formData.BusinessCategory === null ? "" : formData.BusinessCategory?.value,
                    Purpose: BasicInfoForm.value.Purpose.value,

                };
            } else {
                finalObj = {
                    ...finalObj,
                    TypeOfBusiness: BasicInfoForm.value.TypeOfBusiness.value,
                    BusinessCategory: formData.BusinessCategory === null ? "" : formData.BusinessCategory?.value,
                    Purpose: BasicInfoForm.value.Purpose.value,
                };

            }

            InsertUpdateBasicInfo(finalObj)
                .then((data) => {
                    setLoading(false);
                    if (data.status === 200) {
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Basic information has been updated successfully", type: "success", }));
                        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                        BasicInfoForm.patchValue({ BusinessEmail: finalObj.BusinessEmail })
                    }
                    else {
                        setLoading(false);
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "error", }));
                        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);

                        if (data.message === "Tax Id already exists") BasicInfoForm.patchValue({ TaxID: detailLoan[0].TaxID })
                        if (data.message === "Business name already exists") BasicInfoForm.patchValue({ BusinessName: detailLoan[0].BusinessName })
                    }
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                });
        } else {
            markAllAsTouched(BasicInfoForm);
        }
    };

    // Marking all input fields as touched if the form is not valid
    const markAllAsTouched = (formGroup) => {
        if (objectiveRef.current) objectiveRef.current.focus();
        if (businessRef.current) businessRef.current.focus();
        if (categoryRef.current) categoryRef.current.focus();

        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control) {
                control.active = true;
                control.touched = true;
                if (document.getElementById(controlName)) {
                    document.getElementById(controlName).focus();
                }
            }
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", customHandleScroll);
        return () => window.removeEventListener("scroll", customHandleScroll);
    }, [accordionOpen]);

    const accordionClass = accordionOpen
        ? "open-basic-form-accordion"
        : "closed-basic-form-accordion";
    const toggleButton = accordionOpen ? "show-btn" : "hide-btn";

    //customized  Popup function
    const [isModalOpen, setApplicationPopup] = useState(false);

    const applicationPopupShow = () => {
        setApplicationPopup(true);
        document.body.style.overflow = "hidden"; // Prevent scrolling
    };

    const applicationPopupClose = () => {
        setApplicationPopup(false);
        document.body.style.overflow = ""; // Allow scrolling
    };

    const StateHandlerData = () => {
        dispatch(reducersAndActions.actions.profileAction.stateUpdateReducer({ stateUpdate: "upadte", value: true }));

    }
    const ConfirmEmail = () => {
        axiosInstance.get(`/api/LoanApplication/ResendUserInvitation?LoanId=${detailLoan[0].Id}&SessionId=${getSessionId()}&WebURL=${Constants.FRONTEND_URL}`)
            .then((data) => {
                setLoading(false);
                if (data.status === 200) {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.data.message, type: "success", }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                }
                else {
                    setLoading(false);
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "error", }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            });
    }

    let classMapper = {
        "NEW": "new",
        "New": "new",
        "Documents Required": "document-required",
        "On Hold": "new",
        "Review Required": "review-required",
        "Closed": "closed",
        "Completed": "closed",
        "In Progress": "in-progress",
    };

    const handleNewApplication = async (event) => {
        event.preventDefault();
        if (NewApplicationFormGroup.status !== "INVALID") {
            setLoading(true);
            let finalObj = {
                UserId: newApplicationData,
                Borrowamount: NewApplicationFormGroup.value.Borrowamount.replace(/,/g, ""),
                Loandate: NewApplicationFormGroup.value.Loandate,
                purpose: NewApplicationFormGroup.value.purpose.value,
                TypeOfbusiness: NewApplicationFormGroup.value.TypeOfbusiness.value,
                Businessname: NewApplicationFormGroup.value.Businessname,
                BState: NewApplicationFormGroup.value.BState.value,
                BCity: NewApplicationFormGroup.value.BCity,
                Category: NewApplicationFormGroup.value.Category || "",
            };

            InsertOrUpdateNewLoanExistingUser(finalObj)
                .then((data) => {
                    if (data.status === 200) {
                        setLoading(false);
                        applicationPopupClose();
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "success", }));
                        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    } else {
                        setLoading(false);
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "error", }));
                        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                });
        } else {
            markAllAsTouched(NewApplicationFormGroup);
        }
    }


    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (
                <Preloader />
            ) : (
                <div>
                    <div className="dashboard-wrap application-detail">
                        {/* <!--Header Progress Bar --> */}
                        <div className="lm-progress-bar">
                            <div className="lm-progress"></div>
                        </div>
                        {/* <!--Header Progress Bar --> */}

                        {/* <!-- appliaction header sec start  --> */}
                        <div
                            className={`appliaction-header padding-reg pt-0 pb-0 ${activeTab === "creditMemo" ? "CreditMemoActive" : ""
                                }`}>
                            {/* <!-- appliaction breadcrumb   --> */}
                            <div className="lm-breadcrumb d-flex pt-4" onClick={StateHandlerData}>
                                <Link className="back-btn mr-2" to="/loan-application">
                                    <img src={back_arrow} alt="" />
                                </Link>
                                <Link
                                    className="text-light mr-1 cursor-pointer"
                                    to="/loan-application"
                                >
                                    Loan Applications
                                </Link>
                                <img src={brdcrm_nav} alt="" />
                                <span className="text-dark ml-1">
                                    {BasicInfoForm.value.ApplicationNumber}
                                </span>
                            </div>
                            {/* <!-- appliaction breadcrumb   --> */}

                            {/* <!-- basic information form start  --> */}
                            <div id="dynamic_sticky_form" className={`dynamic_sticky_form ${isHidden ? "hide-sticky-form" : ""}`}                            >
                                <div className="header-btn-sec">
                                    <div className="d-flex">
                                        <h1 className="appliaction-no mr-2">
                                            {BasicInfoForm.value.ApplicationNumber}
                                        </h1>
                                        <div className={`status ${classMapper[BasicInfoForm.value.ApplicationStatus]}`}>
                                            {BasicInfoForm.value.ApplicationStatus}
                                        </div>
                                    </div>
                                    <div className="d-flex header-btns">
                                        {
                                            BasicInfoForm.value.VerfiedStatus === "False" && (
                                                <button type="button" onClick={ConfirmEmail} className="lm-button2">
                                                    <img src={verify_icon_blue} alt="" /> Confirm e-mail address
                                                </button>
                                            )
                                        }

                                        {
                                            userRole !== 'Borrower' && (
                                                <button onClick={applicationPopupShow} type="button" className="lm-button2">
                                                    <img src={application_icon_blue} alt="" /> Add application
                                                </button>
                                            )
                                        }
                                        <button type="button" className={`lm-button1 ${toggleButton}`} onClick={handleSubmit}>Save</button>
                                    </div>
                                </div>
                                <div className="accordion">
                                    <div className={`cursor-pointer form-accordian-button ${accordionClass}`} onClick={toggleAccordion}>
                                        Show Basic Information
                                    </div>
                                    {
                                        accordionOpen && (
                                            <div className={`basic-info-form ${accordionOpen ? "open" : "closed"}`}>
                                                <FieldGroup
                                                    control={BasicInfoForm}
                                                    render={({ get, invalid }) => (
                                                        <form action="">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-4">
                                                                    <div className="form-group input-dollar">
                                                                        <FieldControl
                                                                            name="BorrowAmount"
                                                                            render={({ handler, touched, hasError, meta, }) => {
                                                                                const handlerProps = handler();
                                                                                const handleChange = (e) => {
                                                                                    const rawValue = e.target.value.replace(/,/g, ""); // Remove existing commas
                                                                                    const formattedValue = formatNumberWithCommas(rawValue);
                                                                                    e.target.value = formattedValue;
                                                                                    if (handlerProps.onChange) {
                                                                                        handlerProps.onChange(e);
                                                                                    }
                                                                                };

                                                                                return (
                                                                                    <div className="form-group input-dollar">
                                                                                        <label htmlFor="BorrowAmount">
                                                                                            How much would you like to borrow?
                                                                                            <span className="asterisk">*</span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            id="BorrowAmount"
                                                                                            placeholder="400,000.00"
                                                                                            {...handlerProps}
                                                                                            onChange={handleChange}
                                                                                            required={true}
                                                                                        />

                                                                                        {/* Error messages below */}
                                                                                        {touched && hasError("required") && (<><span className="error-message">Borrow amount is required</span><br /> </>)}
                                                                                        {hasError("borrowAmountIssue") && (<><span className="error-message">Amount should be greater than $25,000</span><br /></>)}
                                                                                        {hasError("pattern") && (<><span className="error-message"> Amount should be only numeric values</span><br /></>)}
                                                                                        {hasError("borrowAmountMaxIssue") && (<><span className="error-message">Amount should be less than orequal to 10000000000</span><br /></>)}
                                                                                    </div>
                                                                                );
                                                                            }}
                                                                            meta={{ placeholder: "400,000.00" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4">
                                                                    <div className="form-group">
                                                                        <FieldControl
                                                                            name="Purpose"
                                                                            render={({ handler, touched, hasError, meta }) => (
                                                                                <div className="form-group">
                                                                                    <label htmlFor="Purpose">
                                                                                        How will you use the loan?
                                                                                        <span className="asterisk">*</span>
                                                                                    </label>
                                                                                    <Select
                                                                                        className="basic-single"
                                                                                        classNamePrefix="select"
                                                                                        id="Purpose"
                                                                                        name="Purpose"
                                                                                        menuPortalTarget={document.body}
                                                                                        styles={{
                                                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                                        }}

                                                                                        ref={objectiveRef}
                                                                                        options={LoanReasonOptions}
                                                                                        required={true}
                                                                                        {...handler()}
                                                                                    />

                                                                                    {/* Error messages below */}
                                                                                    <span className="error-message">{touched && hasError("required") && `Objective is required`}                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor="BusinessCategory"> Business Category </label>
                                                                        <Select
                                                                            className="basic-single"
                                                                            classNamePrefix="select"
                                                                            id="BusinessCategory"
                                                                            name="BusinessCategory"
                                                                            menuPortalTarget={document.body}
                                                                            styles={{
                                                                                menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                            }}
                                                                            options={BusinessCategoriesOptions}
                                                                            defaultValue={formData.BusinessCategory}
                                                                            onChange={(selectedOption) => handleSelectChange("BusinessCategory", selectedOption)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4">
                                                                    <div className="form-group">
                                                                        <FieldControl
                                                                            name="TypeOfBusiness"
                                                                            render={({ handler, touched, hasError, meta }) => (
                                                                                <div className="form-group">
                                                                                    <label htmlFor="TypeOfBusiness">
                                                                                        Type of Business
                                                                                        <span className="asterisk">*</span>
                                                                                    </label>
                                                                                    <Select
                                                                                        className="basic-single"
                                                                                        classNamePrefix="select"
                                                                                        id="TypeOfBusiness"
                                                                                        name="TypeOfBusiness"
                                                                                        menuPortalTarget={document.body}
                                                                                        styles={{
                                                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                                        }}
                                                                                        ref={businessRef}
                                                                                        required={true}
                                                                                        options={TypeOfBusnessOptions}
                                                                                        {...handler()}
                                                                                    />
                                                                                    {/* Error messages below */}
                                                                                    <span className="error-message">{touched && hasError("required") && `Business type is required`}                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4">
                                                                    <div className="form-group">
                                                                        <FormField
                                                                            name="BusinessName"
                                                                            label="Business Name"
                                                                            placeholder="Enter Business Name"
                                                                            id="BusinessName"
                                                                            required={true}
                                                                            errorMessage={"Business Name is required"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4">
                                                                    <div className="row">
                                                                        <div className="col-6 pr-2">
                                                                            <div className="form-group">
                                                                                <FieldControl
                                                                                    name="NAICSCode"
                                                                                    render={({ handler, touched, hasError, meta }) => {
                                                                                        const handlerProps = handler();
                                                                                        const standardizeTaxID = (e) => {
                                                                                            let value = e.target.value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters

                                                                                            // Truncate value to a maximum of 14 characters
                                                                                            if (value.length > 9) {
                                                                                                value = value.slice(0, 9);
                                                                                            }

                                                                                            e.target.value = value;

                                                                                            if (handlerProps.onChange) {
                                                                                                handlerProps.onChange(e);
                                                                                            }

                                                                                            if (value.length === 0) {
                                                                                                setNaics("");
                                                                                            }
                                                                                        };

                                                                                        return (
                                                                                            <div className="form-group">
                                                                                                <label htmlFor="NAICSCode">
                                                                                                    NAICS Code
                                                                                                </label>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    name="NAICSCode"
                                                                                                    label="NAICS Code"
                                                                                                    placeholder="44110"
                                                                                                    id="NAICSCode"
                                                                                                    {...handlerProps}
                                                                                                    onChange={standardizeTaxID}
                                                                                                />

                                                                                                {/* Error messages below */}
                                                                                                <span className="error-message">
                                                                                                    {touched &&
                                                                                                        hasError("required") &&
                                                                                                        `NAICSCode is required`}
                                                                                                </span>
                                                                                                {contactTaxId && (<><span className="error-message">{naics}</span>                                                                                                        <br />                                                                                                    </>)}
                                                                                            </div>
                                                                                        );
                                                                                    }}
                                                                                    meta={{ placeholder: "47-113478" }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 pl-2">
                                                                            <div className="form-group">
                                                                                <FormField
                                                                                    name="DBA"
                                                                                    label="D/B/A"
                                                                                    placeholder="Enter d/b/a"
                                                                                    id="DBA"
                                                                                    required={false}
                                                                                    errorMessage={""}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4">
                                                                    <div className="form-group">
                                                                        <FormField
                                                                            name="BorrowerFirstName"
                                                                            label="Borrower First Name"
                                                                            placeholder="Borrower First Name"
                                                                            id="BorrowerFirstName"
                                                                            required={true}
                                                                            errorMessage="Borrower First name is required"
                                                                            onlyCharacters={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4">
                                                                    <div className="form-group">
                                                                        <FormField
                                                                            name="BorrowerLastName"
                                                                            label="Borrower Last Name"
                                                                            placeholder="Borrower Last Name"
                                                                            id="BorrowerLastName"
                                                                            required={true}
                                                                            errorMessage="Borrower Last name is required"
                                                                            onlyCharacters={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4">
                                                                    <div className="form-group">
                                                                        <ContactNumberField
                                                                            name="BorrowerContactNumber"
                                                                            label="Borrower Contact Number"
                                                                            id="BorrowerContactNumber"
                                                                            required={true}
                                                                            errorMessage="Contact number is required"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4">
                                                                    <div className="form-group">
                                                                        <FieldControl
                                                                            name="BusinessEmail"
                                                                            render={({ handler, touched, hasError, meta }) => {
                                                                                const handlerProps = handler();
                                                                                const validateEmail = (e) => {
                                                                                    const email = e.target.value;
                                                                                    setFirstEmail(email);

                                                                                    if (handlerProps.onChange) {
                                                                                        handlerProps.onChange(e);
                                                                                    }
                                                                                };

                                                                                return (
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="BusinessEmail">
                                                                                            Borrower Email Address
                                                                                            <span className="asterisk">*</span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="email"
                                                                                            className="form-control"
                                                                                            id="BusinessEmail"
                                                                                            placeholder="Enter Email address"
                                                                                            {...handlerProps}
                                                                                            onChange={validateEmail}
                                                                                        />

                                                                                        {/* Error messages below */}
                                                                                        {touched && hasError("required") && (
                                                                                            <>
                                                                                                <span className="error-message">
                                                                                                    Borrower Email Address is required
                                                                                                </span>
                                                                                                <br />
                                                                                            </>
                                                                                        )}
                                                                                        {contactEmail && (
                                                                                            <>
                                                                                                <span className="error-message">
                                                                                                    {contactEmail}
                                                                                                </span>
                                                                                                <br />
                                                                                            </>
                                                                                        )}
                                                                                        {touched && hasError("invalidEmail") && (
                                                                                            <>
                                                                                                <span className="error-message">
                                                                                                    Borrower email address is invalid.
                                                                                                </span>
                                                                                                <br />
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                );
                                                                            }}
                                                                            meta={{ placeholder: "example@example.com" }}
                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4">
                                                                    <div className="form-group">
                                                                        <ContactNumberField
                                                                            name="BusinessContactNo"
                                                                            label="Business Phone Number"
                                                                            id="BusinessContactNo"
                                                                            required={true}
                                                                            errorMessage="Phone number is required"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4">
                                                                    <div className="form-group">
                                                                        <FieldControl
                                                                            name="TaxID"
                                                                            render={({
                                                                                handler,
                                                                                touched,
                                                                                hasError,
                                                                                meta,
                                                                            }) => {
                                                                                const handlerProps = handler();
                                                                                const standardizeTaxID = (e) => {
                                                                                    let value = e.target.value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters

                                                                                    // Format value as XX-XXXXXXX
                                                                                    if (value.length > 2) {
                                                                                        value = value.slice(0, 2) + '-' + value.slice(2, 9); // Limit to 9 digits
                                                                                    }

                                                                                    // Update the input value
                                                                                    e.target.value = value;
                                                                                    if (handlerProps.onChange) {
                                                                                        handlerProps.onChange(e);
                                                                                    }

                                                                                    const taxIDPattern = /^\d{2}-\d{7}$/;
                                                                                    if (value.length === 0) {
                                                                                        setContactTaxId("");
                                                                                    } else if (!taxIDPattern.test(value)) {
                                                                                        setContactTaxId("Tax ID should be of 9 digits.");
                                                                                    } else {
                                                                                        setContactTaxId("");
                                                                                    }
                                                                                };

                                                                                return (
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="TaxID">
                                                                                            Tax ID
                                                                                            <span className="asterisk">*</span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            name="TaxID"
                                                                                            label="Business Tax ID"
                                                                                            placeholder="Enter Business Tax ID"
                                                                                            id="TaxID"
                                                                                            {...handlerProps}
                                                                                            onChange={standardizeTaxID}
                                                                                        />

                                                                                        {/* Error messages below */}
                                                                                        {touched && hasError("required") && (
                                                                                            <>
                                                                                                <span className="error-message">
                                                                                                    Business Tax ID is required
                                                                                                </span>
                                                                                                <br />
                                                                                            </>
                                                                                        )}
                                                                                        {contactTaxId && (
                                                                                            <>
                                                                                                <span className="error-message">
                                                                                                    {contactTaxId}
                                                                                                </span>
                                                                                                <br />
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                );
                                                                            }}
                                                                            meta={{ placeholder: "47-113478" }}
                                                                        />


                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4">
                                                                    <div className="form-group">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    )}
                                                />
                                            </div>
                                        )
                                    }
                                </div>

                            </div>
                            {/* <!-- basic information form end  --> */}

                            {/* <!-- loan application tabs start  --> */}
                            <div className="lm-nav-tabs application-tabs">
                                {showLeftButton && (
                                    <div id="slideLeft" onClick={scrollLeft}>
                                        <img src={scroll_left_icon} alt="" />
                                    </div>
                                )}
                                <ul
                                    className="nav nav-tabs"
                                    id="laTab"
                                    ref={laTabRef}
                                    style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                                >
                                    {Object.keys(tabComponents).map((tabId) => (
                                        <li className="nav-item" key={tabId}>
                                            <button
                                                className={`nav-link ${activeTab === tabId ? "active" : ""
                                                    }`}
                                                onClick={() => handleTabClick(tabId)}
                                            >
                                                {tabTitles[tabId]}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                                {showRightButton && (
                                    <div id="slideRight" onClick={scrollRight}>
                                        <img src={scroll_right_icon} alt="" />
                                    </div>
                                )}
                            </div>

                            {/* <!-- loan application tabs end  --> */}
                        </div>
                        {/* <!-- appliaction header sec end  --> */}

                        {/* <!-- loan application tab content start  --> */}
                        <div className="application-wrapper padding-reg">
                            <div className="tab-content" id="laTabContent">
                                {activeTab && (
                                    <div
                                        className={`tab-pane fade show active`}
                                        id={activeTab}
                                    >
                                        {tabComponents[activeTab] === undefined ? tabComponents["affiliates"] : tabComponents[activeTab]}
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* <!-- loan application tab content start  --> */}
                    </div>

                    {/*Add New Application Popup / Modal */}
                    {/* This is customized modal used for datepicker isuue purpose don't use this anywhere else */}

                    <div
                        className={`custom-modal cust-modal-lg modal-open modal-backdrop ${isModalOpen ? "cust-modal-show" : "cust-modal-hide"
                            }`}
                    >
                        <div className="modal-box">
                            <div className="modal-header">
                                <h5 className="modal-title" id="applicationPopupLabel">
                                    New application
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={applicationPopupClose}
                                >
                                    <span aria-hidden="true">
                                        <img src={close_popup} alt="" />
                                    </span>
                                </button>
                            </div>
                            <form>
                                <FieldGroup
                                    control={NewApplicationFormGroup}
                                    render={({ get, invalid }) => (
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group input-dollar">
                                                        <FieldControl
                                                            name="Borrowamount"
                                                            render={({
                                                                handler,
                                                                touched,
                                                                hasError,
                                                                meta,
                                                            }) => {
                                                                const handlerProps = handler();
                                                                const handleChange = (e) => {


                                                                    const rawValue = e.target.value.replace(
                                                                        /,/g,
                                                                        ""
                                                                    ); // Remove existing commas
                                                                    const formattedValue =
                                                                        formatNumberWithCommas(rawValue);
                                                                    e.target.value = formattedValue;
                                                                    if (handlerProps.onChange) {
                                                                        handlerProps.onChange(e);
                                                                    }
                                                                };

                                                                return (
                                                                    <div className="form-group input-dollar">
                                                                        <label htmlFor="Borrowamount">
                                                                            How much would you like to borrow?
                                                                            <span className="asterisk">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="Borrowamount"
                                                                            placeholder="400,000.00"
                                                                            {...handlerProps}
                                                                            onChange={handleChange}
                                                                            required={true}
                                                                        />

                                                                        {/* Error messages below */}
                                                                        {touched && hasError("required") && (
                                                                            <>
                                                                                <span className="error-message">
                                                                                    Borrow amount is required
                                                                                </span>
                                                                                <br />
                                                                            </>
                                                                        )}
                                                                        {
                                                                            hasError("borrowAmountIssue") && (
                                                                                <>
                                                                                    <span className="error-message">
                                                                                        Amount should be greater than
                                                                                        $25,000
                                                                                    </span>
                                                                                    <br />
                                                                                </>
                                                                            )}
                                                                        {hasError("pattern") && (
                                                                            <>
                                                                                <span className="error-message">
                                                                                    Amount should be only numeric
                                                                                    values
                                                                                </span>
                                                                                <br />
                                                                            </>
                                                                        )}
                                                                        {
                                                                            hasError(
                                                                                "borrowAmountMaxIssue"
                                                                            ) && (
                                                                                <>
                                                                                    <span className="error-message">
                                                                                        Amount should be less than or
                                                                                        equal to 10000000000
                                                                                    </span>
                                                                                    <br />
                                                                                </>
                                                                            )}
                                                                    </div>
                                                                );
                                                            }}
                                                            meta={{ placeholder: "400,000.00" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <FieldControl
                                                        name="Loandate"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group input-date">
                                                                <label htmlFor="date">Choose the date by which you want to get the loan<span className="asterisk">*</span></label>
                                                                <DatePicker
                                                                    showIcon
                                                                    toggleCalendarOnIconClick
                                                                    {...handler()}
                                                                    selected={NewApplicationFormGroup.value.Loandate ? new Date(NewApplicationFormGroup.value.Loandate) : null}
                                                                    isClearable={true}
                                                                    id='Loandate'
                                                                    placeholderText="Month DD, YYYY"
                                                                    yearDropdownItemNumber={100}
                                                                    scrollableYearDropdown
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    onChange={(date) => {
                                                                        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                        handler().onChange(formattedDate);
                                                                    }}
                                                                    onKeyDown={(e) => {
                                                                        e.preventDefault();
                                                                    }}
                                                                />
                                                                {/* Error messages below */}
                                                                {touched && hasError("required") && (
                                                                    <span className="error-message">Business state is required</span>
                                                                )}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <FieldControl
                                                        name="purpose"
                                                        render={({
                                                            handler,
                                                            touched,
                                                            hasError,
                                                            meta,
                                                        }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="purpose">
                                                                    How will you use the loan?
                                                                    <span className="asterisk">*</span>
                                                                </label>
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    id="purpose"
                                                                    name="purpose"
                                                                    menuPortalTarget={document.body}
                                                                    styles={{
                                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                    }}
                                                                    ref={objectiveRef}
                                                                    options={LoanReasonOptions}
                                                                    onChange={selectedOption => {
                                                                        handler().onChange(selectedOption?.value);
                                                                    }}
                                                                    {...handler()}

                                                                />

                                                                {/* Error messages below */}
                                                                {touched && hasError("required") && (
                                                                    <span className="error-message">Loan Reason is required</span>
                                                                )}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <FieldControl
                                                        name="TypeOfbusiness"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="TypeOfbusiness">
                                                                    Type of Business<span className="asterisk">*</span>
                                                                </label>
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    id="TypeOfbusiness"
                                                                    name="TypeOfbusiness"
                                                                    menuPortalTarget={document.body}
                                                                    styles={{
                                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                    }}
                                                                    ref={businessRef}
                                                                    options={TypeOfBusnessOptions}
                                                                    onChange={(selectedOption) => {
                                                                        handler().onChange(selectedOption?.value);
                                                                    }}
                                                                    {...handler()}
                                                                />
                                                                <span className="error-message">
                                                                    {touched && hasError("required") && `Business type is required`}
                                                                </span>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <FormField
                                                        name="Businessname"
                                                        label="Business Name"
                                                        id="Businessname"
                                                        placeholder="Enter Business Name"
                                                        required={true}
                                                        errorMessage="Business name is required"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <FormField
                                                        name="BCity"
                                                        label="Business City"
                                                        id="BCity"
                                                        placeholder="Enter a Business City"
                                                        required={true}
                                                        errorMessage="City is required"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <FieldControl
                                                        name="BState"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="business_state">Business state</label>
                                                                <span className="asterisk">*</span>
                                                                <Select
                                                                    id="BState"
                                                                    name="BState"
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    menuPortalTarget={document.body}
                                                                    styles={{
                                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                    }}
                                                                    options={stateLists}
                                                                    onChange={(selectedOption) => {
                                                                        handler().onChange(selectedOption?.value);
                                                                    }}
                                                                    {...handler()}
                                                                    required={true}
                                                                />
                                                                {touched && hasError("required") && (
                                                                    <span className="error-message">Business state is required</span>
                                                                )}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                />
                                <div className="modal-footer la-app-modal-footer">
                                    <button
                                        onClick={applicationPopupClose}
                                        type="button"
                                        className="lm-button2"
                                    >
                                        Cancel
                                    </button>
                                    <button type="button" onClick={handleNewApplication} className="lm-button1">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    {/*Add New Application Popup / Modal */}

                    {/* <!--sent an email Popup / Modal --> */}
                    <Modal
                        className='mark-comp-popup-cust-size'
                        show={sentEmailPopupShow}
                        onHide={() => setSentEmailPopup(false)}
                        aria-labelledby="sentEmailPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        Are you sure you want to update the email address?
                                        <br />
                                        <br />

                                        If “Yes, ” email address will be updated after you click and verify link sent to updated email address.
                                    </h3>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={sentEmailPopupClose}>No</button>
                                    <button type="button" className="lm-button1" onClick={sentEmailPopupConfirm}>Yes</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* <!--sent an email Popup / Modal --> */}
                    {/* <!--sent an setNewTabPopupShow Popup / Modal --> */}
                    <Modal
                        className='mark-comp-popup-cust-size'
                        show={newTabPopupShow}
                        onHide={() => setNewTabPopupShow(false)}
                        aria-labelledby="sentEmailPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        Changing business category may result in loss of documents uploaded under Advisor Generated Documents. Do you wish to continue?
                                    </h3>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={ChangePreviusTab}>No</button>
                                    <button type="button" className="lm-button1" onClick={ChangeNewTab}>Yes</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* <!--sent an setNewTabPopupShow Popup / Modal --> */}
                </div>
            )}
        </>
    );
}

export default ApplicationDetail;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { registerEmail } from '../services/commonService';
import reducersAndActions from '../_redux/slices/index';
import { useDispatch } from 'react-redux';
import Constants from '../helpers/Constants';

function RegisterLink() {
    
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const data = queryParams.get('q');
        registerEmail({q: data}).then(response => {
            if(response.status === 200) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({visible: true, message: response.message, type: "success"}));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                navigate('/');
            }
        }).catch(err => {
            console.log(err);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({visible: true, message: err.message, type: "error"}));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            navigate('/');
        });
    }, []);

    return (
        <div className='d-flex justify-content-center mt-2'>
            <p>Please wait while we authenticate your email...</p>
        </div>
    );
}

export default RegisterLink;
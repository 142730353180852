export const sideMenuMapper = {
    "Dashboard": {
        route: "",
        icon: "lm-dashboard-icon",
    },
    "Lending Criteria": {
        route: "/lending-criteria",
        icon: "lm-lending-criteria-icon"
    },
    "Loan Applications": {
        route: "/loan-application",
        icon: "lm-loan-app-icon"
    },
    "Users": {
        route: "",
        icon: "lm-users-icon"
    },
    "Advisor & Admin": {
        route: "/users/advisor-admin",
        icon: ""
    },
    "Borrowers": {
        route: "/users/borrowers",
        icon: "",
    },
    "Lenders": {
        route: "/users/lenders",
        icon: "",
    },
    "Partners": {
        route: "/users/partners",
        icon: "",
    },
    "Inquiries": {
        route: "/inquiries",
        icon: "lm-inquiries-icon",
    },
    "Logs": {
        route: "/logs",
        icon: "lm-logs-icon",
    },
    "Templates": {
        route: "/templates",
        icon: "lm-templates-icon",
    },
}

// Points to Remember
// 1. The values in "selector" and "value" should be similar to what the API is providing keys in the array of objects.

export const loanApplicationHeader = [
    {
        name: 'Application No.',
        selector: row => row['ApplicationNumber'],
        value: 'ApplicationNumber',
        sortable: true,
        visible: ["Admin", "Advisor", "Borrower", "Partner", "Lender"],
    },
    {
        name: 'Business Name',
        selector: row => row['BusinessName'],
        value: 'BusinessName',
        sortable: true,
        visible: ["Admin", "Advisor", "Borrower", "Partner", "Lender"],
    },
    {
        name: 'Business Category',
        selector: row => row['BusinessCategory'],
        value: 'BusinessCategory',
        sortable: true,
        visible: ["Borrower", "Lender"],
    },
    {
        name: 'Borrower',
        selector: row => row['BorrowerName'],
        value: 'BorrowerName',
        sortable: true,
        visible: ["Admin", "Advisor", "Borrower", "Partner"],
    },
    {
        name: 'Advisor',
        selector: row => row['selectedAdvisors'],
        value: 'selectedAdvisors',
        sortable: true,
        visible: ["Admin", "Lender"],
    },
    {
        name: 'Partner',
        selector: row => row['selectedPartners'],
        value: 'selectedPartners',
        sortable: true,
        visible: ["Admin", "Advisor"],
    },
    {
        name: 'Lender',
        selector: row => row['selectedLenders'],
        value: 'selectedLenders',
        sortable: true,
        visible: ["Admin", "Advisor"],
    },
    {
        name: 'Loan Amount',
        selector: row => row['BorrowAmount'],
        value: 'BorrowAmount',
        sortable: true,
        visible: ["Admin", "Advisor", "Borrower", "Partner", "Lender"],
    },
    {
        name: 'Status',
        selector: row => row['ApplicationStatus'],
        value: 'ApplicationStatus',
        sortable: true,
        visible: ["Admin", "Advisor", "Borrower", "Partner", "Lender"],
    },
    {
        name: '',
        selector: row => row['option'],
        value: 'option',
        sortable: true,
        visible: ["Admin", "Borrower", "Partner", "Lender"],
    }
];

export const inquiryHeader = [
    {
        name: 'Name',
        selector: row => row['name'],
        value: 'name',
        sortable: true,
    },
    {
        name: 'Subject',
        selector: row => row['subject'],
        value: 'subject',
        sortable: true,
    },
    {
        name: 'Date',
        selector: row => row['date'],
        value: 'date',
        sortable: true,
    },
    {
        name: 'Message',
        selector: row => row['message'],
        value: 'message',
        sortable: true,
    }
];

export const AdminandAdviserHeader = [
    {
        name: 'Name',
        selector: row => row['name'],
        value: 'firstName',
        sortable: true,
    },
    {
        name: 'COMPANY / ENTITY',
        selector: row => row['COMPANY / ENTITY'],
        value: 'companyName',
        sortable: true,
    },
    {
        name: 'PHONE NUMBERS',
        selector: row => row['PHONE NUMBERS'],
        value: 'mobile',
        sortable: true,
    },
    {
        name: 'ROLE',
        selector: row => row['ROLE'],
        value: 'roleName',
        sortable: true,
    },
    {
        name: 'CODE',
        selector: row => row['CODE'],
        value: 'code',
        sortable: true,
    },
    {
        name: 'STATUS',
        selector: row => row['STATUS'],
        value: 'statusDesc',
        sortable: true,
    },
    {
        name: 'LAST LOGIN',
        selector: row => row['LAST LOGIN'],
        value: 'lastLoginDateTime',
        sortable: true,
    },
    {
        name: '',
        selector: row => row['ACTIONS'],
        value: 'Actions',
    }
];
export const BorrowersHeader = [
    {
        name: 'Name',
        selector: row => row['name'],
        value: 'firstName',
        sortable: true,
    },
    {
        name: 'COMPANY / ENTITY',
        selector: row => row['COMPANY / ENTITY'],
        value: 'companyName',
        sortable: true,
    },
    {
        name: 'PHONE NUMBERS',
        selector: row => row['PHONE NUMBERS'],
        value: 'mobile',
        sortable: true,
    },
    {
        name: 'ROLE',
        selector: row => row['ROLE'],
        value: 'roleName',
        sortable: true,
    },
    {
        name: 'STATUS',
        selector: row => row['STATUS'],
        value: 'statusDesc',
        sortable: true,
    },
    {
        name: 'LAST LOGIN',
        selector: row => row['LAST LOGIN'],
        value: 'lastLoginDateTime',
        sortable: true,
    }
];
export const LendersHeader = [
    {
        name: 'Name',
        selector: row => row['name'],
        value: 'firstName',
        sortable: true,
    },
    {
        name: 'COMPANY / ENTITY',
        selector: row => row['COMPANY / ENTITY'],
        value: 'companyName',
        sortable: true,
    },
    {
        name: 'PHONE NUMBERS',
        selector: row => row['PHONE NUMBERS'],
        value: 'mobile',
        sortable: true,
    },
    {
        name: 'CODE',
        selector: row => row['CODE'],
        value: 'code',
        sortable: true,
    },
    {
        name: 'STATUS',
        selector: row => row['STATUS'],
        value: 'statusDesc',
        sortable: true,
    },
    {
        name: 'RATING',
        selector: row => row['RATING'],
        value: 'rating',
        sortable: true,
    }, 
    {
        name: 'LAST LOGIN',
        selector: row => row['LAST LOGIN'],
        value: 'lastLoginDateTime',
        sortable: true,
    },
    {
        name: '',
        selector: row => row['ACTIONS'],
        value: 'Actions',
    }
];

export const PartnersHeader = [
    {
        name: 'Name',
        selector: row => row['name'],
        value: 'firstName',
        sortable: true,
    },
    {
        name: 'COMPANY / ENTITY',
        selector: row => row['COMPANY / ENTITY'],
        value: 'companyName',
        sortable: true,
    },
    {
        name: 'PHONE NUMBERS',
        selector: row => row['PHONE NUMBERS'],
        value: 'mobile',
        sortable: true,
    },
    {
        name: 'ROLE',
        selector: row => row['ROLE'],
        value: 'roleName',
        sortable: true,
    }, {
        name: 'CODE',
        selector: row => row['CODE'],
        value: 'code',
        sortable: true,
    },
    {
        name: 'STATUS',
        selector: row => row['STATUS'],
        value: 'statusDesc',
        sortable: true,
    },
    {
        name: 'LAST LOGIN',
        selector: row => row['LAST LOGIN'],
        value: 'lastLoginDateTime',
        sortable: true,
    },
    {
        name: '',
        selector: row => row['ACTIONS'],
        value: 'Actions',
    }
];

export const LoanApplicationAffiliatesHeader = [
    {
        name: 'BUSINESS NAME',
        selector: row => row['BUSINESS NAME'],
        value: 'BusinessName',
        sortable: true,
    },
    {
        name: 'TAX ID',
        selector: row => row['TAX ID'],
        value: 'TaxID',
        sortable: true,
    },
    {
        name: 'NAICS CODE',
        selector: row => row['NAICS CODE'],
        value: 'NAICSCode',
        sortable: true,
    },
    {
        name: 'CONTACT NUMBER',
        selector: row => row['BusinessContactNo'],
        value: 'BusinessContactNo',
        sortable: true,
    },
    {
        name: 'EMAIL',
        selector: row => row['BusinessEmail'],
        value: 'BusinessEmail',
        sortable: true,
    },
    {
        name: 'STATUS',
        selector: row => row['STATUS'],
        value: 'Isactive',
        sortable: true,
    },
    {
        name: 'Action',
        sortable: false,
    }
];


export const LogsHeader = [
    {
        name: 'USER NAME',
        selector: row => row['Name'],
        value: 'Name',
        sortable: true,
    },
    {
        name: 'BUSINESS NAME',
        selector: row => row['BusinessName'],
        value: 'BusinessName',
        sortable: true,
    },
    {
        name: 'USER TYPE',
        selector: row => row['USER TYPE'],
        value: 'comment',
        sortable: true,
    },
    {
        name: 'APPLICATION NUMBER',
        selector: row => row['APPLICATION NUMBER'],
        value: 'applicationNumber',
        sortable: true,
    },
    {
        name: 'DATE AND TIME',
        selector: row => row['DATE AND TIME'],
        value: 'DATE',
        sortable: true,
    },
    {
        name: 'DETAILS',
        selector: row => row['DETAILS'],
        value: 'Details',
        sortable: true,
    }
];

export const DocumentsListHeader = [
    {
        name: 'Select All',
        selector: row => row['Select All'],
        value: '',
        sortable: false,
    },
    {
        name: 'Document Name',
        selector: row => row['Document Name'],
        value: 'Name',
        sortable: true,
    },
    {
        name: 'UPDATED ON',
        selector: row => row['UPLOADED ON'],
        value: 'UploadedDate',
        sortable: true,
    },
    {
        name: 'STATUS',
        selector: row => row['STATUS'],
        value: 'Status',
        sortable: true,
    },
    {
        name: 'ACTIVATE',
        selector: row => row['ACTIVATE'],
        visible: ["Admin", "Advisor", "Partner", "Lender"],
        value: 'IsActive',
        sortable: true,
    },
    {
        name: 'VERIFY',
        selector: row => row['VERIFY'],
        visible: ["Admin", "Advisor", "Partner", "Lender"],
        value: 'Verified',
        sortable: true,
    },
    {
        name: 'ACTIONS',
        selector: row => row['ACTIONS'],
        value: 'Actions',
    }
];

export const businessCashFlowColoums = [
    {
        name: 'BUSINESS NAME',
        selector: row => row['BusinessName'],
        value: 'BusinessName',
        sortable: true,
    },
    {
        name: 'TAX ID',
        selector: row => row['TaxID'],
        value: 'TaxID',
        sortable: true,
    },
    {
        name: 'INCEPTION DATE',
        selector: row => row['InceptionDate'],
        value: 'InceptionDate',
        sortable: true,
    },
    {
        name: 'NAICS',
        selector: row => row['NAICS_Code'],
        value: 'NAICS_Code',
        sortable: true,
    },
    {
        name: 'TYPE OF ENTITY',
        selector: row => row['TYPE OF ENTITY'],
        value: 'Type of Entity',
        sortable: false,
    },
    {
        name: 'Actions',
        selector: row => row['Actions'],
        value: 'Actions',
    }
]


export const personalCashFlowColoums = [
    {
        name: 'NAME',
        selector: row => row['Name'],
        value: 'Name',
        sortable: true,
    },
    {
        name: 'TITLE',
        selector: row => row['Title'],
        value: 'Title',
        sortable: true,
    },
    {
        name: 'EMAIL',
        selector: row => row['Email'],
        value: 'Email',
        sortable: true,
    },
    {
        name: 'OWNERSHIP (%)',
        selector: row => row['Ownership'],
        value: 'Ownership',
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row['Actions'],
        value: 'Actions',
    }
]

export const OwnerListHeadsers = [
    {
        name: 'Name',
        selector: row => row['name'],
        value: 'name',
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row['email'],
        value: 'email',
        sortable: true,
    },
    {
        name: businessType => businessType === 'LLC' ? 'Member' : 'Director',
        selector: row => row['Title'],
        value: 'Title',
        sortable: true,
    },
    {
        name: 'Ownership',
        selector: row => row['Ownership'],
        value: 'ownershipePercentage',
        sortable: true,
    },
    {
        name: 'Actions',
        sortable: false,
    },
];

export const PFSApplicationList = [
    {
        name: 'Name',
        selector: row => row['Name'],
        value: 'Name',
        sortable: true,
    },
    {
        name: 'Title',
        selector: row => row['Title'],
        value: 'Title',
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row['Email'],
        value: 'Email',
        sortable: true,
    },
    {
        name: 'Ownership',
        selector: row => row['Ownership'],
        value: 'Ownership',
        sortable: true,
    },
    {
        name: 'Actions',
        sortable: false,
    },
];


export const templateDocumentColumns = [
    {
        name: 'Sr.No.',
        sortable: false,
    },
    {
        name: 'Document Name',
        sortable: true,
        selector: row => row['TD_Name'],
        value: 'TD_Name',
    },
    {
        name: 'Category',
        sortable: true,
        selector: row => row['TD_BusinessCatagory'],
        value: 'TD_BusinessCatagory',
    },
    {
        name: 'Business Type',
        sortable: true,
        selector: row => row['TD_BusinessType'],
        value: 'TD_BusinessType',
    },
    {
        name: 'Lender',
        sortable: true,
        selector: row => row['LenderName'],
        value: 'TD_ExistingLender',
    },
    {
        name: 'E-Sign',
        sortable: true,
        selector: row => row['TD_DocuSign'],
        value: 'TD_DocuSign',
    },
    {
        name: 'All Cases',
        sortable: true,
        selector: row => row['TD_AllCases'],
        value: 'TD_AllCases',
    },
    {
        name: 'Action',
        sortable: false,
    }
]


export const verificationTableColumns = [
    {
        name: 'THIRD PARTY',
        selector: row => row['THIRD PARTY'],
        value: 'THIRD PARTY',
        sortable: true,
    },
    {
        name: 'BORROWER',
        selector: row => row['BORROWER'],
        value: 'BORROWER',
        sortable: true,
    },
    {
        name: 'STATUS',
        selector: row => row['STATUS'],
        value: 'STATUS',
        sortable: true,
    },
]

export const userLenderLogsColumns = [
    {
        name: 'User Name',
        selector: row => row['User Name'],
        value: 'User Name',
        sortable: true,
    },
    {
        name: 'User Type',
        selector: row => row['User Type'],
        value: 'User Type',
        sortable: true,
    },
    {
        name: 'Comment Date',
        selector: row => row['Comment Date'],
        value: 'Comment Date',
        sortable: true,
    },
    {
        name: 'Transaction',
        selector: row => row['Transaction'],
        value: 'Transaction',
        sortable: true,
    },
    {
        name: 'Rating',
        selector: row => row['Rating'],
        value: 'Rating',
        sortable: true,
    },
    {
        name: 'Admin',
        selector: row => row['Admin'],
        value: 'Admin',
        sortable: false,
    },
]
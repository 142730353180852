const formatDatehandler = (date) => {
  if (date !== null) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

};
export const ObjectDataPersonalProfiledata = (formData) => {
  const ObjectDataPersonalProfile = {
    LoanId: 0,
    personalResumeID: formData.personalResumeID,
    OwnerId: 0,
    Name: formData.First_Name,
    LastName: formData.Last_Name,
    SocialSecurityNumber: formData.Social_Security_Number,
    RandomNumber: '',
    DriverLicenseNumber: formData.Driver_License_Number,
    DateofBirth: formData.Date_of_Birth_Personal_info,
    BorrowerContactNumber: formData.Borrower_Contact_Number,
    IsUSCitizen: formData.Citizenship_Status === "US_Citizen" ? true : false,
    Title: '',
    BusinessMemebr: '',
    USCitizenDetail: formData.Registration_Number,
    NoUSCitizen_Country: formData.Country_of_Citizenship_Lawful_Permanent,
    TypeofViza: formData.Type_of_Visa,
    NoLawfullUSCitizen_Country: formData.Country_of_Citizenship_Not_both,
    isNotLawfullResident: formData.Citizenship_Status === "Not_both" ? true : false,
    isLawfullResident: formData.Citizenship_Status === "Lawful_Permanent_Resident_status" ? true : false,
    City: formData.Birth_City,
    State: formData.Birth_State,
    Country: formData.Birth_Country,
    HomePhone: '',
    BusinessPhone: '',
    spouseEmailId: formData.Spouse_Email,
    spouseName: formData.Spouse_First_Name,
    spouseLastName: formData.Spouse_Last_Name,
    livedFrom: formData.Date_From_Address_info,
    livedTo: formatDatehandler(new Date()),
    BusinessAddressLine1: formData.Street_Address,
    BusinessAddressLine2: formData.Street_Address_2,
    BusinessCity: formData.City,
    BusinessState: formData.State,
    BusinessZIPCode: formData.ZIP_Code,
    IsUSGovEmployee: formData.filed_for_bankruptcy,
    USGovEmployeeDetail: formData.Position,
    USGovEmployeeAgency: formData.Agency,
    USGovEmployeeStartDate: formData.Starting_Date_US_government,
    USGovEmployeeEndDate: formData.End_Date_US_government,
    IsUnderIndictment: formData.owner_presently_under_indictment,
    IndictmentParoleProbation: formData.indictment_Position,
    UnderIndictmentStartDate: formData.Starting_Date_parole_probation,
    UnderIndictmentEndDate: formData.End_Date_parole_probation,
    UnderIndictmentReasonForAdverseAction: formData.owner_presently_under_indictment_Reason,
    IsCriminalOffense: formData.owner_presently_suspended,
    NatureOfCharge: formData.owner_presently_suspended_charge,
    DateOfCharge: formData.Date_of_charge_violation,
    IsVehicleViolation: formData.applied_for_government_financing,
    PastProbationType: formData.I_was,
    OccuranceProbationPastDate: formData.Date_of_Occurrence_violation,
    AdverseActionReason: formData.applied_for_government_financing_Adverse_Action,
    PresentlyDebarred: formData.owner_currently_delinquent,
    PresentlyDebarredType: formData.I_am_presently,
    OccuranceReason: formData.owner_currently_delinquent_presently_Reason_for_occurrence,
    fedralDeptOrAgencyName: formData.owner_currently_delinquent_presently_Federal_Department,
    MoreOwnerOfBusiness: formData.owner_presently_incarcerated,
    DaysDeliquent: formData.owner_presently_incarcerated_Days_Delinquent,
    ReasonForDeliquency: formData.owner_presently_incarcerated_Reason_for_delinquency,
    IsOwnershipInBusiness: true,
    EverFiledForbankruptcy: formData.bankruptcy_,
    IsInvolvedInLegalAction: formData.involved_pending_legal_actions,
    IsControlledByGuarantedLoan: formData.owner_more_than_60_days_delinquent,
    IsGuarantedLoanCurrentDelinquent: formData.owner_more_than_60_days_delinquent_financing_default_currently,
    IsGuarantedLoanDefaultOrCauseLoss: formData.delinquent_financing_default_loss,
    IsJointReturn: formData.owner_file_joint_return,
    spouseSocialSecurityNumber: formData.Spouse_Social_Security_Number,
    SpouseEmailId: formData.Spouse_Email,
    spousePhoneNo: formData.Spouse_Phone_Number,
    OwnerFirstName: '',
    ApplicationNumber: '',
    OwnerLastName: '',
    IsFromEmail: false,
    IsLegalActionPersonal: formData.involved_pending_legal_actions_Business,
    IsLegalActionBusiness: formData.iinvolved_pending_legal_actions_Business,
    LegalActionBusinessPerson: formData.Name_Business_Individual,
    LegalActionDate: formData.Date_of_Legal_Action,
    LegalActionNature: formData.Nature_of_Legal_Action,
    ModifiedBy: 0,
    OwnderInfoList: [],
    OwnerAddressList: []
  };
  return ObjectDataPersonalProfile;
};

export const CurrentEmployeesOptions = [
  { value: '', label: 'Select...' },
  { value: '1-10', label: '1-10' },
  { value: '11-20', label: '11-20' },
  { value: '21-30', label: '21-30' },
  { value: '31-40', label: '31-40' },
  { value: '41-50', label: '41-50' },
  { value: '51-60', label: '51-60' },
  { value: '61-70', label: '61-70' },
  { value: '71-80', label: '71-80' },
  { value: '81-90', label: '81-90' },
  { value: '91-100', label: '91-100' }
];

export const PeakSeasonOptions = [
  { value: '', label: 'Select...' },
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
];

export const LowSeasonOptions = [
  { value: '', label: 'Select...' },
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
];

export const AvrgTermContractOptions = [
  { value: '0', label: 'Select...' },
  { value: '1', label: '1 Years' },
  { value: '2', label: '2 Years' },
  { value: '3', label: '3 Years' },
  { value: '4', label: '4 Years' },
  { value: '5', label: '5 Years' },
  { value: '6', label: '6 Years' },
  { value: '7', label: '7 Years' },
  { value: '8', label: '8 Years' },
  { value: '9', label: '9 Years' },
  { value: '10', label: '10 Years' },
]



export const TermsForPaymentOptions = [
  { value: '0', label: 'Select...' },
  { value: '1', label: 'Progress billing' },
  { value: '2', label: 'Upon completion of job' },
  { value: '3', label: 'Weekly' },
  { value: '4', label: 'Monthly' },
  { value: '5', label: 'Quarterly' },
]

export const LastRenovationOptions = [
  { value: '0', label: 'Select...' },
  { value: '1', label: 'About 1 year ago' },
  { value: '2', label: 'About 2 years ago' },
  { value: '3', label: 'About 3 years ago' },
  { value: '4', label: 'About 4 years ago' },
  { value: '5', label: 'About 5 years ago' },
  { value: '6', label: 'About 6 years ago' },
  { value: '7', label: 'About 7 years ago' },
  { value: '8', label: 'About 8 years ago' },
  { value: '9', label: 'About 9 years ago' },
  { value: '10', label: 'About 10 years ago' }
];

export const TablesSeatingOptions = [
  { value: '0', label: 'Select...' },
  { value: '1', label: '1-10' },
  { value: '2', label: '11-20' },
  { value: '3', label: '21-30' },
  { value: '4', label: '31-40' },
  { value: '5', label: '41-50' },
  { value: '6', label: '51-60' },
  { value: '7', label: '61-70' },
  { value: '8', label: '71-80' },
  { value: '9', label: '81-90' },
  { value: '10', label: '91-100' },
  { value: '11', label: '100 Plus' }
];
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, createRef, useState, useEffect, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import { SidePanel, ProfileHeader } from "@components";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Preloader from "../../plugins/Preloader";
import close_popup from "../../assets/icons/close_popup.svg";
import edit_pen from "../../assets/icons/edit-pen.svg";
import add_note from "../../assets/icons/make-note-icon.svg";
import delete_icon from "../../assets/icons/lm-trash-icon.svg";
import dummy_avtar from "../../assets/img/dummy_avtar.png";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from 'lodash';
import {
  GetAffiliateListByLoanId,
  GetAffiliateDetailsById,
  InsertUpdateAffiliateInfo,
  ActiveInactiveAffiliateById,
  DeleteAffiliateById,
  InsertUpdateApplicationNotes,
  GetApplicationNotes,
} from "../../services/loanApplicationServices";
import { getSessionId } from "../../services/storageData";
import Constants from "../../helpers/Constants";
import reducersAndActions from "../../_redux/slices/index";
import { businessNameValidator, contactNumberValidate, decryptText } from "../../helpers/DynamicFunctions";
import {
  FieldGroup,
  FormBuilder,
  Validators,
  FieldControl,
} from "react-reactive-form";
import FormField from "../../components/FormField/FormField";
import DynamicTable from "../../plugins/DynamicTable";
import { LoanApplicationAffiliatesHeader } from '../../helpers/DropdownsAndMappers';
import '../styles/Users.css';
import ContactNumberField from "../../components/ContactField/ContactField";
import moment from "moment";
import { staticBusinessCategoriesList, staticTypeOfBusinessList } from "../../helpers/staticContentData";

// Reactive Form Group
const affiliateForm = FormBuilder.group({
  BusinessName: ["", [Validators.required, businessNameValidator]],
  DBA: [""],
  TypeOfBusiness: ["", [Validators.required]],
  BusinessCategory: [""],
  TaxID: ["", [Validators.required]],
  BusinessContactNo: ["", [Validators.required, contactNumberValidate]],
  NAICSCode: [0],
  NumberOfEmplyees: ["",],
  BusinessEmail: ["", [Validators.required, Validators.email]],
});

let _loanId;
let TypeOfBusnessOptionsTemp = [];
let BusinessCategoriesOptionsTemp = [];

function Affiliates() {
  const params = useParams();
  const dispatch = useDispatch();
  const businessRef = createRef();
  const categoryRef = createRef();
  const location = useLocation()

  const userdetails = location.state?.data

  const user = useSelector((state) => state.auth);
  const userRole = user.user.roleName;
  const id = user.user.id;

  const [TypeOfBusnessOptions, setTypeOfBusnessOptions] = useState([]);
  const [BusinessCategoriesOptions, setBusinessCategoriesOptions] = useState([]);

  const [AffiliateId, setAffiliateId] = useState("");

  const [confirmRemove, setConfirmRemove] = useState(false);
  const [loading, setLoading] = useState(true);

  const [noteComment, setNotesComment] = useState("");
  const [notesList, setNotesList] = useState([]);
  const [selectedNoteToRemove, setSelectedNoteToRemove] = useState(null);
  const [showConfirmNoteRemove, setShowConfirmNoteRemove] = useState(false);
  const [selectedAffiliatesForNotes, setSelectedAffiliatesForNotes] = useState(null);

  useEffect(() => {
    dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
  }, [loading]);

  const [totalData, setTotalData] = useState(0);
  const [inquiries, setInquiries] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [contactTaxId, setContactTaxId] = useState("")

  // Fetch inquiries whenever page size or page index changes
  const debouncedGetInquiries = useCallback(debounce(() => {
    if (_loanId) {
      getInquiries();
    }
  }, 300), [_loanId, pageIndex, pageSize]);

  useEffect(() => {
    debouncedGetInquiries();
  }, [debouncedGetInquiries]);

  // Function to fetch inquiries
  const getInquiries = async () => {
    try {
      setLoading(true);
      if (userdetails && _loanId) {
        _loanId = userdetails.data ? userdetails.data.loanId : userdetails.Id
      }

      let queryObj = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        LoanId: _loanId,
        SessionId: localStorage.getItem('sessionId')
      };
      let response = await GetAffiliateListByLoanId(queryObj);

      if (response.status === 200 && response.resultObject) {
        let inquiries = JSON.parse(response.resultObject);
        setInquiries(inquiries);
        let total = response.message.split(" ");
        setTotalData(Number(total[1]));
      } else {
        setTotalData(0);
        setInquiries([]);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setTotalData(0);
    }
  }

  // Preloader Start
  useEffect(() => {
    document.title = "Affiliates - Loan Application";
    let oid = decryptText(params.loanId);
    _loanId = oid;

    getMasterArrayList();
  }, []);
  // Preloader End

  const getMasterArrayList = async () => {
    try {
      TypeOfBusnessOptionsTemp = staticTypeOfBusinessList;
      BusinessCategoriesOptionsTemp = staticBusinessCategoriesList;
      setTypeOfBusnessOptions(staticTypeOfBusinessList);
      setBusinessCategoriesOptions(staticBusinessCategoriesList);
    } catch (err) {
      console.log(err);
    }
  };


  const handleCheckboxChange = async (e, id) => {
    setLoading(true);
    let queryObj = {
      AffiliateId: parseInt(id),
      SessionId: getSessionId(),
    };

    let response = await ActiveInactiveAffiliateById(queryObj);
    if (response.status === 200) {
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.message, type: "success", }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIM);
      setLoading(false);
      getInquiries()
    } else {
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.message, type: "error", }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      setLoading(false);
    }
  };

  const AffiliateAddEditModel = async (Id, _loanId) => {
    try {
      affiliateForm.reset();
      setAffiliateId(Id);
      let queryObj = {
        AffiliateId: Id,
        SessionId: getSessionId(),
      };

      if (Id > 0) {
        const modelAffiliateDetails = await GetAffiliateDetailsById(queryObj);
        if (modelAffiliateDetails.status === 200) {
          let finalData = modelAffiliateDetails.resultObject ? JSON.parse(modelAffiliateDetails.resultObject) : "";

          if (finalData && Array.isArray(finalData) && finalData.length > 0) {
            affiliateForm.patchValue({
              BusinessName: finalData[0].BusinessName,
              DBA: finalData[0].DBA,
              TypeOfBusiness: finalData[0].TypeOfBusiness,
              BusinessCategory: finalData[0].BusinessCategory,
              TaxID: finalData[0].TaxID,
              BusinessContactNo: finalData[0].BusinessContactNo,
              NAICSCode: finalData[0].NAICSCode,
              NumberOfEmplyees: finalData[0].NumberOfEmployee,
              BusinessEmail: finalData[0].BusinessEmail,
            });

            for (let elem = 0; elem < TypeOfBusnessOptionsTemp.length; elem++) {
              let elemvalue = TypeOfBusnessOptionsTemp[elem];
              if (elemvalue.value === finalData[0].TypeOfBusiness) {
                affiliateForm.patchValue({ TypeOfBusiness: elemvalue });
              }
            }

            for (let elem = 0; elem < BusinessCategoriesOptionsTemp.length; elem++) {
              let elemvalue = BusinessCategoriesOptionsTemp[elem];
              if (elemvalue.value === finalData[0].BusinessCategory) {
                affiliateForm.patchValue({ BusinessCategory: elemvalue });
              }
            }
          }
        }
      }

      setAffiliatesDrawer(true);
      setIsEditDrawer(Id > 0 ? true : false);
    } catch (err) {
      console.log(err);
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
    }
  };

  // Popup function
  const [isEditDrawer, setIsEditDrawer] = useState(false);
  const [affiliatesDrawerShow, setAffiliatesDrawer] = useState(false);
  const affiliatesDrawerClose = () => setAffiliatesDrawer(false);

  // Note Popup function
  const [addNoteDrawerShow, setNoteDrawer] = useState(false);
  const noteDrawerClose = () => setNoteDrawer(false);


  // ... existing code ...

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (affiliateForm.status !== "INVALID") {
      setLoading(true);
      // Check and set default values if any field is null
      Object.keys(affiliateForm.controls).forEach(key => {
        const control = affiliateForm.get(key);
        if (control?.value === null || control?.value === undefined) {
          control?.setValue("");  // Default to an empty string for text fields
        }
      });
      let finalObj = {
        ...affiliateForm.value,
        IsFlagSetForBusinessCategoryChange: false,
        UserId: 1,
        Id: AffiliateId,
        LoanId: _loanId,
        SessionId: getSessionId(),
      };

      finalObj = {
        ...finalObj,
        TypeOfBusiness: affiliateForm.value.TypeOfBusiness.value,
        BusinessCategory: affiliateForm.value.BusinessCategory.value,
      };


      try {
        const data = await InsertUpdateAffiliateInfo(finalObj);
        setLoading(false);
        if (data.status === 200) {
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "success", }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);

          getInquiries();
          setAffiliatesDrawer(false);
        } else {
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "error", }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      }
    } else {
      markAllAsTouched(affiliateForm);
    }
  };


  // Marking all input fields as touched if the form is not valid
  const markAllAsTouched = (formGroup) => {
    if (businessRef.current) businessRef.current.focus();
    if (categoryRef.current) categoryRef.current.focus();

    Object.keys(formGroup.controls).forEach((controlName) => {
      const control = formGroup.get(controlName);
      if (control) {
        control.active = true;
        control.touched = true;
        if (document.getElementById(controlName)) {
          document.getElementById(controlName).focus();
        }
      }
    });
  };

  const dynamicTableDisplay = (data) => {
    return (
      <tr>
        <td>{data.BusinessName}</td>
        <td>{data.TaxID}</td>
        <td>{data.NAICSCode}</td>
        <td>{data.BusinessContactNo}</td>
        <td>{data.BusinessEmail}</td>
        <td> <div className="lm-toggle-switch">
          <input
            type="checkbox"
            id={`switch` + data.Id}
            checked={data.Isactive === "true" ? true : false}
            onChange={(e) => handleCheckboxChange(e, data.Id)}
          />
          <label htmlFor={`switch` + data.Id}>Status</label>
        </div>
        </td>
        <td>
          <div className="d-flex gap-10 align-items-center">
            <a onClick={() => openNotesModel(data.Id)}>
              <img className="lm-grid-edit-icon" src={add_note} alt="add-note" />
            </a>
            <a onClick={() => { AffiliateAddEditModel(data.Id, data.LoanId) }} >
              <img className="lm-grid-edit-icon" src={edit_pen} alt="" />
            </a>
          </div>
        </td>
      </tr>
    )
  };

  const selectRef = useRef(null);

  useEffect(() => {
    const handleOpen = (event) => {
      const selectElement = selectRef.current;
      if (selectElement) {
        const menuElement = selectElement.querySelector('.select__menu');
        if (menuElement) {
          const rect = selectElement.getBoundingClientRect();
          const spaceBelow = window.innerHeight - rect.bottom;
          const spaceAbove = rect.top;
          const menuHeight = menuElement.offsetHeight;

          if (spaceBelow < menuHeight && spaceAbove > spaceBelow) {
            menuElement.style.bottom = `${rect.height}px`;
            menuElement.style.top = 'auto';
          } else {
            menuElement.style.top = `${rect.height}px`;
            menuElement.style.bottom = 'auto';
          }
        }
      }
    };

    document.addEventListener('mousedown', handleOpen);
    return () => {
      document.removeEventListener('mousedown', handleOpen);
    };
  }, []);

  const handleRemoveAffiliate = async () => {
    try {
      setConfirmRemove(false);
      setLoading(true);

      const finalObj = {
        AffiliateId: AffiliateId,
        ModifiedBy: user.user.id,
        SessionId: getSessionId(),
      };
      const data = await DeleteAffiliateById(finalObj);
      setLoading(false);
      if (data.status === 200) {
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "success", }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        getInquiries();
        setAffiliatesDrawer(false);
      } else {
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "error", }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
    }
  }


  // Notes secion start here ----------------------

  const getNewApplicationNotes = async (affilateId) => {
    try {
      const params = {
        loanId: parseInt(_loanId),
        ownerId: parseInt(affilateId),
        documentId: 0,
        createdBy: 0,
        screenName: "Affiliate"
      }
      const getNotesList = await GetApplicationNotes(params)
      setLoading(false)
      if (getNotesList.status !== 200) {
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: getNotesList.message, type: "error", }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      } else {
        setNotesList(getNotesList.resultObject);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
    }
  }

  const openNotesModel = async (affilateId) => {
    setLoading(true)
    setNotesList([])
    setNotesComment("")
    setSelectedAffiliatesForNotes(affilateId);
    getNewApplicationNotes(affilateId)
    setNoteDrawer(true)
  }


  const handleAddNewNote = async () => {
    if (noteComment && noteComment.trim() !== "") {
      setLoading(true);
      let newNote = {
        "id": 0,
        "loanId": parseInt(_loanId),
        "ownerId": parseInt(selectedAffiliatesForNotes),
        "documentId": 0,
        "isDeleted": 0,
        "createdBy": parseInt(user.user.id),
        "notes": noteComment,
        "screenName": "Affiliate"
      };

      const addNewNote = await InsertUpdateApplicationNotes(newNote);
      if (addNewNote.status === 200) {
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: addNewNote.message, type: "success", }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        setNotesComment("");
        setLoading(false);
        setNoteDrawer(false);
      } else {
        setLoading(false);
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: addNewNote.message, type: "error", }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      }
    } else {
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Enter a valid note comment", type: "error", }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
    }
  }

  const handleDeleteNote = async () => {
    try {
      setLoading(true);
      const deletedNoteDetails = notesList.find((note) => note.id === selectedNoteToRemove);
      let newNote = { ...deletedNoteDetails, isDeleted: 1 };
      const deleteNote = await InsertUpdateApplicationNotes(newNote);
      if (deleteNote.status === 200) {
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Note has been deleted successfully", type: "success", }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        setLoading(false);
        setNoteDrawer(false);
      } else {
        setLoading(false);
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: deleteNote.message, type: "error", }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      }
      setShowConfirmNoteRemove(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  // Notes secion end here ----------------------

  return (
    <>
      {/* SidePanel */}
      <SidePanel />

      {/* ProfileHeader */}
      <ProfileHeader />

      {loading ? (
        <Preloader />
      ) : (

        <div>
          {/* <!-- affiliates grid table start --> */}
          <div className="grid-head d-flex justify-content-between">
            <h3>Affiliates</h3>
            <a onClick={() => AffiliateAddEditModel(0, _loanId)} className="lm-button3">
              <span>+</span> Add a new affiliate
            </a>
          </div>

          <div className="lm-grid-table" id="myTable">

            <DynamicTable
              data={inquiries}
              dynamicTableDisplay={dynamicTableDisplay}
              columns={LoanApplicationAffiliatesHeader}
              changePageSize={setPageSize}
              pageSize={pageSize}
              total={totalData}
              setList={setInquiries}
              setPageIndex={setPageIndex}
              pageIndex={pageIndex}
            />

          </div>

          {/* <!-- affiliates grid table end --> */}

          {/* <!--affiliates form drawer start--> */}
          <Modal
            className="fade-right drawer-modal"
            show={affiliatesDrawerShow}
            onHide={() => setAffiliatesDrawer(false)}
            aria-labelledby="affiliatesDrawerLabel"
          >
            <div className="modal-dialog">
              <FieldGroup
                control={affiliateForm}
                render={({ get, invalid }) => (
                  <form>
                    <div className="modal-content">
                      <div className="drawer-head d-flex justify-content-between">
                        <button type="button" className="close" onClick={affiliatesDrawerClose}>
                          <span aria-hidden="true">
                            <img src={close_popup} alt="" />
                          </span>
                        </button>
                      </div>
                      <div className="drawer-body">
                        <div className="form-group">
                          <FormField
                            name="BusinessName"
                            label="Business Name"
                            placeholder="Business Name"
                            id="BusinessName"
                            required={true}
                            errorMessage={"Business Name is required"}
                            maxLength={150}
                          />
                        </div>
                        <div className="form-group">
                          <FieldControl
                            name="TaxID"
                            render={({ handler, touched, hasError, meta, }) => {
                              const handlerProps = handler();
                              const standardizeTaxID = (e) => {
                                let value = e.target.value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters

                                // Format value as XX-XXXXXXX
                                if (value.length > 2) {
                                  value = value.slice(0, 2) + '-' + value.slice(2, 9); // Limit to 9 digits
                                }

                                // Update the input value
                                e.target.value = value;

                                // Trigger onChange handler if provided
                                if (handlerProps.onChange) {
                                  handlerProps.onChange(e);
                                }

                                // Validation
                                const taxIDPattern = /^\d{2}-\d{7}$/;
                                if (value.length === 0) {
                                  setContactTaxId(""); // Clear the error message
                                } else if (!taxIDPattern.test(value)) {
                                  setContactTaxId("Tax ID should be of 9 digits.");
                                } else {
                                  setContactTaxId(""); // Clear the error message
                                }
                              };


                              return (
                                <div className="form-group">
                                  <label htmlFor="TaxID">
                                    Business Tax ID
                                    <span className="asterisk">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="TaxID"
                                    label="Business Tax ID"
                                    placeholder="22-4351965"
                                    id="TaxID"
                                    {...handlerProps}
                                    onChange={standardizeTaxID}
                                  />

                                  {/* Error messages below */}
                                  <span className="error-message">{touched && hasError("required") && `Business Tax ID is required`}                                  </span>
                                  {contactTaxId && (<>  <span className="error-message">    {contactTaxId}  </span>  <br /></>)}
                                </div>
                              );
                            }}
                            meta={{ placeholder: "47-113478" }}
                          />
                        </div>
                        <div className="form-group">
                          <FieldControl
                            name="TypeOfBusiness"
                            render={({ handler, touched, hasError, meta, }) => (
                              <div className="form-group">
                                <label htmlFor="TypeOfBusiness">
                                  Type of business
                                  <span className="asterisk">*</span>
                                </label>
                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  id="TypeOfBusiness"
                                  name="TypeOfBusiness"
                                  ref={businessRef}
                                  options={TypeOfBusnessOptions}
                                  {...handler()}
                                />
                                {/* Error messages below */}
                                <span className="error-message">{touched && hasError("required") && `Business type is required`}</span>
                              </div>
                            )}
                          />
                        </div>
                        <div className="form-group">
                          <ContactNumberField
                            name="BusinessContactNo"
                            label="Business Contact Number"
                            id="BusinessContactNo"
                            required={true}
                            errorMessage="Contact number is required"
                          />
                        </div>
                        <div className="form-group">
                          <FormField
                            name="NAICSCode"
                            label="NAICS Code"
                            placeholder="47-113478"
                            id="NumberOfEmplyees"
                            maxlength={9}
                            onlyNumbers={true}
                          />
                        </div>
                        <div className="form-group">
                          <FormField
                            name="DBA"
                            label="D/B/A"
                            placeholder="Enter d/b/a"
                            id="DBA"
                            required={false}
                            errorMessage={" is required"}
                            maxlength={150}
                          />
                        </div>
                        <div className="form-group">
                          <FieldControl
                            name="BusinessCategory"
                            render={({ handler, touched, hasError, meta, }) => (
                              <div className="form-group" ref={selectRef}>
                                <label htmlFor="BusinessCategory">
                                  Business Category
                                </label>
                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  id="BusinessCategory"
                                  name="BusinessCategory"
                                  options={BusinessCategoriesOptions}
                                  {...handler()}
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      position: 'fixed',
                                      width: 'auto',
                                      minWidth: '100%',
                                    }),
                                  }}
                                />
                                {/* Error messages below */}
                                <span className="error-message">{touched && hasError("required") && `Business Category is required`}</span>
                              </div>
                            )}
                          />
                        </div>
                        <div className="form-group">
                          <FormField
                            name="NumberOfEmplyees"
                            label="Number of Employees"
                            placeholder="10"
                            id="NumberOfEmplyees"
                            required={false}
                            errorMessage={"Number of employees is required"}
                            maxlength={7}
                            onlyNumbers={true}
                          />
                        </div>
                        <div className="form-group">
                          <FormField
                            name="BusinessEmail"
                            label="Business Email"
                            placeholder="Email Address"
                            id="BusinessEmail"
                            required={true}
                            errorMessage={"Business Email is required"}
                            maxlength={100}
                          />
                        </div>
                      </div>
                      <div className="drawer-footer gap-10 d-flex">
                        {
                          isEditDrawer && (
                            <button type="button" className="lm-button2 text-danger w-100" onClick={() => setConfirmRemove(true)}>Remove</button>
                          )
                        }
                        <button type="button" className={isEditDrawer ? "lm-button1 w-100" : "lm-button1 ml-auto"} onClick={handleSubmit}>Save</button>
                      </div>
                    </div>
                  </form>
                )}
              />
            </div>
          </Modal>
          {/* <!--affiliates form drawer end--> */}


          {/* <!--Add Note drawer start--> */}
          <Modal
            className="fade-right drawer-modal add-notes-drawer"
            show={addNoteDrawerShow}
            onHide={() => setNoteDrawer(false)}
            aria-labelledby="addNoteDrawerLabel"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="drawer-head d-flex justify-content-between">
                  <h3>Notes {notesList.length ? '(' + notesList.length + ')' : ''}</h3>
                  <button type="button" className="close" onClick={noteDrawerClose}>
                    <span aria-hidden="true">
                      <img src={close_popup} alt="" />
                    </span>
                  </button>
                </div>
                <div className="drawer-body">
                  <div className="note-list mt-2">
                    {
                      notesList.map((note, index) => (
                        <div className="note-item">
                          <div className="d-flex justify-content-between">
                            <div className="note-user">
                              <img src={note.profileImage ? `data:image/png;base64,${note.profileImage}` : dummy_avtar} alt="" />
                              <h4>{note.createdName}</h4>
                            </div>
                            <div className="d-flex gap-10 place-items-center">
                              <small className="text-light">{moment.utc(note.createdDate).local().format("MMM DD, YYYY : hh:mm:A")}</small>
                              {
                                (userRole === 'Admin' || note.createdBy === id || (userRole === "Advisor" && note.roleName !== "Admin")) &&
                                <a onClick={() => {
                                  setSelectedNoteToRemove(note.id)
                                  setShowConfirmNoteRemove(true)
                                }}>
                                  <img className='lm-grid-delete-icon' src={delete_icon} alt="" />
                                </a>
                              }
                            </div>
                          </div>
                          <p className="note-text">
                            {note.notes}
                          </p>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className="drawer-footer">
                  <textarea
                    id="add_note"
                    className="form-control"
                    placeholder="Enter Text"
                    value={noteComment}
                    onChange={(e) => setNotesComment(e.target.value)}
                    maxlength="250"
                  >
                  </textarea>
                  <div className="w-100 mt-3">
                    <button type="button" className="lm-button1 w-100" onClick={handleAddNewNote}>Add a Note</button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          {/* <!--Add Note drawer end--> */}

          {/* <!-- Remove Affiliate Popup / Modal --> */}
          <Modal
            className='mark-comp-popup-cust-size'
            show={confirmRemove}
            onHide={() => setConfirmRemove(false)}
            aria-labelledby="markCompletePopupLabel"
            backdrop="static"
            keyboard={false}
            centered
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-body signout-modal">
                  <h3 className="pt-4">
                    Are you sure you want remove this affiliate?
                  </h3>
                </div>
                <div className="modal-footer la-app-modal-footer">
                  <button type="button" className="lm-button2" onClick={() => setConfirmRemove(false)}>Cancel</button>
                  <button type="button" className="lm-button1" onClick={handleRemoveAffiliate}>Confirm</button>
                </div>
              </div>
            </div>
          </Modal>
          {/* <!-- Remove Affiliate Popup / Modal --> */}


          {/* <!-- Remove Affiliate Popup / Modal --> */}
          <Modal
            className='mark-comp-popup-cust-size'
            show={showConfirmNoteRemove}
            onHide={() => setShowConfirmNoteRemove(false)}
            aria-labelledby="markCompletePopupLabel"
            backdrop="static"
            keyboard={false}
            centered
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-body signout-modal">
                  <h3 className="pt-4">
                    Are you sure you want to remove this note?
                  </h3>
                </div>
                <div className="modal-footer la-app-modal-footer">
                  <button type="button" className="lm-button2" onClick={() => setShowConfirmNoteRemove(false)}>Cancel</button>
                  <button type="button" className="lm-button1" onClick={handleDeleteNote}>Confirm</button>
                </div>
              </div>
            </div>
          </Modal>
          {/* <!-- Remove Affiliate Popup / Modal --> */}
        </div>
      )}
    </>
  );
}

export default Affiliates;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import edit_pen from '../../../../assets/icons/edit-pen.svg';
import close_popup from '../../../../assets/icons/close_popup.svg';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Constants from '../../../../helpers/Constants';
import reducersAndActions from '../../../../_redux/slices';
import OpenAIComponent from '../../../../components/OpenAI/OpenAIComponent';

const WorkExperience = ({ onUpdate, workExperiencesData }) => {
    const dispatch = useDispatch()

    const [workExperiencePopupShow, setWorkExperiencePopupShow] = useState(false);
    const [experienceStartDate, setExperienceStartDate] = useState(null);
    const [experienceEndDate, setExperienceEndDate] = useState(null);
    const [workExperiences, setWorkExperiences] = useState([]);
    const [type, setType] = useState('Add');

    const [editIndex, setEditIndex] = useState(null);
    const [formData, setFormData] = useState({
        companyName: '',
        startDate: null,
        endDate: null,
        address: '',
        position: '',
        title: '',
        isCurrentExperience: false,
        id: 0,
        isDeleted: 0
    });

    useEffect(() => {
        setWorkExperiences(workExperiencesData);
    }, [workExperiencesData]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleApplicationCommentChange = (value) => {
        setFormData({ ...formData, position: value });
    };

    // Define the handleDateChange function
    const handleDateChange = (key, dateValue) => {
        const formattedDate = dateValue === null ? null : moment.utc(dateValue).local().format("YYYY-MM-DD");
        console.log('formattedDate', formattedDate)
        if (key === "startDate") {
            setExperienceStartDate(formattedDate);
            if (formattedDate > experienceEndDate) {
                setExperienceEndDate(null);
            }
        } else if (key === "endDate") {
            if (formattedDate < experienceStartDate) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "End date cannot be before start date", type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                return; // prevent updating if the end date is invalid
            }
            setExperienceEndDate(formattedDate);
        }
        setFormData((prevData) => ({ ...prevData, [key]: formattedDate }));
    };

    const handleSubmit = () => {
        setWorkExperiencePopupShow(false);
        if (formData.address.length > 0 || formData.companyName.length > 0 || formData.title.length > 0 || formData.position.length > 0 || (formData.startDate !== null && formData.endDate !== null)) {
            const updatedExperiences = [...workExperiences];
            if (editIndex !== null) {
                updatedExperiences[editIndex] = formData;
            } else {
                updatedExperiences.push(formData);
            }
            setWorkExperiences(updatedExperiences);
            onUpdate(updatedExperiences);
            setFormData({
                companyName: '',
                startDate: null,
                endDate: null,
                address: '',
                position: '',
                title: '',
                isCurrentExperience: false,
                id: 0,
                isDeleted: 0
            });
            setExperienceStartDate(null);
            setExperienceEndDate(null);
            setEditIndex(null);
        }
    };

    const handleEdit = (index) => {
        const experience = workExperiences[index];
        setExperienceStartDate(experience.startDate);
        setExperienceEndDate(experience.endDate);
        setFormData(experience);
        setEditIndex(index);
        setType('Edit');
        setWorkExperiencePopupShow(true);
    };

    const handleRemove = () => {
        let indexData = { ...workExperiences[editIndex], isDeleted: 1 };
        console.log('indexData', indexData)
        const removedData = workExperiences.filter((item, index) => index !== editIndex);
        const updatedExperiences = [...removedData, indexData];
        setWorkExperiences(updatedExperiences);
        onUpdate(updatedExperiences);
        setWorkExperiencePopupShow(false);
        setFormData({
            companyName: '',
            startDate: null,
            endDate: null,
            address: '',
            position: '',
            title: '',
            isCurrentExperience: false,
            id: 0,
            isDeleted: 0
        });
        setEditIndex(null);
    };

    const showAddExpericenceModal = () => {
        setExperienceStartDate(null);
        setExperienceEndDate(null);
        setFormData({
            companyName: '',
            startDate: null,
            endDate: null,
            address: '',
            position: '',
            title: '',
            isCurrentExperience: false,
            id: 0,
            isDeleted: 0
        });
        setEditIndex(null);
        setType('Add');
        setWorkExperiencePopupShow(true);
    };

    const showPeriodDate = (formData) => {
        const { startDate, endDate } = formData;
        if (!startDate) return '';

        const formattedStartDate = moment(startDate).format('MMM YYYY'); // Format: 'Jan 2024'

        if (endDate) {
            const formattedEndDate = moment(endDate).format('MMM YYYY');
            return `${formattedStartDate} - ${formattedEndDate}`;
        } else {
            return `${formattedStartDate} - Present`;
        }
    };


    return (
        <div className="col-12 mt-3">
            <div className="lm-card p-0">
                <div className="d-flex justify-content-between p-3">
                    <h3 className="lm-card-heading mb-0">Work Experience</h3>
                    <a className="lm-button3" onClick={showAddExpericenceModal}><span>+</span> Add experience</a>
                </div>
                <table className="lm-inner-form-grid">
                    <thead>
                        <tr>
                            <th>Company Name</th>
                            <th>Period</th>
                            <th>Location</th>
                            <th>Responsibilities</th>
                            <th>title</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            workExperiences?.map((experience, index) => {
                                if (experience.isDeleted === 1) return null;
                                return (
                                    <tr key={index}>
                                        <td>{experience.companyName}</td>
                                        <td>{showPeriodDate(experience)}</td>
                                        <td>{experience.address}</td>
                                        <td>{experience.position}</td>
                                        <td>{experience.title}</td>
                                        <td>
                                            <a onClick={() => handleEdit(index)}><img src={edit_pen} alt="Edit" /></a>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
            <Modal
                size="md"
                show={workExperiencePopupShow}
                onHide={() => setWorkExperiencePopupShow(false)}
                aria-labelledby="workExperiencePopupLabel"
                backdrop="static"
                keyboard={false}
                centered
            >
                <div className="modal-dialog modal-lg modal-lg-work-exp modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="workExperiencePopupLabel">{type} Work Experience</h5>
                            <button type="button" className="close" onClick={() => setWorkExperiencePopupShow(false)}>
                                <span aria-hidden="true"><img src={close_popup} alt="Close" /></span>
                            </button>
                        </div>
                        <form>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="companyName">Company Name</label>
                                            <input type="text"
                                                className="form-control"
                                                id="companyName"
                                                value={formData.companyName}
                                                onChange={handleInputChange}
                                                maxLength={100}
                                                placeholder="Enter a Company Name" />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="label pb-1">
                                                Are you still working here?
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="anticipateSignificantChanges"
                                                    id="currentlyWorkingYes"
                                                    value="true"
                                                    checked={formData.isCurrentExperience === true}
                                                    onChange={() => {
                                                        setFormData({ ...formData, isCurrentExperience: true, endDate: null });
                                                        setExperienceEndDate(null);
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="currentlyWorkingYes">Yes</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="anticipateSignificantChanges"
                                                    id="currentlyWorkingNo"
                                                    value="false"
                                                    checked={formData.isCurrentExperience === false}
                                                    onChange={() => setFormData({ ...formData, isCurrentExperience: false })}
                                                />
                                                <label className="form-check-label" htmlFor="currentlyWorkingNo">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group input-date">
                                            <label htmlFor="startingEndDates">Starting Date</label>
                                            <DatePicker
                                                showIcon
                                                toggleCalendarOnIconClick
                                                selected={experienceStartDate ? moment(experienceStartDate).format('MMM DD, YYYY') : ''}
                                                isClearable={experienceStartDate}
                                                dateFormat="MMM d, yyyy"
                                                placeholderText="Month DD, YYYY"
                                                yearDropdownItemNumber={100}
                                                scrollableYearDropdown
                                                showMonthDropdown
                                                showYearDropdown
                                                onChange={(e) => handleDateChange("startDate", e)}
                                                maxDate={moment().toDate()}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group input-date">
                                            <label htmlFor="startingEndDates">End Date</label>
                                            <DatePicker
                                                showIcon
                                                toggleCalendarOnIconClick
                                                selected={experienceEndDate ? moment(experienceEndDate).format('MMM DD, YYYY') : ''}
                                                isClearable={true}
                                                dateFormat="MMM d, yyyy"
                                                placeholderText="Month DD, YYYY"
                                                yearDropdownItemNumber={100}
                                                scrollableYearDropdown
                                                showYearDropdown
                                                showMonthDropdown
                                                disabled={formData.isCurrentExperience}
                                                onChange={(e) => handleDateChange("endDate", e)}
                                                maxDate={moment().toDate()}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="location">Location</label>
                                            <input type="text" className="form-control"
                                                maxLength={100}
                                                id="address" value={formData.address} onChange={handleInputChange} placeholder="Enter a Location Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group AI_btn_field">
                                            <label htmlFor="position">Responsibilities</label>
                                            <textarea
                                                className="form-control"
                                                id="position"
                                                maxLength={250}
                                                value={formData.position}
                                                onChange={handleInputChange}
                                                placeholder="Enter text">
                                            </textarea>
                                            <OpenAIComponent
                                                fieldName="ApplicationComment"
                                                value={formData.position}
                                                isFormGroup={false}
                                                setHardData={handleApplicationCommentChange}
                                                type='textarea'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="title">Title</label>
                                            <input type="text"
                                                maxLength={100}
                                                className="form-control" id="title" value={formData.title} onChange={handleInputChange} placeholder="Enter a Title" />
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <div className="modal-footer la-app-modal-footer">
                                {
                                    editIndex !== null ? (
                                        <button onClick={handleRemove} type="button" className="lm-button2 text-red">Remove</button>
                                    ) : <button type="button" className="lm-button2" onClick={() => setWorkExperiencePopupShow(false)}>Cancel</button>
                                }
                                <button onClick={handleSubmit} type="button" className="lm-button1">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default WorkExperience;



export const staticTypeOfBusinessList = [
    // { Id: "", Identifier: "TypeOfBusness", value: "", label: "Select..." },
    { Id: "13", Identifier: "TypeOfBusness", value: "Sole Proprietor", label: "Sole Proprietor" },
    { Id: "14", Identifier: "TypeOfBusness", value: "Limited Liability Company (LLC)", label: "Limited Liability Company (LLC)" },
    { Id: "15", Identifier: "TypeOfBusness", value: "C-Corporation", label: "C-Corporation" },
    { Id: "16", Identifier: "TypeOfBusness", value: "Partnership", label: "Partnership" },
    { Id: "17", Identifier: "TypeOfBusness", value: "Independent Contractor", label: "Independent Contractor" },
    { Id: "59", Identifier: "TypeOfBusness", value: "S-Corporation", label: "S-Corporation" }
]

export const staticBusinessCategoriesList = [
    // { Id: "", Identifier: "BusinessCategories", value: "", label: "Select..." },
    { Id: "33", Identifier: "BusinessCategories", value: "Contractor", label: "Contractor" },
    { Id: "34", Identifier: "BusinessCategories", value: "Gas Station", label: "Gas Station" },
    { Id: "36", Identifier: "BusinessCategories", value: "Hotel", label: "Hotel" },
    { Id: "37", Identifier: "BusinessCategories", value: "Medical", label: "Medical" },
    { Id: "32", Identifier: "BusinessCategories", value: "Restaurant", label: "Restaurant" },
    { Id: "99", Identifier: "BusinessCategories", value: "Transportation", label: "Transportation" },
    { Id: "35", Identifier: "BusinessCategories", value: "Others", label: "Others" }
]

export const staticLoanReasonList = [
    // { Id: "", Identifier: "LoanReason", value: "", label: "Select..." },
    { Id: "1", Identifier: "LoanReason", value: "Accounts Receivables Financing", label: "Accounts Receivables Financing" },
    { Id: "2", Identifier: "LoanReason", value: "Business Acquisition", label: "Business Acquisition" },
    { Id: "3", Identifier: "LoanReason", value: "Business Expansion", label: "Business Expansion" },
    { Id: "4", Identifier: "LoanReason", value: "Construction or Remodeling", label: "Construction or Remodeling" },
    { Id: "5", Identifier: "LoanReason", value: "Equipment Financing", label: "Equipment Financing" },
    { Id: "6", Identifier: "LoanReason", value: "Franchise Financing", label: "Franchise Financing" },
    { Id: "7", Identifier: "LoanReason", value: "Partner Buyout", label: "Partner Buyout" },
    { Id: "8", Identifier: "LoanReason", value: "Real Estate Financing", label: "Real Estate Financing" },
    { Id: "9", Identifier: "LoanReason", value: "Refinance Existing Debt", label: "Refinance Existing Debt" },
    { Id: "10", Identifier: "LoanReason", value: "Start a Business", label: "Start a Business" },
    { Id: "11", Identifier: "LoanReason", value: "Working Capital", label: "Working Capital" },
    { Id: "12", Identifier: "LoanReason", value: "Other", label: "Other" }
]

export const staticGenderList = [
    // { Id: "", Identifier: "Gender", value: "", label: "Select..." },
    { Id: "101", Identifier: "Gender", value: "F", label: "Female" },
    { Id: "100", Identifier: "Gender", value: "M", label: "Male" },
    { Id: "102", Identifier: "Gender", value: "N", label: "Not Disclosed" }
]

export const staticVerteranList = [
    // { Id: "", Identifier: "Veteran", value: "", label: "Select..." },
    { Id: "18", Identifier: "Veteran", value: "1", label: "Non Veteran" },
    { Id: "20", Identifier: "Veteran", value: "3", label: "Service-Disabled Veteran" },
    { Id: "21", Identifier: "Veteran", value: "5", label: "Spouse of Veteran" },
    { Id: "19", Identifier: "Veteran", value: "2", label: "Veteran" },
    { Id: "22", Identifier: "Veteran", value: "4", label: "Not Disclosed" }
]

export const staticEthnicityList = [
    // { Id: "", Identifier: "Ethnicity", value: "", label: "Select..." },
    { Id: "29", Identifier: "Ethnicity", value: "H", label: "Hispanic or Latino" },
    { Id: "30", Identifier: "Ethnicity", value: "N", label: "Not Hispanic or Latino" },
    { Id: "31", Identifier: "Ethnicity", value: "X", label: "Not Disclosed" }
]

export const staticRaceList = [
    // { Id: "", Identifier: "Race", value: "", label: "Select..." },
    { Id: "23", Identifier: "Race", value: "1", label: "American Indian or Alaska Native" },
    { Id: "24", Identifier: "Race", value: "2", label: "Asian" },
    { Id: "25", Identifier: "Race", value: "3", label: "Black or African-American" },
    { Id: "26", Identifier: "Race", value: "4", label: "Native Hawaiian or Pacific Islander" },
    { Id: "27", Identifier: "Race", value: "5", label: "White" },
    { Id: "28", Identifier: "Race", value: "X", label: "Not Disclosed" }
]

export const staticApplicationStatusList = [
    { Id: "57", Identifier: "ApplicationStatus", value: "Closed", label: "Closed" },
    { Id: "56", Identifier: "ApplicationStatus", value: "Completed", label: "Completed" },
    { Id: "53", Identifier: "ApplicationStatus", value: "Documents Required", label: "Documents Required" },
    { Id: "52", Identifier: "ApplicationStatus", value: "In Progress", label: "In Progress" },
    { Id: "51", Identifier: "ApplicationStatus", value: "New", label: "New" },
    { Id: "55", Identifier: "ApplicationStatus", value: "On Hold", label: "On Hold" },
    { Id: "54", Identifier: "ApplicationStatus", value: "Review Required", label: "Review Required" }
]

export const staticBusinessTaxReturnList = [
    // { value: "", label: "Select..." },
    { value: "1040", label: "1040" },
    { value: "1065", label: "1065" },
    { value: "1120", label: "1120" },
    { value: "1120S", label: "1120S" },
    { value: "Others", label: "Others" }
]

export const staticPrescreenRequirementList = [
    // { value: "", label: "Select..." },
    { label: "2 years business tax returns", value: "2 years business tax returns" },
    { label: "2 years personal tax returns", value: "2 years personal tax returns" },
    { label: "Personal Financial Statements", value: "Personal Financial Statements" },
    { label: "Property Rent Roll (if relevant)", value: "Property Rent Roll (if relevant)" },
    { label: "Property Summary (if relevant)", value: "Property Summary (if relevant)" },
    { label: "YTD financials", value: "YTD financials" },
    { label: "YTD financials", value: "YTD financials" }
]

export const staticPropertyTypeList = [
    // { value: "", label: "Select..." },
    { label: "Convenience stores", value: "Convenience stores" },
    { label: "Day Care", value: "Day Care" },
    { label: "Equipment", value: "Equipment" },
    { label: "Franchise", value: "Franchise" },
    { label: "Hotels", value: "Hotels" },
    { label: "Industrial", value: "Industrial" },
    { label: "Medical / Dental", value: "Medical / Dental" },
    { label: "Mixed Use", value: "Mixed Use" },
    { label: "Multi Family", value: "Multi Family" },
    { label: "Office", value: "Office" },
    { label: "Office Condo", value: "Office Condo" },
    { label: "Restaurants", value: "Restaurants" },
    { label: "Retail", value: "Retail" },
    { label: "Self Storage", value: "Self Storage" },
    { label: "Start-Ups", value: "Start-Ups" },
    { label: "Warehouse", value: "Warehouse" },
    { label: "Working Capital", value: "Working Capital" },
    { label: "Others", value: "Others" }
]

export const staticStatesList = [
    // { value: "", label: "Select..." },
    { value: "Alabama", label: "Alabama" },
    { value: "Alaska", label: "Alaska" },
    { value: "Arizona", label: "Arizona" },
    { value: "Arkansas", label: "Arkansas" },
    { value: "California", label: "California" },
    { value: "Colorado", label: "Colorado" },
    { value: "Connecticut", label: "Connecticut" },
    { value: "Delaware", label: "Delaware" },
    { value: "Florida", label: "Florida" },
    { value: "Georgia", label: "Georgia" },
    { value: "Hawaii", label: "Hawaii" },
    { value: "Idaho", label: "Idaho" },
    { value: "Illinois", label: "Illinois" },
    { value: "Indiana", label: "Indiana" },
    { value: "Iowa", label: "Iowa" },
    { value: "Kansas", label: "Kansas" },
    { value: "Kentucky", label: "Kentucky" },
    { value: "Louisiana", label: "Louisiana" },
    { value: "Maine", label: "Maine" },
    { value: "Maryland", label: "Maryland" },
    { value: "Massachusetts", label: "Massachusetts" },
    { value: "Michigan", label: "Michigan" },
    { value: "Minnesota", label: "Minnesota" },
    { value: "Mississippi", label: "Mississippi" },
    { value: "Missouri", label: "Missouri" },
    { value: "Montana", label: "Montana" },
    { value: "Nebraska", label: "Nebraska" },
    { value: "Nevada", label: "Nevada" },
    { value: "New Hampshire", label: "New Hampshire" },
    { value: "New Jersey", label: "New Jersey" },
    { value: "New Mexico", label: "New Mexico" },
    { value: "New York", label: "New York" },
    { value: "North Carolina", label: "North Carolina" },
    { value: "North Dakota", label: "North Dakota" },
    { value: "Ohio", label: "Ohio" },
    { value: "Oklahoma", label: "Oklahoma" },
    { value: "Oregon", label: "Oregon" },
    { value: "Pennsylvania", label: "Pennsylvania" },
    { value: "Rhode Island", label: "Rhode Island" },
    { value: "South Carolina", label: "South Carolina" },
    { value: "South Dakota", label: "South Dakota" },
    { value: "Tennessee", label: "Tennessee" },
    { value: "Texas", label: "Texas" },
    { value: "Utah", label: "Utah" },
    { value: "Vermont", label: "Vermont" },
    { value: "Virginia", label: "Virginia" },
    { value: "Washington", label: "Washington" },
    { value: "West Virginia", label: "West Virginia" },
    { value: "Wisconsin", label: "Wisconsin" },
    { value: "Wyoming", label: "Wyoming" },
    { value: "District of Columbia", label: "District of Columbia" },
];



export const staticCreditMemoLoanType = [
    { Id: "", Identifier: "CreditMemoLoanType", value: 0, label: "Select..." },
    { Id: "105", Identifier: "CreditMemoLoanType", value: 1, label: "Conventional" },
    { Id: "106", Identifier: "CreditMemoLoanType", value: 2, label: "SBA Express" },
    { Id: "107", Identifier: "CreditMemoLoanType", value: 3, label: "SBA 7(a)" },
    { Id: "108", Identifier: "CreditMemoLoanType", value: 4, label: "SBA 504" },
    { Id: "109", Identifier: "CreditMemoLoanType", value: 5, label: "SBA B&I" }
]

export const staticCreditMemoPaymentType = [
    { Id: "", Identifier: "CreditMemoPaymentType", value: 0, label: "Select..." },
    { Id: "115", Identifier: "CreditMemoPaymentType", value: 1, label: "Principal & interest" },
    { Id: "116", Identifier: "CreditMemoPaymentType", value: 2, label: "Interest only" },
    { Id: "117", Identifier: "CreditMemoPaymentType", value: 3, label: "Interest only, then principal & interest " },
]

export const staticCreditMemoStrengthWeak = [
    { Id: "", Identifier: "CreditMemoStrengthWeak", value: 0, label: "Select..." },
    { Id: "118", Identifier: "CreditMemoStrengthWeak", value: 1, label: "Collateral" },
    { Id: "119", Identifier: "CreditMemoStrengthWeak", value: 2, label: "Industry" },
    { Id: "120", Identifier: "CreditMemoStrengthWeak", value: 3, label: "Experience" },
    { Id: "121", Identifier: "CreditMemoStrengthWeak", value: 4, label: "Repayment source" },
    { Id: "122", Identifier: "CreditMemoStrengthWeak", value: 5, label: "Cash flow" },
    { Id: "123", Identifier: "CreditMemoStrengthWeak", value: 6, label: "Others" }
]

export const staticCreditMemoCollateralType = [
    { Id: "", Identifier: "CreditMemoCollateralType", value: 0, label: "Select..." },
    { Id: "124", Identifier: "CreditMemoCollateralType", value: 1, label: "Real estate - commercial" },
    { Id: "125", Identifier: "CreditMemoCollateralType", value: 2, label: "Real estate - residential" },
    { Id: "126", Identifier: "CreditMemoCollateralType", value: 3, label: "Equipment" },
    { Id: "127", Identifier: "CreditMemoCollateralType", value: 4, label: "Financial instruments" },
    { Id: "128", Identifier: "CreditMemoCollateralType", value: 5, label: "Others" }
]

export const staticCreditMemoBenchmarkindex = [
    { Id: "", Identifier: "CreditMemoBenchmarkindex", value: 0, label: "Select..." },
    { Id: "129", Identifier: "CreditMemoBenchmarkindex", value: 1, label: "3 Year Swap" },
    { Id: "130", Identifier: "CreditMemoBenchmarkindex", value: 2, label: "5 Year Swap" },
    { Id: "131", Identifier: "CreditMemoBenchmarkindex", value: 3, label: "7 Year Swap" },
    { Id: "132", Identifier: "CreditMemoBenchmarkindex", value: 4, label: "10 Year Swap" },
    { Id: "133", Identifier: "CreditMemoBenchmarkindex", value: 5, label: "WSJ Prime" },
    { Id: "134", Identifier: "CreditMemoBenchmarkindex", value: 6, label: "SOFR" },
    { Id: "135", Identifier: "CreditMemoBenchmarkindex", value: 7, label: "Other" },
]

export const staticLongMonthOptions = [
    { Identifier: "Month", value: "", label: "Select..." },
    { Identifier: "Month", value: "January", label: "January" },
    { Identifier: "Month", value: "February", label: "February" },
    { Identifier: "Month", value: "March", label: "March" },
    { Identifier: "Month", value: "April", label: "April" },
    { Identifier: "Month", value: "May", label: "May" },
    { Identifier: "Month", value: "June", label: "June" },
    { Identifier: "Month", value: "July", label: "July" },
    { Identifier: "Month", value: "August", label: "August" },
    { Identifier: "Month", value: "September", label: "September" },
    { Identifier: "Month", value: "October", label: "October" },
    { Identifier: "Month", value: "November", label: "November" },
    { Identifier: "Month", value: "December", label: "December" }
]

export const staticCreditMemoAgent = [
    { Id: "", Identifier: "CreditMemoAgent", value: "0", label: "Select..." },
    { Id: "136", Identifier: "CreditMemoAgent", value: "1", label: "Equifax" },
    { Id: "137", Identifier: "CreditMemoAgent", value: "2", label: "Experian" },
    { Id: "138", Identifier: "CreditMemoAgent", value: "3", label: "Transunion" }
]
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import FormTextArea from "../../reusablecomponets/FormTextArea";
import FormRadio from "../../reusablecomponets/FormRadio";
import CreatableSelect from "../../reusablecomponets/CreatableSelect";
import { actions } from '../../_redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { decryptText, validStringValidator } from "../../helpers/DynamicFunctions";
import { FieldGroup, FormBuilder, Validators } from "react-reactive-form";
import reducersAndActions from "../../_redux/slices/index";
import { PeakSeasonOptions } from '../../screens/loan-app-tabs/personalProfile/commonFunction';
import Constants from '../../helpers/Constants';
import FormField from '../../components/FormField/FormField';
import OpenAIComponent from '../../components/OpenAI/OpenAIComponent';
import WeeklyTimeComponent from '../../components/WeeklyTime/WeeklyTImeComponent';

let tempDaySchedule = {}
function IndustryMedical() {

    const dispatch = useDispatch();
    const params = useParams();
    const parsedLoanId = decryptText(params.loanId);

    const [form, setForm] = React.useState(
        FormBuilder.group({
            id: [0],
            loanId: [parsedLoanId],
            geographicMarket: ["", [Validators.required, validStringValidator]],
            suppliersVendorTerms: ["", [Validators.required, validStringValidator]],
            seasonalBusiness: [""],
            numberOfPatients: ["", [Validators.required, validStringValidator]],
            patientsReferred: ["", [Validators.required, validStringValidator]],
            affiliatedWithHospital: [""],
            insurancesAccepted: ["", [Validators.required, validStringValidator]],
            billingVsCollection: ["", [Validators.required, validStringValidator]],
            inuranceTimeFrame: ["", [Validators.required, validStringValidator]],
            additionalServices: [""],
            additionalComments: [""],
            modifiedBy: [0],
            customerVendors: ["", [Validators.required, validStringValidator]],
            keyCompetitors: ["", [Validators.required, validStringValidator]],
            peakSeason: [""],
            hospitalName: [""],
            serviceName: [""]
        })
    );

    useEffect(() => {
        document.title = "Medical Information - Loan Application";
        setShowErrorMessage(false);
        setForm(form);
        dispatch(actions.medicalForm.fetchMedicalData({ loanId: parsedLoanId, type: 'Medical' }));
        setSelectOptions({ geographicMarket: [], customerVendors: [], peakSeason: [] });
    }, [dispatch, parsedLoanId]);

    const { data, loading } = useSelector((state) => state.medicalForm);

    const [dayTimeSchedule, setDayTimeSchedule] = useState({ monday: {}, tuesday: {}, wednesday: {}, thursday: {}, friday: {}, saturday: {}, sunday: {} });
    const [selectOptions, setSelectOptions] = useState({ geographicMarket: [], customerVendors: [], peakSeason: [] });
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useEffect(() => {
        try {
            if (data && data.resultObject && Object.keys(data.resultObject).length > 0) {
                const resultObject = data.resultObject
                console.log(resultObject)

                const customerVendorsOptions = resultObject.customerVendors ? resultObject.customerVendors.split(',').map(value => ({ label: value, value })) : [];
                const peakSeasonOptions = resultObject.peakSeason ? resultObject.peakSeason.split(',').map(value => ({ value, label: PeakSeasonOptions.find(option => option.value === value)?.label || value })) : [];

                const geographicMarketOptions = resultObject.geographicMarket ? resultObject.geographicMarket.split(',').map(value => ({ label: value, value })) : [];

                setSelectOptions({
                    geographicMarket: geographicMarketOptions,
                    customerVendors: customerVendorsOptions,
                    peakSeason: peakSeasonOptions,
                });

                form.patchValue({
                    ...resultObject,
                    loanId: resultObject.loanId || parsedLoanId,
                    affiliatedWithHospital: resultObject.affiliatedWithHospital === 'true' ? 'true' : 'false',
                    seasonalBusiness: resultObject.seasonalBusiness === 'true' ? 'true' : 'false',
                    additionalServices: resultObject.additionalServices === 'true' ? 'true' : 'false',
                });

                const dayTimeSchedule = resultObject.lstHoursOperation.reduce((acc, item) => {
                    acc[item.daysName] = item;
                    return acc;
                }, {});

                setDayTimeSchedule((prev) => ({ ...prev, ...dayTimeSchedule }));
            } else {
                form.reset({ id: 0, loanId: parsedLoanId, modifiedBy: 0, affiliatedWithHospital: 'false', additionalServices: 'false', seasonalBusiness: 'false' });
            }
        } catch (error) {
            console.error("Error parsing or updating form:", error);
        }
    }, [data, setSelectOptions, form]);

    useEffect(() => {
        tempDaySchedule = { ...dayTimeSchedule };
    }, [dayTimeSchedule]);

    const handleSubmit = () => {
        setShowErrorMessage(true);

        if (form.status !== "INVALID") {
            const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
            weekDays.forEach(day => {
                if (!tempDaySchedule.hasOwnProperty(day)) {
                    tempDaySchedule[day] = { startDate: '09:00 AM', endDate: '06:00 PM', timeHour: '12:00', status: true, daysName: day, id: 0, industryId: 0 };
                }
            })

            const finalData = { ...form.value, lstHoursOperation: Object.values(tempDaySchedule) };

            finalData['peakSeason'] = finalData['seasonalBusiness'] === 'true' ? finalData['peakSeason'] : '';
            finalData['hospitalName'] = finalData['affiliatedWithHospital'] === 'true' ? finalData['hospitalName'] : '';
            finalData['serviceName'] = finalData['additionalServices'] === 'true' ? finalData['serviceName'] : '';

            dispatch(actions.medicalForm.submitMedicalForm(finalData))
                .then((response) => {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.payload.message, type: "success" }));
                    dispatch(actions.medicalForm.fetchMedicalData({ loanId: parsedLoanId, type: 'Medical' }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    setShowErrorMessage(false);
                })
                .catch(() => {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Error submitting form", type: "error" }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                });
        } else {
            markAllAsTouched(form);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Please filled required fields", type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    }


    const handleSelectChange = (name, selectedOption) => {
        const currentSelectedValues = selectedOption || [];
        const selectedValues = currentSelectedValues.map(option => option.value);

        // Allow removal if the new selection is less than or equal to the current one
        if (currentSelectedValues.length <= 10) {
            setSelectOptions(prevOptions => ({ ...prevOptions, [name]: currentSelectedValues.filter(opt => opt.value.trim() !== "" && opt.value.length <= 50) }));
            form.controls[name].setValue(selectedValues.join(','));
        } else {
            if (selectOptions[name]?.length < currentSelectedValues.length) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "You can't select more than 10 options", type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);

                setSelectOptions(prevOptions => ({ ...prevOptions, [name]: prevOptions[name] }));
            }
        }
    };

    const markAllAsTouched = (formGroup) => {
        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control && control.status === "INVALID") {
                control.markAsTouched();
                control.updateValueAndValidity();
                if (document.getElementById(controlName)) {
                    document.getElementById(controlName).focus();
                }
            }
        });
    };

    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>


                <div className="lm-form-box pb-5">
                    <FieldGroup
                        control={form}
                        render={({ get }) => (
                            <form>
                                <div className="lm-card">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">Medical Information </h3>
                                        </div>
                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="geographicMarket"
                                                label="Geographic markets"
                                                value={selectOptions.geographicMarket}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                                required={true}
                                            />
                                            {showErrorMessage && !form.value.geographicMarket && <div className='error-message mt-2 mb-3'>Required field</div>}
                                        </div>

                                        <div className="col-md-6">
                                            <CreatableSelect
                                                name="customerVendors"
                                                label="Major customers"
                                                value={selectOptions.customerVendors}
                                                onChange={handleSelectChange}
                                                placeholder="+ Add"
                                                isClearable
                                                required={true}
                                            />
                                            {showErrorMessage && !form.value.customerVendors && <div className='error-message mt-2 mb-3'>Required field</div>}
                                        </div>

                                        <div className="col-md-6 mt-3">
                                            <div className="form-group AI_btn_field">
                                                <FormTextArea
                                                    id="keyCompetitors"
                                                    label="Key competitors?"
                                                    name="keyCompetitors"
                                                    placeholder="Enter Text"
                                                    required={true}
                                                    errorMessage="Required field"
                                                />
                                                <OpenAIComponent
                                                    fieldName="keyCompetitors"
                                                    formGroup={form}
                                                    type='textarea'
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 mt-3">
                                            <div className="form-group AI_btn_field">
                                                <FormTextArea
                                                    id="suppliersVendorTerms"
                                                    label="Major suppliers & Terms"
                                                    name="suppliersVendorTerms"
                                                    placeholder="Enter Text"
                                                    required={true}
                                                    errorMessage="Required field"
                                                />
                                                <OpenAIComponent
                                                    fieldName="suppliersVendorTerms"
                                                    formGroup={form}
                                                    type='textarea'
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group AI_btn_field">
                                                <FormField
                                                    id="patientsReferred"
                                                    label="How are patients referred?"
                                                    name="patientsReferred"
                                                    placeholder="Enter Text"
                                                    required={true}
                                                    errorMessage="Required field"
                                                />
                                                <OpenAIComponent
                                                    fieldName="patientsReferred"
                                                    formGroup={form}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group AI_btn_field">
                                                <FormField
                                                    id="numberOfPatients"
                                                    label="Total active patients?"
                                                    name="numberOfPatients"
                                                    placeholder="Enter Text"
                                                    required={true}
                                                    errorMessage="Required field"
                                                />
                                                <OpenAIComponent
                                                    fieldName="numberOfPatients"
                                                    formGroup={form}
                                                />
                                            </div>
                                        </div>

                                        <div className='col-12'>
                                            <h3 className="lm-card-heading mt-2">Hours of operation</h3>
                                            <div className='row'>
                                                <WeeklyTimeComponent
                                                    weekday="monday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Medical"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="tuesday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Medical"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="wednesday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Medical"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="thursday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Medical"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="friday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Medical"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="saturday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Medical"
                                                />
                                                <WeeklyTimeComponent
                                                    weekday="sunday"
                                                    setDayTimeSchedule={setDayTimeSchedule}
                                                    value={dayTimeSchedule}
                                                    industryType="Medical"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <FormField
                                                id="insurancesAccepted"
                                                label="What insurances are accepted?"
                                                name="insurancesAccepted"
                                                placeholder="Enter Text"
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <FormField
                                                id="billingVsCollection"
                                                label="What is typical billing versus collection ratio?"
                                                name="billingVsCollection"
                                                placeholder="Enter Text"
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <FormField
                                                id="inuranceTimeFrame"
                                                label="What is the typical insurance payment timeframe?"
                                                name="inuranceTimeFrame"
                                                placeholder="Enter Text"
                                                required={true}
                                                errorMessage="Required field"
                                            />
                                        </div>

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">
                                            <FormRadio
                                                name="seasonalBusiness"
                                                label="Is this a seasonal business?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                            />
                                        </div>

                                        {
                                            form.value.seasonalBusiness === "true" &&
                                            <>
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <CreatableSelect
                                                                name="peakSeason"
                                                                label="X-ray"
                                                                value={selectOptions.peakSeason}
                                                                onChange={handleSelectChange}
                                                                placeholder="Select..."
                                                                options={PeakSeasonOptions}
                                                                isMulti
                                                                isClearable
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">
                                            <FormRadio
                                                name="affiliatedWithHospital"
                                                label="Affiliated with a hospital?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                            />
                                        </div>

                                        {form.value.affiliatedWithHospital === "true" && <div className="col-md-6">
                                            <FormField
                                                id="hospitalName"
                                                label="Hospital name"
                                                name="hospitalName"
                                                placeholder="Enter Text"
                                            />
                                        </div>}

                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-12">
                                            <FormRadio
                                                name="additionalServices"
                                                label="Are additional services done on premises (i.e. blood work, x-ray)?"
                                                options={[
                                                    { label: 'Yes', value: 'true' },
                                                    { label: 'No', value: 'false' },
                                                ]}
                                            />
                                        </div>

                                        {form.value.additionalServices === "true" && <div className="col-md-6">
                                            <FormField
                                                id="serviceName"
                                                label="Provide detail"
                                                name="serviceName"
                                                placeholder="Enter Text"
                                            />
                                        </div>}


                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-4 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-md-6">
                                            <div className="form-group AI_btn_field">
                                                <FormTextArea
                                                    id="additionalComments"
                                                    label="Additional comments"
                                                    name="additionalComments"
                                                    placeholder="Enter Text"
                                                    maxlength={500}
                                                />
                                                <OpenAIComponent
                                                    fieldName="additionalComments"
                                                    formGroup={form}
                                                    type='textarea'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sticky-btn-footer d-flex justify-content-end">
                                    <button type="button" className="lm-button1 ml-3" onClick={handleSubmit}>Save</button>
                                </div>
                            </form>
                        )}
                    />
                </div>
            </div>)}

        </>
    );
}

export default IndustryMedical;





import React from 'react';
import { FieldControl } from 'react-reactive-form';

const FormTextarea = ({ id, label, name, placeholder, validators, required, errorMessage, patternMessage, disabled, maxlength = 250, hideLabel = false }) => (
  <div className="form-group">
    {
      hideLabel ? null : (
        <label htmlFor={id}>
          {label}
          {required && <span className="asterisk">*</span>}
        </label>
      )
    }
    <FieldControl
      name={name}
      validators={validators}
      render={({ handler, touched, hasError }) => (
        <div>
          <textarea
            className="form-control"
            id={id}
            placeholder={placeholder}
            {...handler()}
            required={required}
            disabled={disabled}
            maxLength={maxlength}
          />
          {touched && hasError('required') && (<div className="error-message mt-2">{errorMessage || 'This field is required.'}</div>)}
          {touched && hasError('pattern') && (<div className="error-message mt-2">{patternMessage || 'Invalid input.'}</div>)}
          {required && touched && hasError("extraSpaces") && <span className="error-message">Please remove unwanted space</span>}
        </div>
      )}
    />
  </div>
);

export default FormTextarea;

import axios from "../../helpers/axiosConfig";
import { logout } from "../../services/commonService";
import { flushLocalStorageData } from "../../services/storageData";
import reducersAndActions from ".";
import moment from "moment/moment";
import Constants from "../../helpers/Constants";

// sessionSlice.js
const SHOW_WARNING = 'session/SHOW_WARNING';
const HIDE_WARNING = 'session/HIDE_WARNING';
const SET_LOADING = 'session/SET_LOADING';

const WARNING_TIME_MS = 25 * 60 * 1000 // 25 minutes in milliseconds

const initialState = {
    showWarning: false,
    isLoading: false,
};

// Reducer
const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_WARNING:
            return { ...state, showWarning: true };
        case HIDE_WARNING:
            return { ...state, showWarning: false };
        case SET_LOADING:
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

// Actions
export const showWarning = () => ({ type: SHOW_WARNING });
export const hideWarning = () => ({ type: HIDE_WARNING });

export const setLoading = (isLoading) => (dispatch) => {
    if (isLoading) {
        dispatch({ type: SET_LOADING, payload: isLoading });
    } else {
        setTimeout(() => {
            dispatch({ type: SET_LOADING, payload: isLoading });
        }, 2000);
    }
};

// Thunk Actions
export const startSessionTimeout = () => (dispatch) => {

    axios.post('/api/User/RefreshToken', {
        accessToken: localStorage.getItem('token'),
        refreshToken: localStorage.getItem('refreshToken'),
        tokenType: "",
        expiresIn: 0,
        api_domain: "",
        error: ""
    })
        .then((response) => {
            // Store data in localStorage
            const sessionStartTime = moment().valueOf();
            localStorage.setItem('sessionStartTime', sessionStartTime);
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('refreshToken', response.data.refreshToken);

            // Clear any existing timeout
            clearTimeout(window.sessionTimeoutID);

            // Set a new timeout
            window.sessionTimeoutID = setTimeout(() => {
                dispatch(showWarning());
            }, WARNING_TIME_MS);
        })
        .catch((error) => {
            console.log(error);
        })

};

export const clearSessionTimeout = () => (dispatch) => {
    try {
        const savedSessionStartTime = localStorage.getItem('sessionStartTime');
        const currentTime = moment().valueOf();

        if (currentTime >= parseInt(savedSessionStartTime) + WARNING_TIME_MS + 5 * 60 * 1000) {
            let email = localStorage.getItem('email');
            logout(JSON.parse(email))
                .then(() => {
                    dispatch(reducersAndActions.actions.authActions.removeUser());
                    flushLocalStorageData();
                    clearTimeout(window.sessionTimeoutID);
                    dispatch(hideWarning());
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Your session has expired. Please login again.", type: "error" }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                })
                .catch(err => {
                    console.log(err);
                    throw err;
                });
        } else {
            clearTimeout(window.sessionTimeoutID);
            localStorage.removeItem('sessionStartTime');
            dispatch(hideWarning());
        }
    } catch (error) {
        console.log(error);
    }
};

export const checkSessionOnLoad = () => (dispatch) => {
    try {
        const savedSessionStartTime = localStorage.getItem('sessionStartTime');

        if (savedSessionStartTime) {
            const currentTime = moment().valueOf();
            const timeElapsed = currentTime - savedSessionStartTime;

            if (currentTime >= parseInt(savedSessionStartTime) + WARNING_TIME_MS + 5 * 60 * 1000) {
                let email = localStorage.getItem('email');
                logout(JSON.parse(email))
                    .then(() => {
                        dispatch(reducersAndActions.actions.authActions.removeUser());
                        flushLocalStorageData();
                        clearTimeout(window.sessionTimeoutID);
                        dispatch(hideWarning());
                        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Your session has expired. Please login again.", type: "error" }));
                        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    })
                    .catch(err => {
                        console.log(err);
                        throw err;
                    });
            } else if (timeElapsed < WARNING_TIME_MS) {
                const remainingTime = WARNING_TIME_MS - timeElapsed;

                // Set timeout for the remaining time
                window.sessionTimeoutID = setTimeout(() => {
                    dispatch(showWarning());
                }, remainingTime);
            } else {
                dispatch(showWarning());
            }
        }
    } catch (error) {
        console.log(error);
    }
};

export const handleConfirmSession = (dispatch) => () => {
    try {
        const savedSessionStartTime = localStorage.getItem('sessionStartTime');
        const currentTime = moment().valueOf();

        if (currentTime >= parseInt(savedSessionStartTime) + WARNING_TIME_MS + 5 * 60 * 1000) {
            let email = localStorage.getItem('email');
            logout(JSON.parse(email))
                .then(() => {
                    dispatch(reducersAndActions.actions.authActions.removeUser());
                    flushLocalStorageData();
                    clearTimeout(window.sessionTimeoutID);
                    dispatch(hideWarning());
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Your session has expired. Please login again.", type: "error" }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                })
                .catch(err => {
                    console.log(err);
                    throw err;
                });
        } else {
            dispatch(hideWarning());
            dispatch(startSessionTimeout());
        }
    } catch (error) {
        console.log(error);
    }
};

export default sessionReducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { InsertUpdateTemplateDocument,  GetTemplateDocumentList, DeleteTemplateDocument, GetTemplateDocumentDetailsById } from '../../services/templateservices';

// Async thunk for fetching template documents list
export const fetchTemplateDocumentList = createAsyncThunk('templateDocument/fetchTemplateDocumentList',
    async ({ pageIndex, pageSize, status, searchText }) => {
        const response = await GetTemplateDocumentList(pageIndex, pageSize, status, searchText);
        return response;
    }
);

// Async thunk for submitting (adding or updating) a template document
export const submitTemplateDocument = createAsyncThunk('templateDocument/submitTemplateDocument', async (formData, params) => {
    const response = await InsertUpdateTemplateDocument(formData, params);
    return response;
});

// Async thunk for fetching a single template document by ID
export const fetchTemplateDocumentById = createAsyncThunk('templateDocument/fetchTemplateDocumentById', async (loanId) => {
    const response = await GetTemplateDocumentDetailsById(loanId);
    return response;
});

// Async thunk for deleting a template document
export const deleteTemplateDocument = createAsyncThunk('templateDocument/deleteTemplateDocument', async (loanId) => {
    const response = await DeleteTemplateDocument(loanId);
    return response; // Return the ID of the deleted document
});

const templateDocumentSlice = createSlice({
    name: 'templateDocument',
    initialState: {
        data: null, // Use this for a single document or null
        list: [], // Use this for the list of documents
        loading: false,
        error: null,
    },
    reducers: {
        resetTemplateDocumentState: (state) => {
            state.data = null;
            state.list = [];
            state.loading = false;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTemplateDocumentList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTemplateDocumentList.fulfilled, (state, action) => {
                state.loading = false;
                state.list = action.payload; // Update list with fetched documents
            })
            .addCase(fetchTemplateDocumentList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(submitTemplateDocument.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(submitTemplateDocument.fulfilled, (state, action) => {
                state.loading = false;
                // Update or add document in the list
                const index = state.list.findIndex(doc => doc.id === action.payload.id);
                if (index !== -1) {
                    state.list[index] = action.payload;
                } else {
                    state.list.push(action.payload);
                }
            })
            .addCase(submitTemplateDocument.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchTemplateDocumentById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTemplateDocumentById.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload; // Single document details
            })
            .addCase(fetchTemplateDocumentById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(deleteTemplateDocument.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteTemplateDocument.fulfilled, (state, action) => {
                state.loading = false;
                state.list = state.list.filter(doc => doc.id !== action.payload); // Remove deleted document from list
            })
            .addCase(deleteTemplateDocument.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { resetTemplateDocumentState } = templateDocumentSlice.actions;
export default templateDocumentSlice.reducer;

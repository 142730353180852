import axios from "../helpers/axiosConfig";
import { getLocalStorageData, getSessionId, flushLocalStorageData, setLocalStorageData, removeLocalStorageData } from "./storageData";
import Constants from '../helpers/Constants';
import uuid from 'react-uuid';

// -------------- Parameter interfaces for different methods ----------------------------

// --------------------------- Custom Api calling function  for the login of user ------------------------------
export function convertToFormData(data) {
    const formData = new FormData();
    for (const element of Object.keys(data)) {
        const key = element;
        formData.append(key, data[key]);
    }
    return formData;
}

export function generateUniqueId() {
    return uuid();
}

// USER BASED APIS BELOW
export const authenticate = async (userData) => {
    try {
        // Setting variables
        const authenticateUser = {
            EmailAddress: userData.EmailAddress,
            Password: userData.Password,
            SessionId: getSessionId()
        };
        const response = await axios.post(`/api/User/authenticate`, convertToFormData(authenticateUser));
        if (response.data.token) {
            const responseToken = response.data.token;
            const refreshToken = response.data.refreshToken;
            // Storing Token on Browser Storage and Redux
            setLocalStorageData("token", responseToken);
            setLocalStorageData("refreshToken", refreshToken);
            return responseToken;
        } else {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const login = async (userData) => {
    try {
        const token = getLocalStorageData("token");
        if (!token) {
            let authValue = await authenticate(userData);
            if (authValue.status === 401 || authValue.status === 404) {
                let error = new Error(authValue.message);
                error.status = authValue.status;
                throw error;
            }
        }
        const response = await axios.post(`/api/User/login`, convertToFormData(userData));
        if (response.data.status === 200 && response.data.message !== "Two Factor Authentication Login") {
            // Update Last Login Date and Time

            const ipInformation = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${Constants.IPGEOLOCATION_API_KEY}`);

            let reqBody = {
                UserId: response.data.resultObject.id,
                SessionId: getSessionId(),
                EmailAddress: response.data.resultObject.email,
                RoleName: response.data.resultObject.roleName,
                currentUtcDatetime: new Date(),
                LoginedUserIpAddress: ipInformation.data.ip,
                LoginedUserCity: ipInformation.data.city,
                LoginedUserState: ipInformation.data.state_prov,
                LoginedUserCountry: ipInformation.data.country_name
            };
            await axios.post(`api/User/UpdateLastLoginDateTime`, {}, { params: reqBody });
            // Storing Token on Browser Storage and Redux

            setLocalStorageData("user", JSON.stringify(response.data.resultObject));
            setLocalStorageData("email", JSON.stringify(response.data.resultObject.email));
            const temp = {
                ...response.data,
                resultObject: {
                    ...response.data.resultObject,
                    loginedUserIpAddress: ipInformation.data.ip,
                    loginedUserCity: ipInformation.data.city,
                    loginedUserState: ipInformation.data.state_prov,
                    loginedUserCountry: ipInformation.data.country_name
                }
            };
            return temp;
        } else {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}
export const ReSendVerificationCodeEmail = async (userData) => {

    try {
        const token = getLocalStorageData("token");
        if (!token) {
            let authValue = await authenticate(userData);
            if (authValue.status === 401 || authValue.status === 404) {
                let error = new Error(authValue.message);
                error.status = authValue.status;
                throw error;
            }
        }
        const response = await axios.post(`/api/User/SendVerificationCodeEmail?EmailAddress=${userData.email}&name=${userData.name}`);
        if (response.data.status === 200) {

            return response.data;
        } else {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const ChangeLockStatus = async (userData) => {
    try {
        const response = await axios.post(`/api/User/ChangeLockStatus?EmailAddress=${userData.EmailAddress}&SessionId=${getSessionId()}`);
        if (response.data.status === 200) {

            return response.data;
        } else {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}
export const LoginVerificationCode = async (userData) => {
    try {
        const token = getLocalStorageData("token");
        if (!token) {
            let authValue = await authenticate(userData);
            if (authValue.status === 401 || authValue.status === 404) {
                let error = new Error(authValue.message);
                error.status = authValue.status;
                throw error;
            }
        }
        const response = await axios.post(`/api/User/VerificationCodeEmail?EmailAddress=${userData.email}&OTP=${userData.code}`);
        if (response.data.status === 200) {
            let reqBody = {
                UserId: userData.data.id,
                SessionId: getSessionId(),
                EmailAddress: userData.data.email,
                RoleName: userData.data.roleName,
                currentUtcDatetime: new Date(),
            };
            await axios.post(`api/User/UpdateLastLoginDateTime`, {}, { params: reqBody });
            setLocalStorageData("user", JSON.stringify(userData.data));
            setLocalStorageData("email", JSON.stringify(userData.data.email));
            return response.data;
        } else {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const logout = async (email) => {
    try {
        const userData = { EmailAddress: email };
        const response = await axios.post(`/api/User/RemoveSession`, {}, { params: userData });
        if (response.data) {
            flushLocalStorageData();
        }
        return response.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const register = async (registerData) => {
    try {
        registerData["SessionId"] = getSessionId();
        const response = await axios.post(`/api/User/Register`, convertToFormData(registerData));
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const registerEmail = async (data) => {
    try {
        const response = await axios.post(`/api/User/RegisterLink`, {}, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const resetPassword = async (data) => {
    try {
        const response = await axios.get(`/api/User/ResetPassword`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const encryptEmail = async (email) => {
    try {
        const response = await axios.get(`/api/User/EncryptResetPasswordLink`, { params: { email } });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const changePassword = async (data) => {
    try {
        const response = await axios.post(`/api/User/ResetPasswordLink`, convertToFormData(data));
        if (response.data) {
            removeLocalStorageData("user");
            removeLocalStorageData("email");
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const USPSAPI = async (zipCode) => {
    try {
        const apiEndpoint = 'https://secure.shippingapis.com/ShippingAPI.dll';
        const userId = '759L47HUDSO09';

        // Construct the XML request
        const xmlRequest = `
            <CityStateLookupRequest USERID="${userId}">
                <ZipCode ID="0">
                    <Zip5>${zipCode}</Zip5>
                </ZipCode>
            </CityStateLookupRequest>
        `;

        // Perform the GET request
        const response = await axios.get(`${apiEndpoint}?API=CityStateLookup&XML=${encodeURIComponent(xmlRequest)}`, {
            headers: {
                'Content-Type': 'application/xml',
            }
        });

        if (response.status === 200) {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(response.data, 'application/xml');

            const cityNode = xmlDoc.getElementsByTagName('City')[0];
            const stateNode = xmlDoc.getElementsByTagName('State')[0];

            if (cityNode && stateNode) {
                return { status: true, BCity: cityNode.textContent, BState: stateNode.textContent }
            } else {
                return { status: false, message: 'City or State not found in the response' };
            }
        } else {
            return { status: false, message: 'Error: Unable to retrieve data from USPS API' };
        }
    } catch (error) {
        console.log(error);
        return { status: false, message: 'Error: Unable to retrieve data from USPS API' };
    }
}

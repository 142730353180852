/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { Header, Footer } from "@components";
import "./styles/ForgotPassword.css";
import './styles/WebsiteStyle.css';
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { generateUniqueId, LoginVerificationCode, ReSendVerificationCodeEmail } from "../services/commonService";
import Preloader from '../plugins/Preloader';
import { useDispatch, useSelector } from "react-redux";
import reducersAndActions from "../_redux/slices";
import Constants from "../helpers/Constants";
import { autoSetSessionId } from "../services/storageData";


function EmailVerification() {
  const [loading, setLoading] = useState(false);

  const [code, setCode] = useState()
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const user = useSelector((state) => state.auth);

  useEffect(() => {
    if (user.isLoggedIn) {
      navigate("/loan-application");
    }
  }, []);

  const handleSubmit = () => {
    autoSetSessionId(generateUniqueId())
    const userdata = { email: data?.email, code, data }
    try {
      if (code.length !== 0) {
        setLoading(true)
        LoginVerificationCode(userdata)
          .then(res => {
            if (res.status === 200) {
              dispatch(reducersAndActions.actions.authActions.saveUser(data));
              setLoading(false)
              navigate("/loan-application");
            }
          }
          ).catch(err => {
            setLoading(false)
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.response.data.message, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            console.log(err, err.response.data.message);
          })
      }
    } catch (error) {
      dispatch(reducersAndActions.actions.errorActions.showError({ visible: true, message: "Invalid OTP" }));
      setTimeout(() => dispatch(reducersAndActions.actions.errorActions.hideError()), Constants.TOASTERHIDETIME);
      setLoading(false)
      console.log(error)
    }
  }
  const HandleResendCode = () => {
    setLoading(true)
    ReSendVerificationCodeEmail(data)
      .then((res) => {
        setLoading(false)
        if (res.status === 200) {
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: res.message, type: "success" }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
      }
      )
  }

  return (
    <>
      {/* Header */}
      <Header />
      {loading ? <Preloader /> : null}
      {/* Main Body */}

      <div className="website-wrap">

        <section className="forgot-body">
          <div className="container">
            <div className="forgot-box verification-box">
              <h4>Email verification</h4>
              <p>
                Please enter verification code sent to {data?.email}
              </p>

              <div className="form-group">
                <label htmlFor="otp-code">Authentication Code</label>
                <input
                  type="number"
                  className="form-control"
                  id="otp-code"
                  aria-describedby="emailHelp"
                  placeholder="Enter a code"
                  onChange={e => setCode(e.target.value)}
                />
              </div>
              <button onClick={handleSubmit} className="btn btn-forgot">
                Submit
              </button>
              <div className="text-center mt-4">
                <a className="link-secondary" onClick={HandleResendCode}>
                  Resend Code
                </a>
              </div>

            </div>
          </div>
        </section>
      </div>


      {/* Footer */}
      <Footer />
    </>
  );
}

export default EmailVerification;

import React, { useEffect, useRef, useState } from 'react';
import ai_icon from '../../assets/img/ai_icon.svg';
import ai_update_icon from '../../assets/img/ai_update_icon.svg';
import ai_update_options_icon from '../../assets/img/ai_update_options_icon.svg';
import { getAIRePhaseContent } from '../../services/utilServices';

const checkFinalValueForNested = (formGroup, fieldName) => {
    if (fieldName.includes('.')) {
        const nestedFields = fieldName.split('.');
        let nestedValue = formGroup.value;
        for (let i = 0; i < nestedFields.length; i++) {
            nestedValue = nestedValue[nestedFields[i]];
        }
        return nestedValue;
    }
    return formGroup.value[fieldName];
}

const updateFinalValueForNested = (formGroup, fieldName, value) => {
    if (fieldName.includes('.')) {
        const nestedFields = fieldName.split('.');
        let nestedValue = formGroup.value;
        for (let i = 0; i < nestedFields.length - 1; i++) {
            nestedValue = nestedValue[nestedFields[i]];
        }
        formGroup.get(nestedFields[0]).controls[nestedFields[1]].patchValue({ [nestedFields[2]]: value });
    } else {
        formGroup.patchValue({ [fieldName]: value });
    }
}

const AIRewriteButton = ({ AiType = "ReWrite", fieldName, isFormGroup = true, formGroup, type = "", value = "", setHardData, setNestedHardData = true, disabled = false, hiddenLable = false, showLeftSide }) => {
    const finalValue = isFormGroup ? checkFinalValueForNested(formGroup, fieldName) : value;
    const [showAIOptions, setShowAIOptions] = useState(false);

    const aiOptionsRef = useRef(null); // Reference to the AI options container

    // Handle click outside the dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (aiOptionsRef.current && !aiOptionsRef.current.contains(event.target)) {
                setShowAIOptions(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const formateResponse = (content) => {
        try {
            return content.replace(/[\r\t\n]/g, '').replace(/[^a-zA-Z0-9., ]/g, '');
        } catch (error) {
            console.error("Error:", error.message);
            return content;
        }
    }

    const handleAIWrite = async ({ hideOptions, type = '' }) => {
        document.getElementById(`lm-ai-${type}btn-${fieldName}`).classList.add('is_generating');
        const content = await getAIRePhaseContent(finalValue);
        const formattedContent = formateResponse(content);

        if (isFormGroup) {
            updateFinalValueForNested(formGroup, fieldName, formattedContent);
        } else {
            setNestedHardData ? setHardData(formattedContent, fieldName) : setHardData(formattedContent);
        }
        if (hideOptions) {
            setShowAIOptions(false)
        }
        document.getElementById(`lm-ai-${type}btn-${fieldName}`).classList.remove('is_generating');
    }

    return (
        <>
            {
                AiType === "ReWrite" ? (
                    <button
                        type="button"
                        className={type === 'textarea' ? "lm-ai-btn-bottom" : hiddenLable ? "lm-ai-btn-no-label" : "lm-ai-btn"}
                        id={`lm-ai-btn-${fieldName}`}
                        onClick={() => handleAIWrite({ hideOptions: false })}
                        style={{ backgroundColor: finalValue ? '' : '#71719C' }}
                        disabled={!finalValue || disabled}
                    >
                        <img src={ai_icon} alt="AI Icon" />
                        AI rewrite
                    </button>
                ) : (
                    <div className="ai-update-content">
                        <div className="ai-update-box">
                            <button
                                className="lm-ai-update-btn"
                                onClick={() => setShowAIOptions((prev) => !prev)}
                            >
                                <img src={ai_update_icon} alt="" /> AI update
                            </button>
                            {showAIOptions && (
                                <div
                                    // id={`lm-ai-btn-${fieldName}`}
                                    className={showLeftSide ? "ai_update_options ai_update_pose_left" : "ai_update_options"}
                                    ref={aiOptionsRef} // Attach the ref here
                                >
                                    <div
                                        onClick={() => {
                                            setShowAIOptions(false);
                                            document.getElementById(fieldName).focus();
                                        }}
                                    >
                                        <img src={ai_update_options_icon} alt="" /> Write on your own
                                    </div>

                                    <div id={`lm-ai-aiwrite-btn-${fieldName}`}>
                                        <img src={ai_update_options_icon} alt="" /> Use AI to write for you
                                    </div>
                                    <div id={`lm-ai-enhance-btn-${fieldName}`}
                                        onClick={() => {
                                            if (finalValue) {
                                                handleAIWrite({ hideOptions: true, type: 'enhance-' });
                                            } else {
                                                setShowAIOptions(false);
                                                document.getElementById(fieldName).focus();
                                            }
                                        }}
                                    >
                                        <img src={ai_update_options_icon} alt="" /> Use AI to enhance your writing
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default AIRewriteButton;

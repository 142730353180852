function AccessRestricted() {
    return (
        <>
            <div className="">
                <div className="not-found-page-body">
                    <div className="success-page-box not-found-box">
                        {/* <img src={not_found_404} /> */}
                        <h2>
                            Access Denied
                            <br></br>
                            You do not have permission to view this page.
                        </h2>
                        <p>
                            Our services are only available to users within the US.
                            <br />
                            Please contact support if you believe this is an error.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccessRestricted;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from "react-router-dom";
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import back_arrow from '../../assets/icons/back_arrow.svg';
import brdcrm_nav from '../../assets/icons/brdcrm_nav.svg';
import { decryptText, encryptText, formatNumberWithCommas } from '../../helpers/DynamicFunctions';
import { GetPersonalCashFlowDetailsById, InsertAndUpdateBusinessCashflow } from '../../services/cashflowServices';
import { getSessionId } from '../../services/storageData';
import { FieldGroup, FormBuilder } from 'react-reactive-form';
import Constants from '../../helpers/Constants';
import reducersAndActions from '../../_redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import AmountContainer from '../../components/Amount/AmountContainer';


let personalFormGroup = FormBuilder.group({
    pcfId: [""],
    ownerFullName: [""],
    adjustedGrossIncome: [""],
    salaryWages: [""],
    interest: [""],
    taxexempt: [""],
    taxable: [""],
    dividend: [""],
    qualified: [""],
    ordinary: [""],
    iraDistributions: [""],
    iradNontaxable: [""],
    iradTaxable: [""],
    pensionsAnnuities: [""],
    pNontaxable: [""],
    ptaxable: [""],
    socialSecurityBenefits: [""],
    sNontaxable: [""],
    staxable: [""],
    capitalGainorLoss: [""],
    otherIncome: [""],
    totalIncome: [""],
    netIncome: [""],
    depreciation: [""],
    ariInterest: [""],
    amortization: [""],
    annualDebtServicePI: [""],
    surplusorDeficit: [""],
    requiredCoverageMargin: [""],
    surplusDeficitforx: [""],
    grossCashFlow: [""],
    federalTaxes: [""],
    stateTaxes: [""],
    fica: [""],
    realEstateTaxes: [""],
    totalTaxes: [""],
    cashAvailableforDebtService: [""],
    debtMOnthaly: [""],
    residenceMortgagePI: [""],
    secondMortgagePI: [""],
    helocpi: [""],
    autoLoansLeasesPI: [""],
    creditCard: [""],
    studentLoans: [""],
    others: [""],
    totalAnnualDebtService: [""],
    estimatedLivingExpenses: [""],
    discretionaryIncome: [""]
})

function CashFlowPersonal() {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth);

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const decryptedValue = params.get('data') ? decryptText(params.get('data')) : ""

    // Split the decrypted value into individual parameters
    const paramsArray = decryptedValue ? decryptedValue.split("&") : [];

    const applicationName = paramsArray.length > 0 ? paramsArray[0].split("=")[1].replace('%20', " ").replaceAll('"', '') : null;
    const personalID = paramsArray.length > 1 ? paramsArray[1].split("=")[1] : null;
    const appNumber = paramsArray.length > 2 ? paramsArray[2].split("=")[1] : null;
    const appLoanId = paramsArray.length > 3 ? paramsArray[3].split("=")[1] : null;

    // Meta Title
    useEffect(() => {
        document.title = "Cash Flow Personal - Loan Application";
        getPersonalCashflowDetails();
        personalFormGroup.reset();
    }, []);

    // Preloader Start
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);

    const getPersonalCashflowDetails = async () => {
        setLoading(true);
        const params = {
            SessionId: getSessionId(),
            LoanId: personalID,
        };
        const data = await GetPersonalCashFlowDetailsById(params);
        console.log("Personal Cashflow Details", data);
        if (data.resultObject) {
            personalFormGroup.patchValue(data.resultObject);
        } else {
            personalFormGroup.reset()
        }
        setLoading(false);
    }

    // Popup function
    const [PreviousTabPopupShow, setPreviousTabPopup] = useState(false);
    const PreviousTabPopupClose = () => setPreviousTabPopup(false);

    const toFixedOrZero = (value) => {
        const number = parseFloat(value);
        return isNaN(number) ? '0.00' : number.toFixed(2);
    };

    const getValue = (value) => {
        if (typeof value === "string") {
            value = value.replace(/,/g, '');
        }
        value = parseFloat(value);
        return isNaN(value) ? 0 : value;
    };

    const calculateTotalIntreset = () => {
        const data = personalFormGroup.value;
        const totalInterest = getValue(data.taxexempt) + getValue(data.taxable);
        return totalInterest;
    }

    const calculateTotalDividend = () => {
        const data = personalFormGroup.value;
        const totalDividend = getValue(data.qualified) + getValue(data.ordinary);
        return totalDividend;
    }

    const calculateTotalIRADistribution = () => {
        const data = personalFormGroup.value;
        const totalIRADistribution = getValue(data.iradNontaxable) + getValue(data.iradTaxable);
        return totalIRADistribution;
    }

    const calculatePensionAnnuties = () => {
        const data = personalFormGroup.value;
        const totalPensionAnnuties = getValue(data.pNontaxable) + getValue(data.ptaxable);
        return totalPensionAnnuties;
    }

    const calculateTotalSSBenifits = () => {
        const data = personalFormGroup.value;
        const totalSSBenifits = getValue(data.sNontaxable) + getValue(data.staxable);
        return totalSSBenifits;
    }

    const calculateTotalIncome = () => {
        const totalIncome = getValue(personalFormGroup.value.salaryWages) + calculateTotalIntreset() + calculateTotalDividend() + calculateTotalIRADistribution() + calculatePensionAnnuties() + calculateTotalSSBenifits() + getValue(personalFormGroup.value.capitalGainorLoss) + getValue(personalFormGroup.value.otherIncome);
        return totalIncome;
    }



    const calculateTotalSurplusDeficit = () => {
        const data = personalFormGroup.value;
        const totalSurplusDeficit = getValue(data.netIncome) + getValue(data.depreciation) + getValue(data.ariInterest) + getValue(data.amortization) + getValue(data.annualDebtServicePI);
        return totalSurplusDeficit;
    }

    const calculateSurplusDeficiX = () => {
        const data = personalFormGroup.value;
        const surplusDeficiX = (-getValue(data.annualDebtServicePI) * getValue(data.requiredCoverageMargin)) - getValue(data.netIncome);
        return surplusDeficiX;
    }

    const calculatGrossCashFlow = () => {
        const grossCashFlow = calculateTotalIncome() - calculateSurplusDeficiX();
        return grossCashFlow
    }

    const calculateTotalTaxes = () => {
        const data = personalFormGroup.value;
        const totalTaxes = getValue(data.federalTaxes) + getValue(data.stateTaxes) + getValue(data.fica) + getValue(data.realEstateTaxes);
        return totalTaxes;
    }

    const calculateCashAvailableforDebtService = () => {
        const cashAvailableforDebtService = calculatGrossCashFlow() - calculateTotalTaxes();
        return cashAvailableforDebtService;
    }

    const calculateAnnualDebtService = () => {
        const data = personalFormGroup.value;
        const totalAnnualDebtService = getValue(data.residenceMortgagePI) + getValue(data.secondMortgagePI) + getValue(data.helocpi) + getValue(data.autoLoansLeasesPI) + getValue(data.creditCard) + getValue(data.studentLoans) + getValue(data.others);
        return totalAnnualDebtService * 12;
    }

    const calculateDiscretionaryIncome = () => {
        const data = personalFormGroup.value;
        const discretionaryIncome = calculateCashAvailableforDebtService() - calculateAnnualDebtService() - getValue(data.estimatedLivingExpenses);
        return discretionaryIncome;
    }


    const handleSubmitPersonalCashflow = async () => {
        setLoading(true);

        personalFormGroup.patchValue({
            interest: calculateTotalIntreset().toString(),
            dividend: calculateTotalDividend().toString(),
            iraDistributions: calculateTotalIRADistribution().toString(),
            pensionsAnnuities: calculatePensionAnnuties().toString(),
            socialSecurityBenefits: calculateTotalSSBenifits().toString(),
            totalIncome: calculateTotalIncome().toString(),
            surplusorDeficit: calculateTotalSurplusDeficit().toString(),
            grossCashFlow: calculatGrossCashFlow().toString(),
            totalTaxes: calculateTotalTaxes().toString(),
            cashAvailableforDebtService: calculateCashAvailableforDebtService().toString(),
            totalAnnualDebtService: calculateAnnualDebtService().toString(),
            discretionaryIncome: calculateDiscretionaryIncome().toString()
        })

        let data = personalFormGroup.value;
        Object.keys(data).forEach((item) => {
            data[item] = getValue(data[item]).toString()
        })

        data['pcfId'] = parseInt(personalID)
        data['modifiedBy'] = user.user.id;
        data['ownerFullName'] = applicationName;

        const update = await InsertAndUpdateBusinessCashflow(data, "InsertUpdatePersonalCashFlowDetail");
        if (update.status === 200) {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: update.message, type: "success" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            backPreviousTab();
        } else {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: update?.response?.data?.title, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
        setLoading(false);
    }

    const backPreviousTab = () => {
        navigate(`/application-detail/${encryptText(appLoanId)}`, { state: { tabName: 'cash_flow' } });
    }

    const checkForBackButton = () => {
        if (personalFormGroup.dirty) {
            setPreviousTabPopup(true);
        } else {
            backPreviousTab();
        }
    }

    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>

                <div className="dashboard-wrap application-detail bg-white pb-5">
                    {/* <!-- Cash Flow Personal Detail header sec start --> */}
                    <div className="padding-reg">
                        {/* <!-- Cash Flow Personal Detail breadcrumb start --> */}
                        <div className="lm-breadcrumb d-flex">
                            <a onClick={() => navigate('/loan-application')} className="back-btn mr-2 cursor-pointer"><img src={back_arrow} alt="" /></a>
                            <span onClick={() => navigate('/loan-application')} className="text-light mr-1 cursor-pointer">Loan Applications</span>
                            <img src={brdcrm_nav} alt="" />
                            <span className="text-dark ml-1">{appNumber}</span>
                        </div>
                        <h1 className="appliaction-no my-3">{applicationName}</h1>

                        {/* <!-- Cash Flow Personal Detail breadcrumb end --> */}
                        {/* <!-- Cash Flow Personal Detail header sec end --> */}

                        <FieldGroup
                            control={personalFormGroup}
                            render={({ get, invalid }) => (
                                <form>
                                    <div className="lm-card p-0 mt-3">
                                        <div className="p-3">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-7 col-12">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="adjustedGrossIncome"
                                                                    label="Adjusted Gross Income (AGI)"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="salaryWages"
                                                                    label="Salary & Wages"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="taxexempt"
                                                                    label="Tax-exempt"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="taxable"
                                                                    label="Taxable"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="qualified"
                                                                    label="Qualified"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="ordinary"
                                                                    label="Ordinary"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="iradNontaxable"
                                                                    label="IRA Distributions Non-taxable"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="iradTaxable"
                                                                    label="IRA Distributions Taxable"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="pNontaxable"
                                                                    label="Pensions & Annuities Non-taxable"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="ptaxable"
                                                                    label="Pensions & Annuities Taxable"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="sNontaxable"
                                                                    label="Social Security Benefits Non-taxable"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="staxable"
                                                                    label="Social Security Benefits Taxable"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="capitalGainorLoss"
                                                                    label="Capital Gain or Loss"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="otherIncome"
                                                                    label="Other Income"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-1 col-12">

                                                </div>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="cf-value-card">
                                                        <div className="title">
                                                            Interest
                                                        </div>
                                                        <div className="value">
                                                            $ {formatNumberWithCommas(toFixedOrZero(calculateTotalIntreset())) || "0.00"}
                                                        </div>
                                                    </div>
                                                    <div className="cf-value-card">
                                                        <div className="title">
                                                            Dividend
                                                        </div>
                                                        <div className="value">
                                                            $ {formatNumberWithCommas(toFixedOrZero(calculateTotalDividend())) || "0.00"}
                                                        </div>
                                                    </div>
                                                    <div className="cf-value-card">
                                                        <div className="title">
                                                            IRA Distributions
                                                        </div>
                                                        <div className="value">
                                                            $ {formatNumberWithCommas(toFixedOrZero(calculateTotalIRADistribution())) || "0.00"}
                                                        </div>
                                                    </div>
                                                    <div className="cf-value-card">
                                                        <div className="title">
                                                            Pensions & Annuities
                                                        </div>
                                                        <div className="value">
                                                            $ {formatNumberWithCommas(toFixedOrZero(calculatePensionAnnuties())) || "0.00"}
                                                        </div>
                                                    </div>
                                                    <div className="cf-value-card">
                                                        <div className="title">
                                                            Social Security Benefits
                                                        </div>
                                                        <div className="value">
                                                            $ {formatNumberWithCommas(toFixedOrZero(calculateTotalSSBenifits())) || "0.00"}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!--  lm common divider to break the section  --> */}
                                                <div className="lm-divider my-3 mx-3"></div>
                                                {/* <!--  lm common divider to break the section  --> */}
                                                <div className="col-12 mt-2 d-flex justify-content-between">
                                                    <span className="total-label">Total</span>
                                                    <h3 className="total-amount">$ {formatNumberWithCommas(toFixedOrZero(calculateTotalIncome())) || "0.00"}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lm-card p-0 mt-3">
                                        <div className="p-3">
                                            <div className="row">
                                                <div className="col-12">
                                                    <span className="label text-dark mb-0"><b>ANNUAL RENTAL INCOME (SCHED E)</b></span>
                                                </div>
                                                <div className="col-lg-6 col-md-7 col-12">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="netIncome"
                                                                    label="Net Income"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="depreciation"
                                                                    label="+ Depreciation"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="ariInterest"
                                                                    label="+ Interest"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="amortization"
                                                                    label="+ Amortization"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="annualDebtServicePI"
                                                                    label="+ Annual Debt Service (P&I only)"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-1 col-12">

                                                </div>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="cf-value-card">
                                                        <div className="title">
                                                            Surplus or Deficit
                                                        </div>
                                                        <div className="value">
                                                            $ {formatNumberWithCommas(toFixedOrZero(calculateTotalSurplusDeficit())) || "0.00"}
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <!--  lm common divider to break the section  --> */}
                                                <div className="lm-divider my-3 mx-3"></div>
                                                {/* <!--  lm common divider to break the section  --> */}
                                                <div className="col-lg-6 col-md-7 col-12">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="requiredCoverageMargin"
                                                                    label="Required Coverage Margin"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <label htmlFor="surplusDeficitforx">(Surplus) / Deficit for 1.2x</label>
                                                                <input type="text" className="form-control" id="surplusDeficitforx" placeholder="0.00" value={formatNumberWithCommas(toFixedOrZero(getValue(calculateSurplusDeficiX()))) || "0.00"} disabled={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-1 col-12">
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="cf-value-card">
                                                        <div className="title">
                                                            Gross Cash Flow
                                                        </div>
                                                        <div className="value">
                                                            $ {formatNumberWithCommas(toFixedOrZero(calculatGrossCashFlow())) || "0.00"}
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <!--  lm common divider to break the section  --> */}
                                                <div className="lm-divider my-3 mx-3"></div>
                                                {/* <!--  lm common divider to break the section  --> */}
                                                <div className="col-lg-6 col-md-7 col-12">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="federalTaxes"
                                                                    label="Federal Taxes"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="stateTaxes"
                                                                    label="State Taxes"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="fica"
                                                                    label="FICA"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="realEstateTaxes"
                                                                    label="Real Estate Taxes"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-1 col-12">
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="cf-value-card">
                                                        <div className="title">
                                                            Total Taxes
                                                        </div>
                                                        <div className="value">
                                                            $ {formatNumberWithCommas(toFixedOrZero(calculateTotalTaxes())) || "0.00"}
                                                        </div>
                                                    </div>
                                                    <div className="cf-value-card">
                                                        <div className="title">
                                                            Cash Available for Debt Service
                                                        </div>
                                                        <div className="value">
                                                            $ {formatNumberWithCommas(toFixedOrZero(calculateCashAvailableforDebtService())) || "0.00"}
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <!--  lm common divider to break the section  --> */}
                                                <div className="lm-divider my-3 mx-3"></div>
                                                {/* <!--  lm common divider to break the section  --> */}
                                                <div className="col-lg-6 col-md-7 col-12">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="residenceMortgagePI"
                                                                    label="Residence Mortgage (P&I only)"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="secondMortgagePI"
                                                                    label="Second Mortgage (P&I only)"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="helocpi"
                                                                    label="HELOC (P&I only)"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="autoLoansLeasesPI"
                                                                    label="Auto -- Loans & Leases (P&I only)"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="creditCard"
                                                                    label="Credit Card"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="studentLoans"
                                                                    label="Student Loans"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="others"
                                                                    label="Others"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-1 col-12">
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="cf-value-card">
                                                        <div className="title">
                                                            Total Annual Debt Service
                                                        </div>
                                                        <div className="value">
                                                            $ {formatNumberWithCommas(toFixedOrZero(calculateAnnualDebtService())) || "0.00"}
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <!--  lm common divider to break the section  --> */}
                                                <div className="lm-divider my-3 mx-3"></div>
                                                {/* <!--  lm common divider to break the section  --> */}
                                                <div className="col-lg-6 col-md-7 col-12">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="estimatedLivingExpenses"
                                                                    label="Estimated Living Expenses"
                                                                    placeholder="0.00"
                                                                    maxlength={9}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-1 col-12">
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="cf-value-card">
                                                        <div className="title">
                                                            Discretionary Income
                                                        </div>
                                                        <div className="value">
                                                            $ {formatNumberWithCommas(toFixedOrZero(calculateDiscretionaryIncome())) || "0.00"}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="sticky-btn-footer d-flex justify-content-end">
                                        <button onClick={checkForBackButton} type="button" className="lm-button2">Back</button>
                                        <button type="button" className="lm-button1 ml-3" onClick={handleSubmitPersonalCashflow}>Save</button>
                                    </div>

                                </form>
                            )}
                        />
                    </div>
                </div>


                {/* <!--back to Previous Tab Popup / Modal --> */}
                <Modal
                    className='mark-comp-popup-cust-size'
                    show={PreviousTabPopupShow}
                    onHide={() => setPreviousTabPopup(false)}
                    aria-labelledby="PreviousTabPopupLabel"
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-body signout-modal">
                                <h3 className="pt-4">
                                    Going back will lead to the loss of unsaved data
                                </h3>
                            </div>
                            <div className="modal-footer la-app-modal-footer">
                                <button type="button" className="lm-button2" onClick={PreviousTabPopupClose}>Cancel</button>
                                <a className="lm-button1" onClick={backPreviousTab}> Confirm</a>
                            </div>
                        </div>
                    </div>
                </Modal >
                {/* <!--back to Previous Tab Popup / Modal --> */}

            </div >)
            }

        </>
    );
}

export default CashFlowPersonal;